import React, { memo } from 'react';
import { useController, FieldValues, FieldPathByValue } from 'react-hook-form';
import {
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckbox,
} from '@mui/material';
import MaterialIcon from 'components/MaterialIcon';
import { blue } from 'lib/colors';

function Checkbox<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  control,
  name,
  label,
  sx,
  ...props
}: CheckboxProps & { name: TPath; label: string; control: any }) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name,
    control,
  });
  return (
    <>
      <FormControlLabel
        sx={{ margin: '0px' }}
        control={
          <MuiCheckbox
            {...props}
            {...field}
            sx={{ padding: 0, marginRight: '10px', ...sx }}
            inputRef={field.ref}
            checked={field.value}
            value={field.value}
            icon={<MaterialIcon name="check_box_outline_blank" color={blue} />}
            checkedIcon={<MaterialIcon name="check_box" color={blue} />}
          />
        }
        label={label}
      />
      {invalid ? <FormHelperText error>{error?.message}</FormHelperText> : null}
    </>
  );
}

export default memo(Checkbox);
