import React, { useState, useEffect } from 'react';
import {
  styled,
  Typography,
  Stack,
  Divider,
  Grid,
  Tooltip,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { black, blue50 } from 'lib/colors';
import {
  FooterWrapper,
  LongText,
  SidebarWrapper,
  StyledNavLink,
  animateText,
  shrinkSidebarWidth,
  sideBarTransitionSpeed,
  sidebarWidth,
  staggerItems,
} from './common';
import { SidebarItemType } from '../types';
import LogoutButton from './LogoutButton';
import ToggleSidebar from './ToggleSidebar';
import MaterialIcon from 'components/MaterialIcon';

const TitleWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export const Footer = ({ fullWidth }: { fullWidth: boolean }) => (
  <FooterWrapper spacing={2}>
    <Divider />
    <Grid container justifyContent="center">
      {fullWidth ? (
        <Grid item xs={8}>
          <Stack>
            <Typography variant="subtitle2">User</Typography>
            <LongText variant="subtitle2">
              {localStorage.getItem('icEmail')}
            </LongText>
          </Stack>
        </Grid>
      ) : null}
      <Grid
        item
        xs={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LogoutButton />
      </Grid>
    </Grid>
  </FooterWrapper>
);

const ExpandedSidebar = ({
  show,
  expanded,
  items,
  isLightMode,
  title,
}: {
  show: boolean;
  expanded: boolean;
  items: SidebarItemType[];
  isLightMode: boolean;
  title: string;
}) => {
  const [isLocalBackend, setIsLocalBackend] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem('API_URL')?.includes('http://localhost:')) {
      setIsLocalBackend(true);
    }
  }, []);

  return show ? (
    <SidebarWrapper
      bgcolor={isLightMode ? '#2d4abc' : '#1f1c1c'}
      initial={{ opacity: 0, width: 0, display: 'none' }}
      animate={{
        opacity: 1,
        width: expanded
          ? `${sidebarWidth - shrinkSidebarWidth}px`
          : `${shrinkSidebarWidth}px`,
        display: 'block',
      }}
      transition={{
        duration: sideBarTransitionSpeed,
      }}
    >
      <TitleWrapper>
        <AnimatePresence>
          {expanded ? (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={animateText}
              style={{ width: '100%', overflow: 'hidden' }}
            >
              <Stack direction="row" spacing={1}>
                <Typography variant="h6" whiteSpace="nowrap">
                  {title}
                </Typography>
                {isLocalBackend ? (
                  <Typography
                    variant="h6"
                    color="dark"
                    sx={{
                      background: black,
                      borderRadius: '0.5rem',
                      padding: '0 0.5em',
                    }}
                  >
                    Local
                  </Typography>
                ) : null}
              </Stack>
            </motion.div>
          ) : null}
        </AnimatePresence>
        <ToggleSidebar />
      </TitleWrapper>

      <Stack mt={4}>
        {items?.map((item, index) => (
          <motion.div key={index}>
            <Tooltip title={!expanded ? item.name : ''} arrow placement="right">
              <StyledNavLink
                to={item.link}
                style={({ isActive }) => {
                  return {
                    backgroundColor: isActive ? blue50 : 'inherit',
                  };
                }}
                end
              >
                <MaterialIcon name={item.icon} color="white" size={20} />
                <AnimatePresence>
                  {expanded ? (
                    <motion.div
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      custom={index}
                      variants={staggerItems}
                    >
                      <Typography variant="subtitle2">{item.name}</Typography>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </StyledNavLink>
            </Tooltip>
          </motion.div>
        ))}
      </Stack>
      <Footer fullWidth={expanded} />
    </SidebarWrapper>
  ) : null;
};

export default ExpandedSidebar;
