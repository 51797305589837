import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'components/Modal';
import { TextField } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { authedDelete, handleAPIError } from 'utils/api';
import { useParams, useRevalidator } from 'react-router-dom';
import { COMPANY_VESSELS_KEY } from 'query/queries';
import MaterialIcon from 'components/MaterialIcon';
import { orange } from 'lib/colors';

type Props = {
  id: number;
  open: boolean;
  closeFn: () => void;
};

const DeleteBunkeringEstimatesMutation = async (id: number) => {
  const endpoint = `/api/v2/omega/flotilla-vessels/${id}/bunkering-estimates`;
  const response = await authedDelete(endpoint);
  handleAPIError(response.status);
  return;
};

const DeleteBunkeringEstimates = ({ id, open, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const companyId = parseInt(params.id!);
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<{ id: number | null }>({
    defaultValues: {
      id: null,
    },
    resolver: yupResolver(
      yup.object().shape({
        id: yup
          .number()
          .positive()
          .required()
          .test('confirm-delete', "Value doesn't match vessel id.", (value) => {
            return value === id;
          }),
      })
    ),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: DeleteBunkeringEstimatesMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: COMPANY_VESSELS_KEY(companyId),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    mutate(id);
  };

  const headerComp = () => (
    <ModalHeader
      title="Delete Bunkering Estimates"
      icon="delete"
      subtitle={`Remove bunkering estimates for vessel with ID (${id})`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        color="error"
        variant="contained"
        disabled={isLoading}
      >
        Delete
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Button
          size="large"
          color="warning"
          variant="outlined"
          sx={{
            textTransform: 'none',
            textAlign: 'initial',
            width: '450px',
          }}
        >
          <MaterialIcon
            name="warning"
            color={orange}
            size={50}
            style={{
              marginRight: '1rem',
            }}
          />
          Once you delete this vessel bunkering estimate, there is no going
          back. Please be certain.
        </Button>
        <Stack spacing={2} mt={2.5}>
          <Typography variant="subtitle2">
            Enter vessel ID to delete bunkering estimates for
          </Typography>
          <TextField
            type="number"
            control={control}
            name="id"
            placeholder={id.toString()}
            autoFocus
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default DeleteBunkeringEstimates;
