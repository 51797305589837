import { BREAK_POINT_XS } from 'lib/breakpoints';
import { styled, Avatar, Chip } from '@mui/material';
import { blue10, white } from 'lib/colors';

export const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  border-radius: 20px 0 0 20px;
  @media (max-width: ${BREAK_POINT_XS}) {
    border-radius: unset;
  }
`;

export const StyledAvatar = styled(Avatar)`
  height: 35px;
  width: 35px;
  font-size: 15px;
`;

export const StyledChip = styled(Chip)`
  background-color: ${blue10};
  color: ${white};
  min-width: 100px;
`;

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
