import {
  CompanyCommon,
  ListCompaniesResp,
} from '@greywing-maritime/frontend-library/dist/types/companies';
import queryClient from 'query';
import {
  companiesQuery,
  companyFlightsQuery,
  companyPreferredPortsQuery,
  companyQuery,
  companyStatsQuery,
  statsQuery,
} from 'query/queries';
import { LoaderFunctionArgs } from 'react-router-dom';

export const CompaniesLoader = async () => {
  const query = companiesQuery;
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export const CompanyLoader = async (props: LoaderFunctionArgs) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('Company not found', { status: 404 });
  }
  const query = companyQuery(parseInt(id));
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export const CompanyWithStatsLoader = async (props: LoaderFunctionArgs) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('Company not found', { status: 404 });
  }
  const query = companyStatsQuery(parseInt(id));
  const company = (await CompanyLoader(props)) as CompanyCommon;
  return {
    stats:
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query)),
    company,
  };
};

export const CompaniesWithStatsLoader = async () => {
  const query = statsQuery;
  const { companies } = (await CompaniesLoader()) as ListCompaniesResp;
  return {
    stats:
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query)),
    companies,
  };
};

export const CompanyPreferredPortsLoader = async (
  props: LoaderFunctionArgs
) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('Company not found', { status: 404 });
  }
  const query = companyPreferredPortsQuery(parseInt(id));
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export const CompanyFlightsConfigLoader = async (props: LoaderFunctionArgs) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('Company not found', { status: 404 });
  }
  const query = companyFlightsQuery(parseInt(id));
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
