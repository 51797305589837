import React from 'react';
import Modal from 'components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { authedPost, handleAPIError } from 'utils/api';
import { AddCmsJobSchema } from '../validation';
import ModalHeader from 'components/ModalHeader';
import { TextField, AutocompleteWithAdd } from 'components/Forms';
import { CMS_ADAPTER_IDS_COMMON } from '@greywing-maritime/frontend-library/dist/types/cms';
import { useRevalidator } from 'react-router-dom';
import { CMS_JOBS_KEY } from 'query/queries';

type AddCmsJobType = {
  companyId?: number;
  adapterId: string;
  frequencyInMins?: number;
};

type Props = {
  open: boolean;
  closeFn: () => void;
};

const AddCmsJobMutation = async (values: AddCmsJobType) => {
  const endpoint = `/api/v2/omega/cms/jobs`;
  const response = await authedPost(endpoint, values);
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

const AddCmsJob = ({ open, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<AddCmsJobType>({
    defaultValues: {
      companyId: 0,
      adapterId: Object.values(CMS_ADAPTER_IDS_COMMON)[0],
      frequencyInMins: 0,
    },
    resolver: yupResolver(AddCmsJobSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: AddCmsJobMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: CMS_JOBS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: AddCmsJobType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Add Cms Job"
      icon="add_circle"
      subtitle="Add cms job by providing cms details"
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Add
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Company ID</Typography>
          <TextField
            type="number"
            control={control}
            name="companyId"
            placeholder="01"
            size="small"
            autoFocus
          />
          <Typography variant="subtitle2">Adapter ID</Typography>
          <AutocompleteWithAdd
            control={control}
            name="adapterId"
            size="small"
            options={Object.values(CMS_ADAPTER_IDS_COMMON).map((value) => ({
              label: value,
            }))}
          />
          <Typography variant="subtitle2">Frequency in minutes</Typography>
          <TextField
            type="number"
            control={control}
            name="frequencyInMins"
            placeholder="1440"
            size="small"
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default AddCmsJob;
