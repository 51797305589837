import { Autocomplete, Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import debounce from 'lodash/debounce';
import {
  useController,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { AirportCommon } from '@greywing-maritime/frontend-library/dist/types/airports';
import { FlightResult as CommonFlightResult } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getAuthToken } from '@greywing-maritime/frontend-library/dist/utils/auth';

import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';
import { headers } from 'utils/api';
import { FlightQueryParams, FlightResult, QuickFlyResult } from './types';
import { useFlightStore } from '.';
import { Checkbox, DatePicker } from 'components/Forms';
import queryClient from 'query';

type FlightProp = {
  flights: {
    id: string;
    arrival: AirportCommon | null;
    departure: AirportCommon | null;
    date: Moment;
  }[];
  refreshCache: boolean;
};

// fetches airports with typing into inputs
export const searchAirports = async (query: string) => {
  const queryParams = new URLSearchParams({
    term: query,
  });
  return queryClient.fetchQuery({
    queryKey: ['airports', query],
    queryFn: async () => {
      const resp = await fetch(
        `${getAPIUrl()}/api/v2/airports?${queryParams}`,
        {
          headers: headers(getAuthToken()?.token || ''),
        }
      );
      return resp.json();
    },
  });
};

function transformObj(
  flight: CommonFlightResult & { uid: string; totalFlightTime: number }
): FlightResult {
  return {
    ...flight,
    departure: {
      ...flight.departure,
      time: flight.departure.time as unknown as Date,
    },
    arrival: {
      ...flight.arrival,
      time: flight.arrival.time as unknown as Date,
    },
    fetchedAt: new Date(),
    cabinClass: flight?.cabinClass || '',
    numSeatsAvailable: flight?.numSeatsAvailable || 0,
  };
}

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

async function getFlights({
  startCode,
  endCode,
  startDate,
  refreshCache,
}: FlightQueryParams): Promise<QuickFlyResult[]> {
  const flightReqQueryParams = new URLSearchParams({
    startCode,
    endCode,
    startDate,
    refreshCache: refreshCache ? 'true' : 'false',
  });
  try {
    const flightsEndpoint = `${getAPIUrl()}/api/flotillav2/get_flights?${flightReqQueryParams}`;
    const response = await fetch(flightsEndpoint, {
      method: 'POST',
      headers: headers(getAuthToken()?.token || ''),
    });
    const { resultsURL: streamingURL } = await response.json();
    const stream = new EventSource(`${getAPIUrl()}${streamingURL}`);

    const result: QuickFlyResult[] = [];
    const start = window.performance.now();
    return new Promise((resolve) => {
      stream.addEventListener('flight_returned', (response: any) => {
        const flightResult = JSON.parse(response.data.trim());
        const newFlights: FlightResult[] = (flightResult?.data || []).map(
          (
            flight: CommonFlightResult & {
              uid: string;
              totalFlightTime: number;
            }
          ) => transformObj(flight)
        );
        useFlightStore.getState().updateFlights({
          flights: newFlights,
          source: newFlights[0]?.source || 'unknown',
          count: newFlights.length,
          meta: flightResult?.meta,
          timeTaken: window.performance.now() - start,
          createdAt: new Date().toISOString(),
          startCode,
          endCode,
          startDate,
        });
      });

      stream.addEventListener('done', () => {
        stream.close();
        console.log(
          `Fetched groups of flights for ${startCode} to ${endCode} from ${startDate}:`,
          result.length
        );
        resolve(result);
      });
    });
  } catch (error) {
    throw error;
  }
}
const AirportDropdown = ({
  control,
  name,
  label,
}: {
  control: any;
  name: string;
  label: string;
}) => {
  const [options, setOptions] = useState<AirportCommon[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const handleFetchAirports = useCallback(
    debounce(async (text: string) => {
      if (!text) return;
      setLoading(true);
      const fetchedAirports = await searchAirports(text);
      setOptions(fetchedAirports || []);
      setLoading(false);
    }, 300),
    []
  );

  // @ts-ignore
  const onChange = (e, value, r) => {
    if (r === 'input') {
      handleFetchAirports(value);
    }
  };

  const { field } = useController({
    name,
    control,
  });
  // @ts-ignore
  const onInputChange = (e, v) => {
    field.onChange(v);
  };

  return (
    <Autocomplete
      noOptionsText="Start typing to search"
      value={field.value}
      getOptionLabel={(option) => `(${option.iataCode}) ${option.name}`}
      options={options}
      onInputChange={onChange}
      onChange={onInputChange}
      openOnFocus
      loading={loading}
      isOptionEqualToValue={(option, selected) =>
        option?.iataCode === selected?.iataCode
      }
      filterSelectedOptions
      size="small"
      renderInput={(params) => (
        <TextField name={name} label={label} autoComplete="off" {...params} />
      )}
    />
  );
};

const Wrapper = styled('form')`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const InputWrapper = styled(`div`)`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  padding: 0.25rem;
  border: 1px solid grey;
  border-radius: 4px;
`;

function Inputs() {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FlightProp>({
    defaultValues: {
      flights: [
        {
          id: v4().slice(0, 8),
          arrival: null,
          departure: null,
          date: moment().add(1, 'day'),
        },
      ],
      refreshCache: false,
    },
  });

  const onSubmit = useCallback(async (data: FlightProp) => {
    const isComplete = data.flights.every((o) => o.arrival && o.departure);
    if (!isComplete) return;
    try {
      return Promise.all(
        data.flights.map(
          async (flight) =>
            await getFlights({
              startCode: flight.arrival!.iataCode,
              endCode: flight.departure!.iataCode,
              startDate: flight.date.format('YYYY-MM-DD'),
              refreshCache: data.refreshCache,
            })
        )
      );
    } catch (error) {
      console.error('FAILED!', error);
      return Promise.resolve();
    }
  }, []);

  const {
    fields: flightField,
    insert,
    remove,
  } = useFieldArray({
    control,
    name: 'flights',
  });

  const updatedFlightsValue = useWatch({
    control,
    name: 'flights',
  });

  const addInput = useCallback(
    (index: number) => {
      const prevFlight = updatedFlightsValue[index - 1];
      insert(index, {
        id: v4().slice(0, 8),
        arrival: prevFlight.arrival || null,
        departure: prevFlight.departure || null,
        date: prevFlight.date || moment().add(1, 'day'),
      });
    },
    [updatedFlightsValue, insert]
  );

  const handleRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      {flightField.map((o, index) => (
        <InputWrapper key={o.id}>
          {index !== 0 && (
            <div style={{ alignSelf: 'flex-end' }}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemove(index)}
              >
                Delete
              </Button>
            </div>
          )}
          <AirportDropdown
            key="arrival"
            name={`flights.${index}.arrival`}
            label="arrival"
            control={control}
          />
          <AirportDropdown
            key="departure"
            name={`flights.${index}.departure`}
            label="departure"
            control={control}
          />
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
            <DatePicker name={`flights.${index}.date`} control={control} />
          </LocalizationProvider>
        </InputWrapper>
      ))}
      <Button onClick={() => addInput(flightField.length)} variant="outlined">
        Add more
      </Button>
      <ButtonWrapper>
        <LoadingButton loading={isSubmitting} variant="contained" type="submit">
          Submit
        </LoadingButton>
        <Checkbox name="refreshCache" label="Refresh Cache" control={control} />
      </ButtonWrapper>
    </Wrapper>
  );
}

export default Inputs;
