import Inbox from './pages/Inbox';
import Starred from './pages/Starred';
import CrewChanges from './pages/CrewChanges';
import {
  MailLoader,
  CompanyMailLoader,
  UserMailLoader,
  ConversationLoader,
} from './loader';
import Sidebar from './components/Sidebar';
import Conversation from './pages/Conversation';
import { mailType } from './types';
import { LoaderFunction, RouteObject } from 'react-router';

const routesArr = (type: (typeof mailType)[number]): RouteObject[] => {
  let loader: LoaderFunction = () => null;
  if (type === 'all') {
    loader = MailLoader;
  } else if (type === 'company') {
    loader = CompanyMailLoader;
  } else if (type === 'users') {
    loader = UserMailLoader;
  }
  const path = type === 'all' ? '/mail' : `/${type}/:id/mail`;
  return [
    {
      path: `${path}/inbox`,
      element: <Inbox type={type} />,
      loader,
    },
    {
      path: `${path}/starred`,
      element: <Starred type={type} />,
      loader,
    },
    {
      path: `${path}/crew-changes`,
      element: <CrewChanges type={type} />,
      loader,
    },
    {
      path: `${path}/inbox/:convoId`,
      element: <Conversation type={type} />,
      loader: ConversationLoader,
    },
  ];
};

const routes = mailType.reduce(
  (acc: RouteObject[], type) => [
    ...acc,
    ...routesArr(type).map((route) => ({
      ...route,
      element: <Sidebar type={type}>{route.element}</Sidebar>,
    })),
  ],
  []
);

export default routes;
