import React from 'react';
import { useIsLightMode } from 'hooks';
import GreywingIcon from '../assets/icons/greywing-icon.svg';
import GreywingWhiteIcon from '../assets/icons/greywing-icon-white.svg';

type Props = {
  className?: string;
  white?: boolean;
};

const Logo = ({ className, white }: Props) => {
  const isLightMode = useIsLightMode();
  return white ? (
    <div className={className}>
      <img src={GreywingWhiteIcon} height="100%" alt="" />
    </div>
  ) : (
    <div className={className}>
      {!isLightMode ? (
        <img src={GreywingWhiteIcon} height="100%" alt="" />
      ) : (
        <img src={GreywingIcon} height="100%" alt="" />
      )}
    </div>
  );
};

export default Logo;
