import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { blue, white } from 'lib/colors';
import MaterialIcon, { MaterialIconType } from './MaterialIcon';

type Props = {
  title: string;
  icon?: MaterialIconType;
  subtitle?: string;
};
const ModalHeader = ({ title, icon, subtitle }: Props) => {
  return (
    <Stack direction={'row'} spacing={2}>
      {icon ? (
        <Avatar sx={{ backgroundColor: blue, width: 50, height: 50 }}>
          <MaterialIcon name={icon} color={white} />
        </Avatar>
      ) : null}
      <Stack justifyContent="center">
        <Typography variant="h6" fontWeight={600}>
          {title}
        </Typography>
        {subtitle ? (
          <Typography variant="subtitle2" fontWeight={300}>
            {subtitle}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default ModalHeader;
