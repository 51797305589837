import React from 'react';
import { Stack, Tooltip, IconButton } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useLoaderData, useParams } from 'react-router-dom';
import { blue, red } from 'lib/colors';
import { useState } from 'react';
import { UserPermissionCommon } from '@greywing-maritime/frontend-library/dist/types/omegaUsers';
import EditPermission from './components/EditUserPermission';
import DeletePermission from './components/DeletePermission';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import { StyledChip } from 'styles/common';
import { AllPermissionType } from './types';
import { capitalize } from 'lodash';
import MaterialIcon from 'components/MaterialIcon';

const RenderAccess = (props: GridRenderCellParams<any, string>) => {
  const { value } = props;
  return value ? (
    <StyledChip
      label={capitalize(value)}
      sx={{ backgroundColor: value === 'allow' ? blue : red }}
    />
  ) : (
    <StyledChip label={'-'} />
  );
};

const RenderActions = ({
  deleteFn,
  editFn,
}: {
  deleteFn?: () => void;
  editFn: () => void;
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <Tooltip title="Edit Permission" placement="top" arrow>
        <IconButton onClick={editFn}>
          <MaterialIcon name="edit" color={blue} />
        </IconButton>
      </Tooltip>
      {deleteFn ? (
        <Tooltip title="Delete Permission" placement="top" arrow>
          <IconButton onClick={deleteFn}>
            <MaterialIcon name="delete" color={red} />
          </IconButton>
        </Tooltip>
      ) : null}
    </Stack>
  );
};

const columns: GridColDef[] = [
  {
    field: 'featureId',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'displayName',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'type',
    headerName: 'Access Control',
    width: 200,
    valueGetter: (params) =>
      params.row.userPerm ? params.row.effectivePerm.type : null,
    renderCell: RenderAccess,
  },
];

function UserPermissions() {
  const params = useParams();
  const id = parseInt(params.id!);
  const permissions = useLoaderData() as AllPermissionType[];
  const [permission, setPermission] = useState<AllPermissionType | null>(null);
  const [showEditPermissionModal, setShowEditPermissionModal] =
    useState<boolean>(false);
  const [showDeletePermissionModal, setShowDeletePermissionModal] =
    useState<boolean>(false);

  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    setPermission(row);
  };

  const handleEditModal = () => {
    setShowEditPermissionModal(!showEditPermissionModal);
  };

  const handleDeleteModal = () => {
    setShowDeletePermissionModal(!showDeletePermissionModal);
  };

  const actions: GridColDef = {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    valueGetter: (params) => params.row.userPerm,
    renderCell: (props: GridRenderCellParams<any, UserPermissionCommon>) => {
      const { value } = props;
      return value ? (
        <RenderActions deleteFn={handleDeleteModal} editFn={handleEditModal} />
      ) : (
        <RenderActions editFn={handleEditModal} />
      );
    },
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Permissions"
        subtitle="Update and manage permissions"
        chipText={permissions?.length + ' permissions'}
        icon="format_list_bulleted"
      />

      <Table
        height="100%"
        rows={permissions}
        getRowId={(row) => row.featureId}
        columns={[...columns, actions]}
        onRowClick={handleRowClick}
        noRowsText="No permissions found"
        nonClickable
      />

      {permission ? (
        <>
          {showEditPermissionModal ? (
            <EditPermission
              permission={{
                ...(permission.userPerm as UserPermissionCommon),
                featureId: permission.featureId,
                displayName: permission.displayName,
                id,
              }}
              open={showEditPermissionModal}
              closeFn={handleEditModal}
              companyPerm={permission.companyPerm}
              effectivePerm={permission.effectivePerm}
            />
          ) : null}

          {showDeletePermissionModal ? (
            <DeletePermission
              permission={{
                ...(permission.userPerm as UserPermissionCommon),
                featureId: permission.featureId,
                displayName: permission.displayName,
                id,
              }}
              type={'user'}
              open={showDeletePermissionModal}
              closeFn={handleDeleteModal}
            />
          ) : null}
        </>
      ) : null}
    </Stack>
  );
}

export default UserPermissions;
