import React, { memo } from 'react';
import {
  styled,
  SelectProps,
  Select as MuiSelect,
  FormHelperText,
  MenuItem,
  MenuItemProps,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useController, FieldValues, FieldPathByValue } from 'react-hook-form';
import { CURRENCIES_LIST } from 'lib/currencies';

type Option = { value: string | number; label: string };

const CountryImage = styled('img')`
  width: 20px;
  height: auto;
  margin-right: 1rem;
`;

function CurrencyV2<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  options,
  variant,
  menuItemProps,
  ...props
}: SelectProps & {
  name: TPath;
  control: any;
  options?: Option[];
  menuItemProps?: MenuItemProps;
}) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name: props.name,
    control: props.control,
  });

  return (
    <FormControl variant={variant}>
      {props.label && (
        <InputLabel id={`label-${props.id}`}>{props.label}</InputLabel>
      )}
      <MuiSelect
        labelId={props.label ? `label-${props.id}` : ''}
        {...props}
        {...field}
        ref={field.ref}
        value={field.value}
        error={invalid}
        MenuProps={{ sx: { maxHeight: 200 } }}
      >
        <MenuItem key={'None'} value={'None'} {...menuItemProps}>
          None
        </MenuItem>
        {CURRENCIES_LIST.map(({ Code, Flag }) => (
          <MenuItem key={Code} value={Code} {...menuItemProps}>
            <CountryImage loading="lazy" src={Flag} />
            {Code}
          </MenuItem>
        ))}
      </MuiSelect>
      {invalid && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  );
}

export default memo(CurrencyV2);
