import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import EditFeatures from './components/EditFeature';
import AddFeature from './components/AddFeature';
import PageHeader from 'components/PageHeader';
import {
  FeatureCommon,
  ListFeaturesResp,
} from '@greywing-maritime/frontend-library/dist/types/features';
import Table from 'components/Table';
import { useLoaderData } from 'react-router-dom';
import RenderAccess from 'components/Table/RenderAccess';
import MaterialIcon from 'components/MaterialIcon';
import { white } from 'lib/colors';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'displayName',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'slug',
    headerName: 'Slug',
    width: 250,
  },
  {
    field: 'isPublic',
    headerName: 'Access Control',
    width: 120,
    renderCell: RenderAccess,
  },
];

function FeaturesPage() {
  const { features } = useLoaderData() as ListFeaturesResp;
  const [feature, setFeature] = useState<FeatureCommon | null>(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const handleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const handleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    setFeature(row);
    handleEditModal();
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Features"
        subtitle="Update and manage platform features"
        chipText={features.length + ' features'}
        icon="featured_play_list"
        actions={
          <Button
            variant="contained"
            startIcon={<MaterialIcon name="add" color={white} />}
            onClick={handleAddModal}
          >
            Add
          </Button>
        }
      />

      <Table
        height="100%"
        rows={features}
        columns={columns}
        onRowClick={handleRowClick}
        noRowsText="No features found"
      />

      {showAddModal ? (
        <AddFeature open={showAddModal} closeFn={handleAddModal} />
      ) : null}

      {feature && showEditModal ? (
        <EditFeatures
          feature={feature}
          open={showEditModal}
          closeFn={handleEditModal}
        />
      ) : null}
    </Stack>
  );
}

export default FeaturesPage;
