import * as yup from 'yup';

export const AddCmsJobSchema = yup.object().shape({
  companyId: yup.number().positive().required(),
  adapterId: yup.string().required(),
  frequencyInMins: yup.number().positive().required(),
});

export const EditCmsJobSchema = yup.object().shape({
  companyId: yup.number().positive().required(),
  adapterId: yup.string().optional(),
  enabled: yup.boolean().optional(),
  frequencyInMins: yup.number().positive().optional(),
});
