import { ExtractedCost } from '@greywing-maritime/greywing-copilot/dist/gpt/inbound-agent-email/types';
import { GridRowId } from '@mui/x-data-grid-pro';
import groupBy from 'lodash/groupBy';

export type ACEConvoCostsRow = ExtractedCost & {
  id: number;
};

export type ExtractedCostSummary = {
  id: string;
  appliesTo: string;
  currency: string;
  cost: number;
};

export function getInitialSelection(data: ACEConvoCostsRow[]): GridRowId[] {
  return data.map((o) => o.id);
}

export function computeSelectedCost(
  costs: ACEConvoCostsRow[],
  selected: GridRowId[]
) {
  const selectedCosts = costs.filter((cost) => selected.includes(cost.id));
  const grouped = groupBy(selectedCosts, (o) => `${o.appliesTo}-${o.currency}`);
  const summary = Object.values(grouped).reduce((acc, group) => {
    const totalCostPerPerson = group.reduce(
      (total, each) => total + each.costPerPerson,
      0
    );
    acc.push({
      id: `${group[0].appliesTo}-${group[0].currency}`,
      appliesTo: group[0].appliesTo,
      currency: group[0].currency,
      cost: totalCostPerPerson,
    });
    return acc;
  }, [] as ExtractedCostSummary[]);
  return summary;
}
