import { Stack, Typography, styled } from '@mui/material';
import React from 'react';

const Title = styled(Typography)`
  font-weight: 600;
  white-space: nowrap;
  font-size: 14px;
`;

const RenderObject = (obj: any) => {
  return Object.keys(obj).map((key) => (
    <Stack direction="row" spacing={1} key={key}>
      {obj[key] && typeof obj[key] === 'object' ? (
        <Stack ml={2}>
          <Title>{key}: </Title>
          {RenderObject(obj[key])}
        </Stack>
      ) : (
        <>
          <Title>{key}: </Title>
          <Typography
            variant="body2"
            fontSize={14}
            sx={{ wordBreak: 'break-word' }}
          >
            {obj[key] || '-'}
          </Typography>
        </>
      )}
    </Stack>
  ));
};

export default RenderObject;
