import { Stack, Typography, styled } from '@mui/material';
import Logo from 'components/Logo';
import { black } from 'lib/colors';
import React, { useEffect, useState } from 'react';

const LogoWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.6rem;
  column-gap: 0.8rem;
`;

const LogoIcon = styled(Logo)`
  height: 30px;
`;

const GWLogo = ({ fullWidth }: { fullWidth: boolean }) => {
  const [isLocalBackend, setIsLocalBackend] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem('API_URL')?.includes('http://localhost:')) {
      setIsLocalBackend(true);
    }
  }, []);

  return (
    <LogoWrapper>
      <LogoIcon white />
      {fullWidth ? <Typography variant="h6">Greywing</Typography> : null}
      {isLocalBackend && fullWidth ? (
        <Typography
          variant="h6"
          color="dark"
          sx={{
            background: black,
            borderRadius: '0.5rem',
            padding: '0 0.5em',
          }}
        >
          Local
        </Typography>
      ) : null}
    </LogoWrapper>
  );
};

export default GWLogo;
