import { SidebarItemType } from './types';

const IS_LOCAL_DEV = process.env?.REACT_APP_LOCAL_DEV === 'true';

export const sideBarItems: SidebarItemType[] = [
  { name: 'Companies', icon: 'home', link: '/dashboard' },
  { name: 'Users', icon: 'groups', link: '/users' },
  { name: 'Features', icon: 'featured_play_list', link: '/features' },
  { name: 'Vessel Tags', icon: 'sell', link: '/vessel-tags' },
  { name: 'Ports', icon: 'anchor', link: '/ports', hide: !IS_LOCAL_DEV },
  { name: 'Risk Area', icon: 'crisis_alert', link: '/risk-area' },
  { name: 'Flights', icon: 'flight', link: '/flights' },
  {
    name: 'Proteus',
    icon: 'memory',
    link: '/proteus',
    hide: !IS_LOCAL_DEV,
  },
  { name: 'Data Push Jobs', icon: 'backup_table', link: '/data-push-jobs' },
  { name: 'CMS Jobs', icon: 'business_center', link: '/cms-jobs' },
  { name: 'VMS Jobs', icon: 'dns', link: '/vms-jobs' },
  {
    name: 'Proteus Email',
    icon: 'mail',
    link: `/mail/inbox`,
  },
  {
    name: 'Port Agents',
    icon: 'support_agent',
    link: `/port-agents`,
  },
  {
    name: 'Port Agency Costs',
    icon: 'paid',
    link: `/port-agency-costs`,
  },
  {
    name: 'Port Restrictions',
    icon: 'anchor',
    link: `/port-restrictions`,
  },
  {
    name: 'Proteus Parsing',
    icon: 'document_scanner',
    link: `/proteus-parsing`,
  },
];

export const sideBarItemsAdmin: SidebarItemType[] = [
  { name: 'Settings', icon: 'settings', link: '/settings' },
];
