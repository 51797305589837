import queryClient from 'query';
import {
  inboxQuery,
  companyInboxQuery,
  userInboxQuery,
  conversationQuery,
} from 'query/queries';
import { LoaderFunctionArgs } from 'react-router-dom';

export const MailLoader = async () => {
  const query = inboxQuery;
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export const CompanyMailLoader = async (props: LoaderFunctionArgs) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('Company not found', { status: 404 });
  }
  const query = companyInboxQuery(parseInt(id));
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export const UserMailLoader = async (props: LoaderFunctionArgs) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('User not found', { status: 404 });
  }
  const query = userInboxQuery(parseInt(id));
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export const ConversationLoader = async (props: LoaderFunctionArgs) => {
  const {
    params: { convoId },
  } = props;
  if (typeof convoId === 'undefined') {
    throw new Response('Conversation not found', { status: 404 });
  }
  const query = conversationQuery(parseInt(convoId));
  return await queryClient.fetchQuery(query);
};
