import React, { memo, useState } from 'react';
import { useController, FieldValues, FieldPathByValue } from 'react-hook-form';
import {
  OutlinedInputProps,
  IconButton,
  InputAdornment,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';
import MaterialIcon from 'components/MaterialIcon';

function PasswordField<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  control,
  name,
  ...props
}: OutlinedInputProps & { name: TPath; control: any }) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name,
    control,
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <OutlinedInput
        {...props}
        {...field}
        inputRef={field.ref}
        value={field.value}
        type={showPassword ? 'text' : 'password'}
        sx={{
          svg: {
            width: '20px',
            height: '20px',
          },
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              <MaterialIcon
                name={showPassword ? 'visibility_off' : 'visibility'}
                color="white"
              />
            </IconButton>
          </InputAdornment>
        }
        error={invalid}
      />
      {invalid ? <FormHelperText error>{error?.message}</FormHelperText> : null}
    </>
  );
}

export default memo(PasswordField);
