import React from 'react';
import { styled, Stack, Divider } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { blue, blue10, white } from 'lib/colors';

const ToolbarWrapper = styled(Stack)`
  height: 35px;
  .MuiButton-root {
    background-color: ${blue10};
    color: ${white};
    padding: 0.2rem 0.8rem;
    text-transform: none;
    &:hover {
      background-color: ${blue};
      color: ${white};
    }
  }
  .MuiOutlinedInput-input {
    padding: 0.4rem 0.2rem;
  }
  .MuiTextField-root {
    padding: 0;
    margin-left: 0.5rem;
  }
`;

const SearchWrapper = styled(Stack)`
  position: absolute;
  right: 8px;
  background-color: ${white};
`;

export const DefaultToolbarItems = () => {
  return (
    <>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
    </>
  );
};

export const DefaultToolbarSearch = () => {
  return (
    <SearchWrapper>
      <GridToolbarQuickFilter variant="outlined" />
    </SearchWrapper>
  );
};

const Toolbar = ({ toolbar }: { toolbar: React.ReactNode }) => {
  if (toolbar === null) return null;
  return (
    <>
      <ToolbarWrapper direction={'row'} spacing={1} m={1}>
        {toolbar ?? (
          <>
            <DefaultToolbarItems />
            <DefaultToolbarSearch />
          </>
        )}
      </ToolbarWrapper>
      <Divider />
    </>
  );
};

export default Toolbar;
