import { Details, PreferredPorts } from 'pages/company';
import Users from 'pages/users';
import {
  CompanyFlightsConfigLoader,
  CompanyPreferredPortsLoader,
  CompanyWithStatsLoader,
} from './loader';
import { CompanyPermissionsLoader } from 'pages/permissions/loader';
import { CompanyUsersLoader } from 'pages/users/loader';
import { CompanyVesselsLoader } from 'pages/vessels/loader';
import Vessels from 'pages/vessels';
import Sidebar from './components/Sidebar';
import CompanyPermissions from 'pages/permissions/CompanyPermissions';
import TravelVendors from './pages/TravelVendors';
import { RouteObject } from 'react-router';
import PortAgencyCosts from 'pages/port-agency-costs';
import { CompanyPortAgencyCostsLoader } from 'pages/port-agency-costs/loader';
import BunkeringEstimates from './pages/BunkeringEstimates';

const routesArr: RouteObject[] = [
  {
    path: '/company/:id',
    element: <Details />,
    loader: CompanyWithStatsLoader,
  },
  {
    path: '/company/:id/permissions',
    element: <CompanyPermissions />,
    loader: CompanyPermissionsLoader,
  },
  {
    path: '/company/:id/users',
    element: <Users type="company" />,
    loader: CompanyUsersLoader,
  },
  {
    path: '/company/:id/vessels',
    element: <Vessels type="company" />,
    loader: CompanyVesselsLoader,
  },
  {
    path: '/company/:id/preferred-ports',
    element: <PreferredPorts />,
    loader: CompanyPreferredPortsLoader,
  },
  {
    path: '/company/:id/port-agency-costs',
    element: <PortAgencyCosts />,
    loader: CompanyPortAgencyCostsLoader,
  },
  {
    path: '/company/:id/travel-vendors',
    element: <TravelVendors />,
    loader: CompanyFlightsConfigLoader,
  },
  {
    path: '/company/:id/bunkering-estimates',
    element: <BunkeringEstimates />,
    loader: CompanyVesselsLoader,
  },
];

const routes = routesArr.map((route) => ({
  ...route,
  element: <Sidebar>{route.element}</Sidebar>,
}));

export default routes;
