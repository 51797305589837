import { Button, ButtonProps } from '@mui/material';
import React, { useState } from 'react';
import MaterialIcon from './MaterialIcon';
import { white } from 'lib/colors';

type Props = ButtonProps & {
  onClick: () => void;
  duration?: number;
};

const animationStyles = (rotating: boolean) => ({
  animation: rotating ? 'rotate 1s ease-in-out' : 'none',
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

const RefreshButton = ({ duration = 1000, onClick, ...props }: Props) => {
  const [rotating, setRotating] = useState<boolean>(false);

  const handleClick = () => {
    onClick();
    setRotating(true);
    setTimeout(() => setRotating(false), duration);
  };

  return (
    <Button {...props} onClick={handleClick} disabled={rotating}>
      <MaterialIcon
        name="refresh"
        color={white}
        style={{
          ...animationStyles(rotating),
        }}
      />
    </Button>
  );
};

export default RefreshButton;
