import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/selectors';
import { createTheme, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import {
  greywingTheme,
  greywingLightTheme,
  greywingDarkTheme,
} from 'lib/theme';
import App from '../../App';

export default function AppWithDarkmode() {
  const { theme: activeTheme } = useSelector(selectTheme);

  const theme = useMemo(
    () =>
      createTheme(
        deepmerge(
          greywingTheme,
          activeTheme === 'light' ? greywingLightTheme : greywingDarkTheme
        )
      ),
    [activeTheme]
  );

  return (
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  );
}
