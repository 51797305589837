import React, { useMemo } from 'react';
import { useNavigation } from 'react-router-dom';
import { Stack, Paper, styled } from '@mui/material';
import { useIsLightMode } from 'hooks';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { Sidebar } from 'components/Sidebar';
import MobileSidebar from 'components/Sidebar/MobileSidebar';
import { Loading } from 'components/State';
import { ToggleColorMode } from 'components/Theming';
import { blue40 } from 'lib/colors';
import { useSelector } from 'react-redux';
import { selectSidebar } from 'redux/selectors';

const Wrapper = styled(Stack)<{ bgcolor: string }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: ${({ bgcolor }) => bgcolor};
  @media (max-width: ${BREAK_POINT_XS}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled(Paper)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px 0 0 20px;
  @media (max-width: ${BREAK_POINT_XS}) {
    border-radius: unset;
  }
`;

const ContentContainer = styled(Paper)`
  padding: 2rem;
  height: calc(100% - 4rem);
  overflow: auto;
`;

const ButtonOverlay = styled('div')`
  margin-left: 1;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 10;
`;

function AuthLayout({ children }: { children: React.ReactNode }) {
  const isLightMode = useIsLightMode();
  const { state } = useNavigation();
  const { show } = useSelector(selectSidebar);

  const bgColor = useMemo(() => {
    if (isLightMode) return show ? '#2d4abc' : blue40;
    else return show ? '#1f1c1c' : '#2c2c2c';
  }, [isLightMode, show]);

  return (
    <Wrapper bgcolor={bgColor}>
      <ButtonOverlay>
        <ToggleColorMode />
      </ButtonOverlay>
      <Sidebar />
      <MobileSidebar />
      <ContentWrapper elevation={3}>
        {state === 'loading' && <Loading />}
        <ContentContainer>{children}</ContentContainer>
      </ContentWrapper>
    </Wrapper>
  );
}

export default AuthLayout;
