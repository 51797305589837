import React from 'react';
import { Router } from 'router';
import { styled, Stack } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';

if (process.env.REACT_APP_DATA_GRID_LICENSE) {
  console.log('Setting license key');
  LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_LICENSE);
}

const StyledContainer = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

function App() {
  return (
    <StyledContainer
      sx={{ color: 'text.primary', backgroundColor: 'background.default' }}
    >
      <Router />
    </StyledContainer>
  );
}

export default App;
