import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../types';
import { getAuthToken } from '@greywing-maritime/frontend-library/dist/utils/auth';

// Set the initial auth based on whether a token exists or has expired
// This prevents the initial load from flickering between loading and loaded
// when a previous session exists
export const initialAuth = getAuthToken().success;

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuth,
  reducers: {
    setUserAuth: (_, action: PayloadAction<boolean>) => action.payload,
  },
});

/* ----- selectors -----*/

export const selectUserAuth = ({ authed }: RootState) => authed;

/* ----- actions -----*/

export const { setUserAuth } = authSlice.actions;

/* ----- reducer -----*/

export default authSlice.reducer;
