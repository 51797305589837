import React, { useState } from 'react';
import { Typography, Tooltip, IconButton, Stack } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import PageHeader from 'components/PageHeader';
import { Creator, VesselTagCommon, VesselTags } from './types';
import { StyledAvatar } from 'styles/common';
import { stringAvatar } from 'utils/avatar';
import EditVesselTag from './components/EditVesselTag';
import Table from 'components/Table';
import { useLoaderData } from 'react-router-dom';
import MaterialIcon from 'components/MaterialIcon';

const RenderColor = (props: GridRenderCellParams<any, string>) => {
  const { value } = props;
  return (
    <Tooltip title={value} placement="top" arrow>
      <IconButton>
        <MaterialIcon name="circle" size={20} color={value} />
      </IconButton>
    </Tooltip>
  );
};

export const RenderCreator = (props: GridRenderCellParams<any, Creator>) => {
  const { value } = props;

  return value ? (
    <Stack direction={'row'} spacing={1} alignItems="center">
      <StyledAvatar {...stringAvatar(value.name, 'initials')} />
      <Stack>
        <Typography variant="body2" fontWeight={500}>
          {value.name}
        </Typography>
        <Typography variant="body2" fontWeight={300}>
          {value.email}
        </Typography>
      </Stack>
    </Stack>
  ) : null;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'displayName',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'colour',
    headerName: 'Colour',
    width: 120,
    renderCell: RenderColor,
  },
  {
    field: 'companyName',
    headerName: 'Company',
    width: 200,
    valueGetter: (params) => params.row.creator.company?.name ?? '-',
  },
  {
    field: 'creator',
    headerName: 'Created by',
    width: 250,
    renderCell: RenderCreator,
  },
];

function VesselTagsPage() {
  const { data: tags } = useLoaderData() as VesselTags;
  const [tag, setTag] = useState<VesselTagCommon | null>(null);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const handleOpenEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    setTag(row);
    handleOpenEditModal();
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Vessel Tags"
        subtitle="Update and manage vessel tags"
        icon="sell"
        chipText={tags.length + ' tags'}
      />

      <Table
        height="100%"
        rows={tags}
        columns={columns}
        onRowClick={handleRowClick}
        noRowsText="No tags found"
      />

      {tag && openEditModal ? (
        <EditVesselTag
          tag={tag}
          open={openEditModal}
          closeFn={handleOpenEditModal}
        />
      ) : null}
    </Stack>
  );
}

export default VesselTagsPage;
