import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button, TextField } from '@mui/material';
import { authedPost, handleAPIError } from 'utils/api';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { useRevalidator } from 'react-router-dom';
import { PORT_AGENTS_KEY } from 'query/queries';
import { OmegaPortAgentCommon } from '@greywing-maritime/frontend-library/dist/types/omegaPortAgents';
import LottieAnimation from 'components/LottieAnimation';

type Props = {
  type: 'enable' | 'disable';
  portAgent: OmegaPortAgentCommon;
  open: boolean;
  closeFn: () => void;
};

const TogglePortAgentMutation = async (values: {
  id: number;
  type: 'enable' | 'disable';
  disableReason: string | null;
}) => {
  const { id, type, disableReason } = values;
  const endpoint =
    type === 'enable'
      ? `/api/v2/omega/port-agents/${id}/enable`
      : `/api/v2/omega/port-agents/${id}/disable${
          disableReason ? '?reason=' + disableReason : ''
        }`;
  const response = await authedPost(endpoint);
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};

const TogglePortAgent = ({ portAgent, type, open, closeFn }: Props) => {
  const { id, email } = portAgent;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const [disableReason, setDisableReason] = useState<string | null>(null);

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: TogglePortAgentMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: PORT_AGENTS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = () => {
    mutate({
      id,
      type,
      disableReason: type === 'enable' ? null : disableReason,
    });
  };

  const headerComp = () => (
    <ModalHeader
      title="Toggle Port Agent"
      icon="toggle_on"
      subtitle={`${type === 'enable' ? 'Enable' : 'Disable'} ${email}`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        disabled={isLoading}
        color={type === 'enable' ? 'primary' : 'error'}
      >
        {type === 'enable' ? 'Enable' : 'Disable'}
      </Button>
    </Stack>
  );
  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <Stack spacing={2}>
        {type === 'enable' ? (
          <LottieAnimation
            name="switch-BZGWPLM3HE"
            height={200}
            width={200}
            loop={true}
          />
        ) : (
          <>
            <Typography variant="subtitle2">Reason for disabling ?</Typography>
            <TextField
              name="reason"
              placeholder="Your reason..."
              onChange={(e) => setDisableReason(e.target.value)}
              multiline
              rows={4}
            />
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default TogglePortAgent;
