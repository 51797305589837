import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { authedUpdate, handleAPIError } from 'utils/api';
import { UpdateFeatureSchema } from '../validation';
import { AccessControl } from '../types';
import {
  FeatureCommon,
  UpdateFeatureReq,
} from '@greywing-maritime/frontend-library/dist/types/features';
import Modal from 'components/Modal';
import { JsonEditorField, Select } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { useRevalidator } from 'react-router-dom';
import { FEATURES_KEY } from 'query/queries';
import { filterOptionalParams, parseNullableJson } from 'utils/helpers';

type Props = {
  feature: FeatureCommon;
  open: boolean;
  closeFn: () => void;
};

type UpdateFeatureType = yup.InferType<typeof UpdateFeatureSchema>;

const UpdateFeatureMutation = async (
  values: UpdateFeatureReq & { id: number }
) => {
  const { id, isPublic, config } = values;
  const endpoint = `/api/v2/omega/features/` + id;
  const response = await authedUpdate(endpoint, {
    isPublic,
    ...filterOptionalParams({ config: parseNullableJson(config) }),
  });
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};
const EditFeature = ({ feature, open, closeFn }: Props) => {
  const { id, isPublic, config, displayName } = feature;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<UpdateFeatureType>({
    defaultValues: {
      isPublic: isPublic ? 'Public' : 'Private',
      config: config ? JSON.stringify(config) : '{}',
    },
    resolver: yupResolver(UpdateFeatureSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UpdateFeatureMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: FEATURES_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: UpdateFeatureType) => {
    const { isPublic, config } = values;
    mutate({
      id,
      isPublic: isPublic === 'Public',
      config,
    });
  };

  const headerComp = () => (
    <ModalHeader
      title="Edit Feature"
      icon="edit"
      subtitle={`Update parameters for ${displayName}`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Update
      </Button>
    </Stack>
  );

  return (
    <Modal
      width={800}
      height={700}
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Access Control</Typography>
          <Select
            control={control}
            name="isPublic"
            size="small"
            options={AccessControl.map((o) => ({
              value: o,
              label: o,
            }))}
          />
          <Typography variant="subtitle2">Config</Typography>
          <JsonEditorField control={control} name="config" />
        </Stack>
      </form>
    </Modal>
  );
};

export default EditFeature;
