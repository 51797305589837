import React, { useEffect, useState } from 'react';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import moment from 'moment';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { blue, red, green, white } from 'lib/colors';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import AddDataPushJob from './components/AddDataPushJob';
import EditDataPushJob from './components/EditDataPushJob';
import { StyledChip } from 'styles/common';
import QueueDataPushJob from './components/QueueDataPushJob';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import {
  DefaultToolbarItems,
  DefaultToolbarSearch,
} from 'components/Table/Toolbar';
import RefreshButton from 'components/RefreshButton';
import {
  DataPushJobCommon,
  ListDataPushJobsResp,
} from '@greywing-maritime/frontend-library/dist/types/dataPushJobs';
import MaterialIcon from 'components/MaterialIcon';

const RenderStatus = (props: GridRenderCellParams<any, boolean>) => {
  const { value } = props;
  return <MaterialIcon name="circle" color={value ? green : red} size={14} />;
};

const RenderRun = (props: GridRenderCellParams<any, boolean | null>) => {
  const { value } = props;
  return value === null ? (
    <div>-</div>
  ) : (
    <StyledChip
      label={value ? 'Success' : 'Failed'}
      sx={{ backgroundColor: value ? blue : red }}
    />
  );
};

const RenderDate = (props: GridRenderCellParams<any, Date>) => {
  const { value } = props;
  return (
    <div>{value ? moment(value).format('MMM DD YYYY HH:mm:ss') : '-'}</div>
  );
};

const RenderFreq = (props: GridRenderCellParams<any, number>) => {
  const { value } = props;
  return <div>{value} minutes</div>;
};

const RenderActions = ({
  queueFn,
  editFn,
}: {
  queueFn: () => void;
  editFn: () => void;
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <Tooltip title="Edit Data Push Job" placement="top" arrow>
        <IconButton onClick={editFn}>
          <MaterialIcon name="edit" color={blue} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Queue Data Push Job" placement="top" arrow>
        <IconButton onClick={queueFn}>
          <MaterialIcon name="sync" rotate={90} color={blue} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const columns: GridColDef[] = [
  {
    field: 'enabled',
    headerName: '',
    width: 50,
    renderCell: RenderStatus,
  },
  {
    field: 'adapterId',
    headerName: 'Adapter ID',
    width: 150,
  },
  {
    field: 'displayName',
    headerName: 'Company',
    width: 150,
    valueGetter: (params) => params.row.company?.displayName ?? '-',
  },
  {
    field: 'frequencyInMins',
    headerName: 'Frequency',
    width: 150,
    renderCell: RenderFreq,
  },
  {
    field: 'lastRunStartedAt',
    headerName: 'Started At',
    width: 170,
    renderCell: RenderDate,
  },
  {
    field: 'lastRunCompletedAt',
    headerName: 'Completed At',
    width: 170,
    renderCell: RenderDate,
  },
  {
    field: 'lastRunSuccessful',
    headerName: 'Last Run',
    width: 150,
    renderCell: RenderRun,
  },
];

function DataPushJobsPage() {
  const { dataPushJobs } = useLoaderData() as ListDataPushJobsResp;
  const [dataPushJob, setDataPushJob] = useState<DataPushJobCommon | null>(
    null
  );
  const [showQueueModal, setShowQueueModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const revalidator = useRevalidator();
  const pollingInterval = 60000;

  useEffect(() => {
    setInterval(revalidator.revalidate, pollingInterval);
  }, []); //eslint-disable-line

  const toolbar = () => {
    return (
      <>
        <DefaultToolbarItems />
        <RefreshButton onClick={revalidator.revalidate} />
        <DefaultToolbarSearch />
      </>
    );
  };

  const handleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const handleEditModal = () => {
    setShowEditModal(!showEditModal);
  };
  const handleQueueModal = () => {
    setShowQueueModal(!showQueueModal);
  };

  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    setDataPushJob(row);
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: () => (
      <RenderActions queueFn={handleQueueModal} editFn={handleEditModal} />
    ),
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Data Push Jobs"
        subtitle="Update and manage data push jobs"
        chipText={dataPushJobs.length + ' jobs'}
        icon="backup_table"
        actions={
          <Button
            variant="contained"
            startIcon={<MaterialIcon name="add" color={white} />}
            onClick={handleAddModal}
          >
            Add
          </Button>
        }
      />

      <Table
        height="100%"
        rows={dataPushJobs}
        columns={[...columns, actions]}
        onRowClick={handleRowClick}
        noRowsText="No jobs found"
        initialState={{
          sorting: {
            sortModel: [{ field: 'adapterId', sort: 'asc' }],
          },
        }}
        toolbar={toolbar()}
        nonClickable
      />

      {showAddModal ? (
        <AddDataPushJob open={showAddModal} closeFn={handleAddModal} />
      ) : null}

      {dataPushJob && showEditModal ? (
        <EditDataPushJob
          dataPushJob={dataPushJob}
          open={showEditModal}
          closeFn={handleEditModal}
        />
      ) : null}

      {dataPushJob && showQueueModal ? (
        <QueueDataPushJob
          dataPushJob={dataPushJob}
          open={showQueueModal}
          closeFn={handleQueueModal}
        />
      ) : null}
    </Stack>
  );
}

export default DataPushJobsPage;
