import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import { AuthWrapper } from 'pages/auth';
import DashboardRoute from 'pages/dashboard/route';
import CompanyRoutes from 'pages/company/route';
import RiskAreaRoute from 'pages/risk-area/route';
import UsersRoute from 'pages/users/route';
import FeaturesRoute from 'pages/features/route';
import SettingsRoute from 'pages/settings/route';
import FlightRoute from 'pages/flights/route';
import VesselTagsRoute from 'pages/vessel-tags/route';
import SeaGPTRoute from 'pages/proteus/route';
import CMSJobsRoute from 'pages/cms-jobs/route';
import VMSJobsRoute from 'pages/vms-jobs/route';
import PortAgentsRoute from 'pages/port-agents/route';
import SeaGPTEmailRoute from 'pages/seagpt-email/route';
import PortAgencyCostsRoute from 'pages/port-agency-costs/route';
import PortRestrictionsRoute from 'pages/port-restrictions/route';
import DataPushJobsRoute from 'pages/data-push-jobs/route';
import SeaGPTParsingRoute from 'pages/proteus-parsing/route';
import { filterOptionalParams } from 'utils/helpers';
import { getAuthToken } from '@greywing-maritime/frontend-library/dist/utils/auth';

const protectedRoutes = (routes: RouteObject[]): RouteObject[] =>
  routes.map((route) => {
    const { loader, children, ...rest } = route;
    return {
      ...rest,
      loader: async (props) => {
        const { success } = getAuthToken();
        if (success && loader) return loader(props);
        if (!success)
          throw new Response('Login to access this page !', { status: 403 });
        return null;
      },
      ...filterOptionalParams({
        children: children ? protectedRoutes(children) : undefined,
      }),
    };
  });

// ------ Router --------

const routes = createBrowserRouter([
  {
    path: '/',
    element: <AuthWrapper />,
    errorElement: <ErrorBoundary />,
    children: protectedRoutes([
      ...DashboardRoute,
      ...CompanyRoutes,
      ...RiskAreaRoute,
      ...PortAgentsRoute,
      ...UsersRoute,
      ...FeaturesRoute,
      ...SettingsRoute,
      ...FlightRoute,
      ...VesselTagsRoute,
      ...SeaGPTRoute,
      ...CMSJobsRoute,
      ...VMSJobsRoute,
      ...DataPushJobsRoute,
      ...SeaGPTEmailRoute,
      ...PortAgencyCostsRoute,
      ...PortRestrictionsRoute,
      ...SeaGPTParsingRoute,
    ]),
  },
]);

function Router() {
  return <RouterProvider router={routes} />;
}

export default Router;
