import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Typography } from '@mui/material';
import { TextField } from 'components/Forms';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
function LocalBackend() {
  const handleLocal = ({ port }: { port: string }) => {
    localStorage.setItem('API_URL', `http://localhost:${port}`);
    window.location.replace('/');
  };

  const { handleSubmit, control } = useForm<{ port: string }>({
    defaultValues: {
      port: '8100',
    },
    resolver: yupResolver(
      yup.object().shape({
        port: yup.string().required(),
      })
    ),
  });

  return (
    <Stack alignItems="start">
      <Typography variant="button" sx={{ fontWeight: 'bold' }}>
        Local backend
      </Typography>
      <Typography variant="body2" mt={1}>
        If you are a developer, you can use a local backend for testing on a
        local port. This will allow you to test the app without having to deploy
        your own backend.
      </Typography>
      <form onSubmit={handleSubmit(handleLocal)}>
        <Stack rowGap={3} mt={3}>
          <TextField label="Port number" control={control} name="port" />
          <Button variant="contained" type="submit">
            Use Local Backend
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default LocalBackend;
