import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import authReducer, { initialAuth } from './auth';
import themeReducer, { initialTheme } from './theme';
import sidebarReducer, { initialSidebar } from './sidebar';
import scrollStateReducer, { initialScrollState } from './scrollState';

const appInitialState = {
  authed: initialAuth,
  theme: initialTheme,
  sidebar: initialSidebar,
  scrollState: initialScrollState,
};

const appReducer = combineReducers({
  authed: authReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  scrollState: scrollStateReducer,
});

type Reducer = ReturnType<typeof appReducer>;

const rootReducer = (state: any, action: PayloadAction<any>) => {
  if (action.type === 'store/reset') {
    // initial authed will be true, if the application loads with a previous session
    return appReducer({ ...appInitialState, authed: initialAuth }, action);
  }
  return appReducer(state as Reducer, action);
};

export default rootReducer;
