import {
  ListAllPortAgentsResp,
  OmegaPortAgentCommon,
} from '@greywing-maritime/frontend-library/dist/types/omegaPortAgents';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import { blue, red, textGray } from 'lib/colors';
import React, { useCallback, useState } from 'react';
import { useLoaderData } from 'react-router';
import EditPortAgent from './components/EditPortAgent';
import TogglePortAgent from './components/TogglePortAgent';
import { CodeCard } from 'components/Table/Cards';
import {
  DataGridProProps,
  GridCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import MaterialIcon from 'components/MaterialIcon';

const RenderActions = ({
  editFn,
  toggleFn,
  disabled,
  disabledReason,
}: {
  editFn: () => void;
  toggleFn: () => void;
  disabled: boolean;
  disabledReason: string | null;
}) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Tooltip title="Edit Port Agent" placement="top" arrow>
        <IconButton onClick={editFn}>
          <MaterialIcon name="edit" color={blue} />
        </IconButton>
      </Tooltip>
      <Stack direction="row">
        <Tooltip title="Toggle Port Agent" placement="top" arrow>
          <IconButton onClick={toggleFn}>
            {!disabled ? (
              <MaterialIcon name="toggle_on" size={35} color={blue} />
            ) : (
              <MaterialIcon name="toggle_off" size={35} color={red} />
            )}
          </IconButton>
        </Tooltip>
        {disabled && disabledReason ? (
          <Tooltip title={'Reason: ' + disabledReason} placement="top" arrow>
            <IconButton>
              <MaterialIcon name="help" color={textGray} />
            </IconButton>
          </Tooltip>
        ) : null}
      </Stack>
    </Stack>
  );
};

const columns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
  },
  {
    field: 'port',
    headerName: 'Port',
    width: 200,
    valueGetter: (params) =>
      `${params.row.countryIso} - ${params.row.portLocode} (${params.row.region})`,
  },
  {
    field: 'agency',
    headerName: 'Agency',
    width: 250,
  },
];

const PortAgentsPage = () => {
  const {
    portAgents,
    dictionary: { companies, users },
  } = useLoaderData() as ListAllPortAgentsResp;
  const apiRef = useGridApiRef();
  const [portAgent, setPortAgent] = useState<OmegaPortAgentCommon | null>(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showToggleModal, setShowToggleModal] = useState<boolean>(false);

  const handleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleToggleModal = () => {
    setShowToggleModal(!showToggleModal);
  };

  const handleCellClick = (params: GridCellParams) => {
    const { id, row, field } = params;
    if (field === 'actions') setPortAgent(row);
    else apiRef.current.toggleDetailPanel(id);
  };

  const extraColumns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: 'Company',
      width: 150,
      valueGetter: ({ row }) => {
        const userId = parseInt(row.id);
        if (userId in users) {
          const companyId = users[userId].companyId;
          if (companyId && companyId in companies) {
            return companies[companyId].displayName;
          }
        }
        return '-';
      },
    },
    {
      field: 'public',
      headerName: 'is Public?',
      width: 120,
    },
  ];

  const actions: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 160,
      valueGetter: (params) => ({
        disabled: params.row.disabled,
        disabledReason: params.row.disabledReason,
      }),
      renderCell: ({
        value,
      }: GridRenderCellParams<
        any,
        { disabled: boolean; disabledReason: string | null }
      >) => (
        <RenderActions
          editFn={handleEditModal}
          toggleFn={handleToggleModal}
          disabled={value!.disabled}
          disabledReason={value!.disabledReason}
        />
      ),
    },
  ];

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(
    ({ row: { meta } }) => (
      <CodeCard>
        <pre>
          {meta && Object.keys(meta).length
            ? JSON.stringify(meta, null, 2)
            : 'No Meta Info available.'}
        </pre>
      </CodeCard>
    ),
    []
  );

  return (
    <Stack height="100%">
      <PageHeader
        title="Port Agents"
        subtitle="Update and manage port agents"
        chipText={portAgents.length + ' agents'}
        icon="support_agent"
      />

      <Table
        apiRef={apiRef}
        height="100%"
        rows={portAgents}
        columns={[...columns, ...extraColumns, ...actions]}
        onCellClick={handleCellClick}
        noRowsText="No port agents found"
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
      />

      {portAgent && showEditModal ? (
        <EditPortAgent
          portAgent={portAgent}
          open={showEditModal}
          closeFn={handleEditModal}
        />
      ) : null}

      {portAgent && showToggleModal ? (
        <TogglePortAgent
          type={portAgent.disabled ? 'enable' : 'disable'}
          portAgent={portAgent}
          open={showToggleModal}
          closeFn={handleToggleModal}
        />
      ) : null}
    </Stack>
  );
};

export default PortAgentsPage;
