import { RouteObject } from 'react-router';
import QuickflyPage from './quickfly';

const routes: RouteObject[] = [
  {
    path: '/flights',
    element: <QuickflyPage />,
  },
];

export default routes;
