import ModalHeader from 'components/ModalHeader';
import React, { useState } from 'react';
import Modal from 'components/Modal';
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { ParsedResultsModalType } from 'pages/seagpt-email/types';
import { capitalize } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams, useRevalidator } from 'react-router-dom';
import { CONVERSATION_KEY } from 'query/queries';
import { UpdateParsedResultsMutation } from '../ParsedResults';
import { red } from 'lib/colors';
import LottieAnimation from 'components/LottieAnimation';
import { AssistedCrewChangeEmailParsedResults } from '@greywing-maritime/frontend-library/dist/types/acc';
import MaterialIcon, { MaterialIconType } from 'components/MaterialIcon';

const StyledStack = styled(Stack)`
  border: 1px solid rgb(189, 189, 189);
  border-radius: 6px;
  background: rgb(250, 250, 250);
`;

const StyledRow = styled(Stack)`
  padding: 0.5rem;
  border-bottom: 1px solid rgb(189, 189, 189);
  position: relative;
  &:last-child {
    border-bottom: 0px;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0px;
  top: 0px;
`;

type Props = {
  type: ParsedResultsModalType;
  parsedResults: AssistedCrewChangeEmailParsedResults;
  aceIdforUpdate: number;
  icon: MaterialIconType;
  closeFn: () => void;
};

const EditParsedResults = ({
  type,
  parsedResults,
  aceIdforUpdate,
  icon,
  closeFn,
}: Props) => {
  const title = capitalize(type.split('-').join(' '));
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const params = useParams();
  const convoId = parseInt(params.convoId!);
  const [priceConditions, setPriceConditions] = useState<string>(
    parsedResults.priceConditions || ''
  );
  const [costCalculation, setCostCalculation] = useState<string>(
    parsedResults.appliedCosts?.costCalculation || ''
  );
  const [parsedRestrictions, setParsedRestrictions] = useState<string[]>(
    parsedResults.parsedRestrictions || []
  );
  const [newRestriction, setNewRestriction] = useState<string>('');

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UpdateParsedResultsMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: CONVERSATION_KEY(convoId),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    mutate({
      ...parsedResults,
      priceConditions: priceConditions?.length ? priceConditions : null,
      parsedRestrictions: parsedRestrictions?.length
        ? parsedRestrictions
        : null,
      appliedCosts: parsedResults.appliedCosts
        ? {
            ...parsedResults.appliedCosts,
            costCalculation: costCalculation?.length ? costCalculation : null,
          }
        : null,
      aceIdforUpdate,
    });
  };

  const headerComp = () => (
    <ModalHeader
      title={title}
      icon="edit"
      subtitle={`Update ${title} within parsed results`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        disabled={isLoading}
      >
        Save
      </Button>
    </Stack>
  );

  return (
    <Modal
      width={800}
      height={type === 'parsed-restrictions' ? '100%' : 800}
      open
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <Stack spacing={2}>
        {type === 'price-conditions' ? (
          <TextField
            placeholder="Enter Price Conditions..."
            value={priceConditions}
            onChange={(e) => setPriceConditions(e.target.value)}
            multiline
            rows={20}
            autoFocus
          />
        ) : null}
        {type === 'cost-calculation' ? (
          <TextField
            placeholder="Enter Cost Calculation..."
            value={costCalculation}
            onChange={(e) => setCostCalculation(e.target.value)}
            multiline
            rows={20}
            autoFocus
          />
        ) : null}
        {type === 'parsed-restrictions' ? (
          <>
            <Stack direction="row" spacing={2}>
              <TextField
                placeholder="Enter Restriction..."
                onChange={(e) => setNewRestriction(e.target.value)}
                size="small"
                fullWidth
                autoFocus
              />
              <Button
                variant="contained"
                onClick={() =>
                  setParsedRestrictions((parsedRestrictions) => [
                    ...parsedRestrictions,
                    newRestriction,
                  ])
                }
              >
                Add
              </Button>
            </Stack>
            {parsedRestrictions.length ? (
              <Stack minHeight={200}>
                <StyledStack maxHeight={400} overflow="auto">
                  {[...parsedRestrictions]
                    .reverse()
                    .map((restriction, index) => (
                      <StyledRow key={index}>
                        <Typography variant="body2">{restriction}</Typography>
                        <StyledIconButton
                          onClick={() => {
                            setParsedRestrictions((parsedRestrictions) =>
                              parsedRestrictions?.filter((_, i) => i !== index)
                            );
                          }}
                        >
                          <MaterialIcon name="delete" color={red} size={20} />
                        </StyledIconButton>
                      </StyledRow>
                    ))}
                </StyledStack>
              </Stack>
            ) : (
              <Stack height={200} alignItems="center">
                <LottieAnimation
                  name="search-imm-xZN2Dotxbr"
                  width="10rem"
                  height="10rem"
                  loop
                />
                <Typography variant="subtitle2" fontWeight={500} mb={2}>
                  No Restrictions Available
                </Typography>
              </Stack>
            )}
          </>
        ) : null}
      </Stack>
    </Modal>
  );
};

export default EditParsedResults;
