import * as yup from 'yup';

export const PermissionSchema = yup.object().shape({
  id: yup.number().positive().required(),
  featureId: yup.number().positive().required(),
  type: yup.string().oneOf(['allow', 'deny']).required(),
  params: yup
    .string()
    .test('is-valid-object', 'Invalid JSON string', (value) => {
      try {
        if (value) {
          if (value === '') return true;
          const parsedObject = JSON.parse(value);
          return typeof parsedObject === 'object' && parsedObject !== null;
        }
      } catch (error) {
        return false; // JSON parsing failed
      }
    })
    .required(),
});
