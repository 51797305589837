import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Stack, Button } from '@mui/material';
import Modal from 'components/Modal';
import { JsonEditorField, Select } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { CompanyPermissionType } from '../types';
import { PermissionSchema } from '../validation';
import { AccessControl } from '../types';
import { authedUpdate, handleAPIError } from 'utils/api';
import { useRevalidator } from 'react-router-dom';
import { COMPANY_KEY } from 'query/queries';
import { capitalize } from 'lodash';

type Props = {
  permission: CompanyPermissionType & { id: number };
  open: boolean;
  closeFn: () => void;
};

type UpdatePermissionType = yup.InferType<typeof PermissionSchema>;

export const UpdateCompanyPermissionsMutation = async (
  values: UpdatePermissionType
) => {
  const { id, featureId, type, params } = values;
  const endpoint = `/api/v2/omega/companies/${id}/company-perms/features/${featureId}`;
  const response = await authedUpdate(endpoint, {
    type,
    params: JSON.parse(params),
  });
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

const EditPermission = ({ permission, open, closeFn }: Props) => {
  const { id, featureId, type, params, displayName } = permission;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<UpdatePermissionType>({
    defaultValues: {
      id,
      featureId,
      type,
      params: params ? JSON.stringify(params) : '{}',
    },
    resolver: yupResolver(PermissionSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UpdateCompanyPermissionsMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: COMPANY_KEY(id),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: UpdatePermissionType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Edit Permission"
      icon="edit"
      subtitle={`Update parameters for ${displayName}`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Update
      </Button>
    </Stack>
  );

  return (
    <Modal
      width={800}
      height={700}
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Access Control</Typography>
          <Select
            control={control}
            name="type"
            size="small"
            options={AccessControl.map((o) => ({
              value: o,
              label: capitalize(o),
            }))}
          />
          <Typography variant="subtitle2">Config</Typography>
          <JsonEditorField control={control} name="params" />
        </Stack>
      </form>
    </Modal>
  );
};

export default EditPermission;
