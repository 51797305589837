import React, { useState } from 'react';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import PageHeader from 'components/PageHeader';
import { useLoaderData } from 'react-router-dom';
import {
  FlotillaVesselCommon,
  ListFlotVesResp,
} from '@greywing-maritime/frontend-library/dist/types/omegaFlotillaVessels';
import Table from 'components/Table';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import AddVessel from './components/AddVessels';
import AssignPIC from './components/AssignPIC';
import { blue, red, white } from 'lib/colors';
import DeleteVessel from './components/DeleteVessel';
import MaterialIcon from 'components/MaterialIcon';

const RenderFlag = (props: GridRenderCellParams<any, string>) => {
  const { value } = props;
  return value ? (
    <>
      <img
        loading="lazy"
        src={'https://flagsapi.com/' + value + '/flat/24.png'}
        alt={value}
        style={{ marginRight: '10px' }}
      />
      {value}
    </>
  ) : null;
};

const RenderActions = ({
  deleteFn,
  assignPICFn,
}: {
  deleteFn: () => void;
  assignPICFn: () => void;
}) => {
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Assign New PIC" placement="top" arrow>
        <IconButton onClick={assignPICFn}>
          <MaterialIcon name="person" color={blue} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Vessel" placement="top" arrow>
        <IconButton onClick={deleteFn}>
          <MaterialIcon name="delete" color={red} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'vesselName', headerName: 'Vessel Name', width: 200 },
  { field: 'imo', headerName: 'IMO', width: 90 },
  { field: 'status', headerName: 'Status', width: 200 },
  { field: 'type', headerName: 'Type', width: 200 },
  { field: 'flag', headerName: 'Flag', width: 90, renderCell: RenderFlag },
  { field: 'picId', headerName: 'PIC ID', width: 90 },
];

const VesselsPage = ({ type }: { type: 'company' | 'user' }) => {
  const { flotillaVessels } = useLoaderData() as ListFlotVesResp;
  const [vessel, setVessel] = useState<FlotillaVesselCommon | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAssignPICModal, setShowAssignPICModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const handleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleAssignPICModal = () => {
    setShowAssignPICModal(!showAssignPICModal);
  };

  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    setVessel(row);
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: () => (
      <RenderActions
        assignPICFn={handleAssignPICModal}
        deleteFn={handleDeleteModal}
      />
    ),
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Vessels"
        subtitle="View and manage vessels"
        chipText={flotillaVessels.length + ' vessels'}
        icon="directions_boat"
        actions={
          <Button
            variant="contained"
            startIcon={<MaterialIcon name="add" color={white} />}
            onClick={handleAddModal}
          >
            Add
          </Button>
        }
      />
      <Table
        height="100%"
        rows={flotillaVessels}
        columns={[...columns, actions]}
        onRowClick={handleRowClick}
        noRowsText="No vessels found"
        nonClickable
      />

      {showAddModal ? (
        <AddVessel type={type} open={showAddModal} closeFn={handleAddModal} />
      ) : null}

      {vessel && showDeleteModal ? (
        <DeleteVessel
          type={type}
          imo={parseInt(vessel.imo)}
          open={showDeleteModal}
          closeFn={handleDeleteModal}
        />
      ) : null}

      {vessel && showAssignPICModal ? (
        <AssignPIC
          type={type}
          imo={parseInt(vessel.imo)}
          open={showAssignPICModal}
          closeFn={handleAssignPICModal}
        />
      ) : null}
    </Stack>
  );
};

export default VesselsPage;
