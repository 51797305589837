import { resetStore, setUserAuth } from 'redux/actions';
import { AppDispatch } from 'redux/types';
import {
  clearLocalStorage,
  getAuthToken,
} from '@greywing-maritime/frontend-library/dist/utils/auth';
import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';
import { SESSION_TOKEN_EXPIRY } from '@greywing-maritime/frontend-library/dist/config/tokens';

// util executing steps for logout
export const logoutUser = (dispatch: AppDispatch) => {
  // clear values
  clearLocalStorage();
  sessionStorage.clear();

  // dispatch actions
  dispatch(resetStore());
  dispatch(setUserAuth(false));
};

export const authenticateUser = async (): Promise<boolean> => {
  try {
    console.log('Verifying auth...');
    const authToken = getAuthToken();

    if (!authToken.success) return false;

    const res = await fetch(`${getAPIUrl()}/api/v2/users/refresh-token`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken.token}`,
      },
    });

    let result = await res.json();

    if (result.updatedExpiry) {
      window.localStorage.setItem(SESSION_TOKEN_EXPIRY, result.updatedExpiry);
      console.log('Verified auth !');
      return true;
    }

    return false;
  } catch (err) {
    console.error('Error verifying auth - ', err);
    return false;
  }
};
