import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Typography, Stack, Button } from '@mui/material';
import { authedUpdate, handleAPIError } from 'utils/api';
import { Success, Error, Loading } from 'components/State';
import Stats from 'components/Stats';
import PageHeader from 'components/PageHeader';
import { Currency, TextField } from 'components/Forms';
import { UpdateCompanySchema } from '../validation';
import { CompanyCommon } from '@greywing-maritime/frontend-library/dist/types/companies';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { GetStatsResp } from '@greywing-maritime/frontend-library/dist/types/usageStats';
import { COMPANIES_KEY, COMPANY_KEY } from 'query/queries';
import { getCurrencyFromCode } from 'lib/currencies';

type UpdateCompanyType = yup.InferType<typeof UpdateCompanySchema>;

const UpdateCompanyMutation = async (values: UpdateCompanyType) => {
  const { id, displayName, credentials, parameters } = values;
  const { preferredCurrency, ...rest } = parameters;
  const endpoint = `/api/v2/omega/companies/` + id;
  const response = await authedUpdate(endpoint, {
    displayName,
    emailDomains: [],
    credentials,
    parameters: preferredCurrency === 'None' ? rest : parameters,
  });
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

type LoaderProps = {
  stats: GetStatsResp;
  company: CompanyCommon;
};

const Details = () => {
  const queryClient = useQueryClient();
  const { stats, company } = useLoaderData() as LoaderProps;
  const revalidator = useRevalidator();
  const preferredCurrency = (company as CompanyCommon).parameters
    .preferredCurrency;

  const { handleSubmit, control } = useForm<UpdateCompanyType>({
    defaultValues: {
      ...company,
      parameters: {
        preferredCurrency:
          preferredCurrency && getCurrencyFromCode(preferredCurrency)
            ? preferredCurrency
            : 'None',
      },
    },
    resolver: yupResolver(UpdateCompanySchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UpdateCompanyMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: COMPANY_KEY(company.id),
        exact: true,
      });
      await queryClient.refetchQueries({
        queryKey: COMPANIES_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: UpdateCompanyType) => {
    mutate(values);
  };

  return (
    <Stack>
      <PageHeader
        title="Company Info"
        subtitle="Update Company Details here"
        icon="apartment"
        actions={
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            disabled={isLoading}
          >
            Save
          </Button>
        }
      />

      <Stack mb={4}>
        <Stats data={stats} />
      </Stack>

      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Display Name</Typography>
          <TextField
            control={control}
            name="displayName"
            placeholder="Greywing"
            size="small"
            autoFocus
          />
          <Typography variant="subtitle2">Domain Name</Typography>
          <TextField
            control={control}
            name="domain"
            placeholder="greywing"
            size="small"
          />
          <Typography variant="subtitle2">Preferred Currency</Typography>
          <Currency
            control={control}
            size="small"
            name="parameters[preferredCurrency]"
          />
        </Stack>
      </form>

      {isLoading ? <Loading /> : null}
      {isSuccess ? <Success /> : null}
      {isError ? <Error text={error as Error} /> : null}
    </Stack>
  );
};

export default Details;
