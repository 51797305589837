import React from 'react';
import { stringAvatar } from 'utils/avatar';
import { Typography, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { StyledAvatar } from 'styles/common';

type AvatarType = {
  email?: string | null;
  name?: string | null;
};

export const AvatarCardV2 = ({ value }: { value: AvatarType }) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems="center">
      <StyledAvatar
        {...stringAvatar(value.name || value.email || '', 'initials')}
      />
      <Stack>
        {value.name ? (
          <Typography variant="body2" fontWeight={700}>
            {value.name}
          </Typography>
        ) : null}
        {value.email ? (
          <Typography variant="body2" fontWeight={400}>
            {value.email}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

const RenderAvatarV2 = (props: GridRenderCellParams<any, AvatarType>) => {
  const { value } = props;

  return value ? <AvatarCardV2 value={value} /> : null;
};

export default RenderAvatarV2;
