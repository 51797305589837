import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Stack, Button } from '@mui/material';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { authedPost, handleAPIError } from 'utils/api';
import { VmsJobCommon } from '@greywing-maritime/frontend-library/dist/types/vms';
import LottieAnimation from 'components/LottieAnimation';
import { VMS_JOBS_KEY } from 'query/queries';
import { useRevalidator } from 'react-router-dom';

type Props = {
  vmsJob: VmsJobCommon;
  open: boolean;
  closeFn: () => void;
};

const QueueJobMutation = async (id: number) => {
  const endpoint = `/api/v2/omega/vms/jobs/${id}/queue`;
  const response = await authedPost(endpoint);
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};
const QueueVmsJob = ({ vmsJob, open, closeFn }: Props) => {
  const { id } = vmsJob;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: QueueJobMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: VMS_JOBS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    mutate(id);
  };

  const headerComp = () => (
    <ModalHeader
      title="Queue Vms Job"
      icon="sync"
      subtitle={`Add vms job to the queue`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        disabled={isLoading}
      >
        Queue
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <LottieAnimation
        name="platform-loading-X5sgEHtKwe"
        height={200}
        width={200}
        loop={true}
      />
    </Modal>
  );
};

export default QueueVmsJob;
