import { RouteObject } from 'react-router';
import VMSJobsPage from './VmsJobs';
import { VmsLoader } from './loader';

const routes: RouteObject[] = [
  {
    path: '/vms-jobs',
    element: <VMSJobsPage />,
    loader: VmsLoader,
  },
];

export default routes;
