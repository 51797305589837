import React from 'react';
import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EmailCountType } from '../../types';
import { blue, green } from 'lib/colors';
import MaterialIcon from 'components/MaterialIcon';

const RenderEmailCount = (props: GridRenderCellParams<any, EmailCountType>) => {
  const { value } = props;

  return value ? (
    <Stack direction={'row'} spacing={2} alignItems="center">
      <Stack direction={'row'} spacing={1}>
        <MaterialIcon name="play_circle" rotate={-90} size={20} color={green} />
        <Typography variant="body2" fontWeight={700}>
          {value.numEmailsSent}
        </Typography>
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <MaterialIcon name="play_circle" rotate={90} size={20} color={blue} />
        <Typography variant="body2" fontWeight={700}>
          {value.numEmailsReceived}
        </Typography>
      </Stack>
    </Stack>
  ) : null;
};

export default RenderEmailCount;
