import React, { MouseEvent, useEffect, useState } from 'react';
import {
  DataGridPro,
  DataGridProProps,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import Toolbar from './Toolbar';
import { styled, Stack, Typography, Menu, MenuItem } from '@mui/material';
import { gray20 } from 'lib/colors';
import { white } from 'lib/colors';
import { useSelector } from 'react-redux';
import { selectScrollState } from 'redux/selectors';
import { useAppDispatch } from 'hooks';
import { setScrollState } from 'redux/actions';
import { useLocation } from 'react-router-dom';
import MaterialIcon from 'components/MaterialIcon';

const Wrapper = styled(Stack)`
  height: 100%;
  width: 100%;
  margin: 1rem;
  align-items: center;
  justify-content: center;
`;

const ContextMenu = styled(Menu)`
  .MuiMenu-paper {
    background-color: #1f1f1f;
    color: ${white};
  }
`;

const ContextMenuItem = styled(MenuItem)`
  font-size: 12.5px;
  padding: 0.2rem 2rem;
  :hover {
    background-color: #363635;
  }
`;

const CustomOverlay = ({
  text,
  actions,
}: {
  text: string;
  actions: React.ReactNode;
}) => {
  return (
    <Wrapper>
      <Typography variant="subtitle2" fontWeight={500} mb={2}>
        {text}
      </Typography>
      {actions ? <Stack>{actions}</Stack> : null}
    </Wrapper>
  );
};

type Props = DataGridProProps & {
  name?: string;
  height?: string | number;
  width?: string | number;
  noResultsText?: string;
  noResultsActions?: React.ReactNode;
  noRowsText?: string;
  noRowsActions?: React.ReactNode;
  nonClickable?: boolean;
  toolbar?: React.ReactNode;
  openInNewTab?: (rowId: number) => string;
  disableScrollRestoration?: boolean;
};

const Table = (props: Props) => {
  const {
    name,
    height = 600,
    width = '100%',
    noResultsText = 'No results',
    noResultsActions,
    noRowsText = 'No rows',
    noRowsActions,
    nonClickable = false,
    toolbar,
    slots,
    slotProps,
    sx,
    openInNewTab,
    disableScrollRestoration = false,
    apiRef: customRef,
    ...rest
  } = props;

  const ref = useGridApiRef();
  const apiRef = customRef || ref;
  const scrollState = useSelector(selectScrollState);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  // if initial scroll state then restore scroll
  useEffect(() => {
    if (apiRef?.current && !disableScrollRestoration) {
      let key = pathname;
      if (name) key = `${pathname}-${name}`;
      if (key in scrollState) {
        const unsubscribe = apiRef.current.subscribeEvent(
          'scrollPositionChange',
          () => {
            apiRef.current.scroll(scrollState[key]);
            unsubscribe();
          }
        );
      }
      // keep saving current scroll position
      apiRef.current.subscribeEvent('scrollPositionChange', () => {
        dispatch(
          setScrollState({
            [key]: apiRef.current.getScrollPosition(),
          })
        );
      });
    }
  }, [apiRef]); //eslint-disable-line

  const [selectedRow, setSelectedRow] = useState<number>();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 2 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Stack sx={{ height: height, width: width }}>
      <DataGridPro
        {...rest}
        apiRef={apiRef}
        slots={{
          toolbar: Toolbar,
          noResultsOverlay: () => (
            <CustomOverlay text={noResultsText} actions={noResultsActions} />
          ),
          noRowsOverlay: () => (
            <CustomOverlay text={noRowsText} actions={noRowsActions} />
          ),
          detailPanelExpandIcon: () => <MaterialIcon name="expand_more" />,
          detailPanelCollapseIcon: () => <MaterialIcon name="expand_less" />,
          ...slots,
        }}
        slotProps={{
          toolbar: {
            toolbar: toolbar,
          },
          panel: {
            disablePortal: true,
          },
          row: {
            onContextMenu: handleContextMenu,
          },
          ...slotProps,
        }}
        sx={{
          '& .MuiDataGrid-row:hover': {
            cursor: nonClickable ? 'default' : 'pointer',
          },
          '& .MuiDataGrid-detailPanel': {
            bgcolor: gray20,
            p: 2,
            boxSizing: 'border-box',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          ...sx,
        }}
      />
      {openInNewTab && selectedRow ? (
        <ContextMenu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          slotProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
        >
          <ContextMenuItem
            onClick={() => {
              window.open(openInNewTab(selectedRow), '_blank');
              handleClose();
            }}
          >
            Open in new tab
          </ContextMenuItem>
        </ContextMenu>
      ) : null}
    </Stack>
  );
};

export default Table;
