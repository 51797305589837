import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Stack, Button, Typography } from '@mui/material';
import { useParams, useRevalidator } from 'react-router-dom';

import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { authedUpdate, handleAPIError } from 'utils/api';
import LottieAnimation from 'components/LottieAnimation';
import type { AssistedCrewChangeEmail } from '../../types';
import { CONVERSATION_KEY } from 'query/queries';

type Props = {
  email: AssistedCrewChangeEmail;
  closeFn: () => void;
};

const ReleaseMutation = async ({ aceId }: { aceId: number }) => {
  const endpoint = `/api/v2/omega/assisted-crew-changes/emails/${aceId}/release`;
  const response = await authedUpdate(endpoint);
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};
const HoldMutation = async ({ aceId }: { aceId: number }) => {
  const endpoint = `/api/v2/omega/assisted-crew-changes/emails/${aceId}/hold`;
  const response = await authedUpdate(endpoint);
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};

const HoldRelease = ({ email: { onHold, id: aceId }, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const params = useParams();
  const convoId = parseInt(params.convoId!);

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: onHold ? ReleaseMutation : HoldMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: CONVERSATION_KEY(convoId),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    mutate({ aceId });
  };

  const headerComp = () => (
    <ModalHeader
      title={onHold ? 'Release Email' : 'Hold Email'}
      icon={onHold ? 'mark_email_read' : 'mark_email_unread'}
      subtitle={
        onHold
          ? `Release Email and Results to the User`
          : `Hold back Email and Results from the User`
      }
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        disabled={isLoading}
      >
        {onHold ? 'Release' : 'Hold'}
      </Button>
    </Stack>
  );

  return (
    <Modal
      open
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <Typography variant="body2">
        {onHold
          ? 'This will release the email and results to the user.'
          : 'This will hold back the email and results from the user.'}
      </Typography>
      <LottieAnimation
        name="support-lK5ffCOEjh"
        height={300}
        width={300}
        loop={true}
      />
    </Modal>
  );
};

export default HoldRelease;
