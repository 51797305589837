import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Stack, Button } from '@mui/material';
import Modal from 'components/Modal';
import { PasswordField, TextField, Switch } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { AddUserSchema } from '../validation';
import { authedPost, handleAPIError } from 'utils/api';
import { useRevalidator } from 'react-router-dom';
import { COMPANY_KEY, USERS_KEY } from 'query/queries';
import { CompanyCommon } from '@greywing-maritime/frontend-library/dist/types/companies';
import { AssignUsersMutation } from './AssignUsers';
import { OmegaSignUpUserResp } from '@greywing-maritime/frontend-library/dist/types/omegaUserAccounts';

type AddUserMutationType = {
  user: AddUserType;
  company?: CompanyCommon;
};

const AddUserMutation = async (values: AddUserMutationType) => {
  const {
    user: { confirmPassword, ...rest },
    company,
  } = values;
  const endpoint = `/api/v2/omega/user-accounts/sign-up`;
  const response = await authedPost(endpoint, rest);
  const data = (await response.json()) as OmegaSignUpUserResp;
  handleAPIError(response.status, data);
  if (company && 'id' in company)
    await AssignUsersMutation({ userIds: [data.id], companyId: company.id });
  return data;
};

type AddUserType = yup.InferType<typeof AddUserSchema>;

type Props = {
  open: boolean;
  company?: CompanyCommon;
  closeFn: () => void;
};
const AddUser = ({ open, company, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<AddUserType>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      isVerified: false,
      isApproved: false,
      shouldSendWelcomeEmailIfVerifiedAndApproved: false,
    },
    resolver: yupResolver(AddUserSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: AddUserMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: USERS_KEY,
        exact: true,
      });
      if (company && 'id' in company)
        await queryClient.refetchQueries({
          queryKey: COMPANY_KEY(company.id),
          exact: true,
        });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: AddUserType) => {
    mutate({ user: values, company });
  };

  const headerComp = () => (
    <ModalHeader
      title="Add User"
      icon="add_circle"
      subtitle="Add user by providing user details"
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Add
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={1.5}>
          <Typography variant="subtitle2">Email</Typography>
          <TextField
            control={control}
            name="email"
            placeholder="user@grey-wing.com"
            size="small"
            autoFocus
          />
          <Typography variant="subtitle2">Password</Typography>
          <PasswordField
            control={control}
            name="password"
            placeholder="Enter a password"
            size="small"
          />
          <Typography variant="subtitle2">Confirm Password</Typography>
          <PasswordField
            control={control}
            name="confirmPassword"
            placeholder="Confirm password"
            size="small"
          />
        </Stack>
        <Stack spacing={2} mt={3}>
          <Switch control={control} name="isVerified" label="Is Verified" />
          <Switch control={control} name="isApproved" label="Is Approved" />
          <Switch
            control={control}
            name="shouldSendWelcomeEmailIfVerifiedAndApproved"
            label="Send Welcome Email"
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default AddUser;
