import React, { memo } from 'react';
import { useController, FieldValues, FieldPathByValue } from 'react-hook-form';
import { TextFieldProps, TextField as MuiTextField } from '@mui/material';

function TextField<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({ control, name, ...props }: TextFieldProps & { name: TPath; control: any }) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name,
    control,
  });
  return (
    <MuiTextField
      {...props}
      {...field}
      inputRef={field.ref}
      value={field.value}
      error={invalid}
      helperText={error?.message}
    />
  );
}

export default memo(TextField);
