import React, { useEffect, useState } from 'react';
import { FormHelperText, Stack, SxProps, Theme, styled } from '@mui/material';
import { useController } from 'react-hook-form';
import { JsonEditor, JsonEditorProps } from 'components/JsonEditor';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/ext/language_tools';
import { codeBg } from 'lib/colors';

const Wrapper = styled(Stack)`
  .jsoneditor-poweredBy {
    display: none;
  }
  .jsoneditor {
    border: 0px;
  }
  .jsoneditor-menu {
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
    background-color: ${codeBg};
    button {
      cursor: pointer;
    }
  }
  .jsoneditor-statusbar {
    border-radius: 0px 0px 5px 5px;
  }
  *::-webkit-scrollbar-thumb {
    background: #bbbfc7;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const aceThemes = require.context('brace/theme/', false, /\.\/(.*\.js)$/);
const THEMES = [
  'ambiance',
  'chaos',
  'chrome',
  'clouds_midnight',
  'clouds',
  'cobalt',
  'crimson_editor',
  'dawn',
  'dracula',
  'dreamweaver',
  'eclipse',
  'github',
  'gob',
  'gruvbox',
  'idle_fingers',
  'iplastic',
  'katzenmilch',
  'kr_theme',
  'kuroir',
  'merbivore_soft',
  'merbivore',
  'mono_industrial',
  'monokai',
  'pastel_on_dark',
  'solarized_dark',
  'solarized_light',
  'sqlserver',
  'terminal',
  'textmate',
  'tomorrow_night_blue',
  'tomorrow_night_bright',
  'tomorrow_night_eighties',
  'tomorrow_night',
  'tomorrow',
  'twilight',
  'vibrant_ink',
  'xcode',
] as const;

const JsonEditorField = ({
  control,
  name,
  sx,
  theme,
  ...props
}: Omit<JsonEditorProps, 'value' | 'name' | 'theme'> & {
  name: string;
  control: any;
  theme?: (typeof THEMES)[number];
  sx?: SxProps<Theme>;
}) => {
  const { field } = useController({
    name,
    control,
  });

  const aceTheme = theme && THEMES.includes(theme) ? theme : 'merbivore_soft';
  aceThemes(`./${aceTheme}.js`);

  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState<string>('{}');
  const [key, setKey] = useState<string>('');

  // sync current value and remount on form reset
  useEffect(() => {
    if (field.value !== value && error === null) {
      setValue(field.value ?? '{}');
      setKey(crypto.randomUUID());
    }
  }, [error, field, value]);

  return (
    <Wrapper sx={sx}>
      <JsonEditor
        {...props}
        name={name}
        key={key}
        innerRef={field.ref}
        value={JSON.parse(value)}
        // only runs if value is valid
        onChange={(value: any) => {
          const v = JSON.stringify(value);
          field.onChange(v);
          setValue(v);
          setError(null);
        }}
        // runs if value is invalid
        onError={(value: string, err: Error) => {
          field.onChange(value);
          setError(err.message);
        }}
        mode={props.mode ?? 'code'}
        ace={ace}
        htmlElementProps={{
          style: { height: '20rem', width: '100%', ...props.htmlElementProps },
        }}
        theme={`ace/theme/${aceTheme}`}
      />
      {error ? (
        <FormHelperText sx={{ marginTop: '1rem' }} error>
          {error}
        </FormHelperText>
      ) : null}
    </Wrapper>
  );
};

export default JsonEditorField;
