import * as yup from 'yup';

export const UpdateCompanySchema = yup.object().shape({
  id: yup.number().positive().required(),
  displayName: yup.string().required(),
  emailDomains: yup.array().of(yup.string().email()).required(),
  credentials: yup.object().required(),
  parameters: yup
    .object()
    .shape({
      preferredCurrency: yup.string().optional(),
    })
    .required(),
});

export const AddCompanySchema = yup.object().shape({
  displayName: yup.string().required(),
  domain: yup.string().required(),
  emailDomains: yup.array().of(yup.string().email()).required(),
  credentials: yup.object().required(),
  parameters: yup
    .object()
    .shape({
      preferredCurrency: yup.string().optional(),
    })
    .required(),
  includeVesselData: yup.boolean().required(),
});
