import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../types';
import { GridScrollParams } from '@mui/x-data-grid-pro';

type ScrollStateProps = {
  [key: string]: GridScrollParams;
};

export const initialScrollState: ScrollStateProps = {};

const scrollStateSlice = createSlice({
  name: 'scrollState',
  initialState: initialScrollState,
  reducers: {
    setScrollState: (state, action: PayloadAction<ScrollStateProps>) => {
      return { ...state, ...action.payload };
    },
  },
});

/* ----- selectors -----*/

export const selectScrollState = ({ scrollState }: RootState) => scrollState;

/* ----- actions -----*/

export const { setScrollState } = scrollStateSlice.actions;

/* ----- reducer -----*/

export default scrollStateSlice.reducer;
