import React, { memo } from 'react';
import {
  SelectProps,
  Select as MuiSelect,
  FormHelperText,
  MenuItem,
  MenuItemProps,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useController, FieldValues, FieldPathByValue } from 'react-hook-form';

type Option = { value: string | number; label: string };

function Select<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  options,
  variant,
  menuItemProps,
  control,
  sx,
  ...props
}: SelectProps & {
  name: TPath;
  control: any;
  options: Option[];
  menuItemProps?: MenuItemProps;
}) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name: props.name,
    control,
  });

  return (
    <FormControl variant={variant} sx={sx}>
      {props.label && (
        <InputLabel id={`label-${props.id}`}>{props.label}</InputLabel>
      )}
      <MuiSelect
        labelId={props.label ? `label-${props.id}` : ''}
        {...props}
        {...field}
        ref={field.ref}
        value={field.value}
        MenuProps={{ sx: { maxHeight: 250 } }}
        error={invalid}
      >
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value} {...menuItemProps}>
            {o.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {invalid ? <FormHelperText error>{error?.message}</FormHelperText> : null}
    </FormControl>
  );
}

export default memo(Select);
