import queryClient from 'query';
import { portAgentsQuery } from 'query/queries';

export const PortAgentsLoader = async () => {
  const query = portAgentsQuery;
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
