import React from 'react';
import * as yup from 'yup';
import Modal from 'components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { authedUpdate, handleAPIError } from 'utils/api';
import ModalHeader from 'components/ModalHeader';
import { TextField } from 'components/Forms';
import { useParams, useRevalidator } from 'react-router-dom';
import { COMPANY_PREFERRED_PORTS_KEY } from 'query/queries';
import {
  PreferredPort,
  PutPreferredPortsBody,
} from '@greywing-maritime/frontend-library/dist/types/omegaPreferredPorts';

type Props = {
  ports: PreferredPort[];
  open: boolean;
  closeFn: () => void;
};

const AddPreferredPortMutation = async (values: PutPreferredPortsBody) => {
  const endpoint = `/api/v2/omega/preferred-ports`;
  const response = await authedUpdate(endpoint, values);
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};

const AddPreferredPort = ({ ports, open, closeFn }: Props) => {
  const params = useParams();
  const id = parseInt(params.id!);
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const locodes = ports.map((port) => port.locode);

  const { handleSubmit, control } = useForm<{ locode: string }>({
    defaultValues: {
      locode: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        locode: yup
          .string()
          .required()
          .test(
            'is-five-letters',
            'LOCODE must be exactly of 5 letters',
            (value) => value.length === 5
          )
          .test(
            'is-present',
            'LOCODE is already present as a preferred port',
            (value) => !locodes.includes(value)
          ),
      })
    ),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: AddPreferredPortMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: COMPANY_PREFERRED_PORTS_KEY(id),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async ({ locode }: { locode: string }) => {
    mutate({ locodes: [...locodes, locode], companyId: id });
  };

  const headerComp = () => (
    <ModalHeader
      title="Add Preferred Port"
      icon="add_circle"
      subtitle="Add new preferred port by providing port LOCODE"
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Add
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">LOCODE</Typography>
          <TextField
            control={control}
            name="locode"
            size="small"
            placeholder="SGSIN"
            autoFocus
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default AddPreferredPort;
