import React, { memo } from 'react';
import { useController, FieldValues, FieldPathByValue } from 'react-hook-form';
import { TextFieldProps, TextField, Autocomplete, Chip } from '@mui/material';

function MultipleTagField<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({ control, name, ...props }: TextFieldProps & { name: TPath; control: any }) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name,
    control,
  });

  return (
    <Autocomplete
      value={field.value}
      size={props?.size}
      options={[]}
      multiple
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip size={props?.size} label={option} {...getTagProps({ index })} />
        ))
      }
      onChange={(event, values) => {
        field.onChange(values);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={field.ref}
          size={props?.size}
          placeholder={field.value?.length ? '' : props?.placeholder}
          required={field.value?.length ? false : true}
          error={invalid}
          helperText={Array.isArray(error) ? error[0]?.message : error?.message}
        />
      )}
    />
  );
}

export default memo(MultipleTagField);
