import React from 'react';
import { Button, styled } from '@mui/material';
import { useAppDispatch } from 'hooks';
import { toggleSidebar } from 'redux/actions';
import { useSelector } from 'react-redux';
import { selectSidebar } from 'redux/selectors';
import MaterialIcon from 'components/MaterialIcon';

const ToggleButton = styled(Button)`
  min-width: unset;
  height: 32px;
  margin: 0 8px;
`;

const ToggleSidebar = () => {
  const dispatch = useAppDispatch();
  const { expanded } = useSelector(selectSidebar);
  return (
    <ToggleButton onClick={() => dispatch(toggleSidebar())}>
      <MaterialIcon
        name={
          expanded
            ? 'keyboard_double_arrow_left'
            : 'keyboard_double_arrow_right'
        }
        color="white"
      />
    </ToggleButton>
  );
};

export default ToggleSidebar;
