import { RouteObject } from 'react-router';
import FeaturesPage from './Features';
import { FeaturesLoader } from './loader';

const routes: RouteObject[] = [
  {
    path: '/features',
    element: <FeaturesPage />,
    loader: FeaturesLoader,
  },
];

export default routes;
