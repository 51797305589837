import * as yup from 'yup';

export enum DATA_PUSH_ADAPTER_IDS {
  CTELEPORT = 'C-TELEPORT',
}

export const AddDataPushJobSchema = yup.object().shape({
  companyId: yup.number().positive(),
  adapterId: yup.string().required(),
  frequencyInMins: yup.number().positive().required(),
  config: yup
    .string()
    .test('is-valid-object', 'Invalid JSON string', (value) => {
      try {
        if (value) {
          if (value === '') return true;
          const parsedObject = JSON.parse(value);
          return typeof parsedObject === 'object' && parsedObject !== null;
        }
      } catch (error) {
        return false; // JSON parsing failed
      }
    })
    .nullable()
    .required(),
});

export const EditDataPushJobSchema = yup.object().shape({
  id: yup.number().positive().required(),
  companyId: yup.number().positive().optional(),
  adapterId: yup.string().optional(),
  enabled: yup.boolean().optional(),
  frequencyInMins: yup.number().positive().optional(),
  config: yup
    .string()
    .test('is-valid-object', 'Invalid JSON string', (value) => {
      try {
        if (value) {
          if (value === '') return true;
          const parsedObject = JSON.parse(value);
          return typeof parsedObject === 'object' && parsedObject !== null;
        }
      } catch (error) {
        return false; // JSON parsing failed
      }
    })
    .nullable()
    .optional(),
});
