import { RouteObject } from 'react-router';
import PortRestrictionsPage from './PortRestrictions';
import { portRestrictionsLoader } from './loader';

const routes: RouteObject[] = [
  {
    path: '/port-restrictions',
    element: <PortRestrictionsPage />,
    loader: portRestrictionsLoader,
  },
];

export default routes;
