import { Typography, styled, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks';
import { selectTheme } from 'redux/selectors';
import { setTheme } from 'redux/actions';
import { white, black, gray90 } from 'lib/colors';
import { ThemeMode } from 'utils/types';
import DarkPreview from 'assets/icons/dark-preview.svg';
import LightPreview from 'assets/icons/light-preview.svg';

const Card = styled(Stack)<{ type: string }>`
  user-select: none;
  width: 14rem;
  height: 9.5rem;
  margin-right: 1rem;
  cursor: pointer;
  border: ${({ type }) =>
    type === 'active' ? `2px solid #4ECDC4` : `1px solid ${gray90}`};
  border-radius: 8px;
  overflow: hidden;
  > * {
    transition: transform 0.2s;
    transform: ${({ type }) =>
      type === 'active' ? `scale(0.96)` : `scale(1)`};
  }
`;

const Wrapper = styled(Stack)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const ThemePreviewWrapper = styled(Stack)`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
`;

const StyledText = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2rem;
  background-color: ${black};
  color: ${white};
  border-top: 1px solid ${gray90};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.6rem;
  z-index: 1;
`;

const Appearance = () => {
  const dispatch = useAppDispatch();
  const { theme: selectedTheme } = useSelector(selectTheme);

  const handleThemeChange = (theme: ThemeMode) => {
    dispatch(setTheme(theme));
  };

  return (
    <Stack>
      <Typography variant="button" sx={{ fontWeight: 'bold' }}>
        Theme Mode
      </Typography>
      <Typography variant="body2" mt={1}>
        Choose how Greywing looks to you. Click on one of the following themes
        to switch between day and night themes.
      </Typography>
      <ThemePreviewWrapper>
        <Card
          type={selectedTheme === 'light' ? 'active' : 'inactive'}
          onClick={() => handleThemeChange('light')}
        >
          <Wrapper>
            <img src={LightPreview} alt="light-mode" />
            <StyledText>Light Mode</StyledText>
          </Wrapper>
        </Card>
        <Card
          type={selectedTheme === 'dark' ? 'active' : 'inactive'}
          onClick={() => handleThemeChange('dark')}
        >
          <Wrapper>
            <img src={DarkPreview} alt="dark-mode" />
            <StyledText>Dark Mode</StyledText>
          </Wrapper>
        </Card>
      </ThemePreviewWrapper>
    </Stack>
  );
};

export default Appearance;
