import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Overlay } from 'styles/common';
import LottieAnimation from '../LottieAnimation';

type Props = {
  text: string | Error | Error[];
};

const ErrorComp = ({ text }: Props) => {
  return (
    <Overlay>
      <Stack>
        <LottieAnimation name="error-1zdlOo8cek" width="10rem" loop={false} />
        <Typography variant="subtitle1" color="error">
          {Array.isArray(text) ? text[0]?.message : null}
          {text instanceof Error ? text?.message : null}
          {typeof text === 'string' ? text : null}
        </Typography>
      </Stack>
    </Overlay>
  );
};

export default ErrorComp;
