import { Button } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { companyInboxQuery, inboxQuery, userInboxQuery } from 'query/queries';
import LoadingIcon from 'components/LoadingIcon';
import { blue10, white } from 'lib/colors';
import { mailType } from '../types';
import MaterialIcon from 'components/MaterialIcon';

const ConvoNavigate = ({ type }: { type: (typeof mailType)[number] }) => {
  const navigate = useNavigate();

  const params = useParams();
  const id = parseInt(params.id!);
  const convoId = parseInt(params.convoId!);

  const getQuery = useMemo(() => {
    if (type === 'all') {
      return inboxQuery;
    } else if (type === 'company') {
      return companyInboxQuery(id);
    } else if (type === 'users') {
      return userInboxQuery(id);
    }
  }, []); //eslint-disable-line

  const { isLoading, data } = useQuery(getQuery!);

  if (isLoading) return <LoadingIcon color={blue10} size={25} />;

  if (!data) return null;

  const { data: convoList } = data;
  let previous: number | null = null;
  let next: number | null = null;
  const index = convoList.findIndex((obj) => obj.id === convoId);
  if (index > 0) {
    previous = convoList[index - 1].id;
  }
  if (index !== -1 && index < convoList.length - 1) {
    next = convoList[index + 1].id;
  }

  const handleNavigation = (convoId: number) => {
    if (type === 'all') {
      navigate(`/mail/inbox/${convoId}`);
    } else if (type === 'company') {
      navigate(`/company/${id}/mail/inbox/${convoId}`);
    } else if (type === 'users') {
      navigate(`/users/${id}/mail/inbox/${convoId}`);
    }
  };

  return (
    <>
      {previous ? (
        <Button variant="contained" onClick={() => handleNavigation(previous!)}>
          <MaterialIcon
            name="keyboard_double_arrow_left"
            color={white}
            style={{ marginRight: '0.5rem' }}
          />
          Previous
        </Button>
      ) : null}
      {next ? (
        <Button variant="contained" onClick={() => handleNavigation(next!)}>
          Next
          <MaterialIcon
            name="keyboard_double_arrow_right"
            color={white}
            style={{ marginLeft: '0.5rem' }}
          />
        </Button>
      ) : null}
    </>
  );
};

export default ConvoNavigate;
