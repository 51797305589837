export const ChaserEmailTemplate = `
<p class="editor-paragraph" dir="ltr"><span>Good day!</span></p>
<p class="editor-paragraph" dir="ltr"><span>Please can you respond to the below email regarding our subject vessel. </span></p>
<p class="editor-paragraph" dir="ltr"><span>Awaiting your response, many thanks.</span></p>
<p class="editor-paragraph" dir="ltr"><br/></p>
`;

export const ClarificationEmailTemplate = (questions?: string | null) => `
<p class="editor-paragraph" dir="ltr"><span>Good day!</span></p>
<p class="editor-paragraph" dir="ltr"><span>${questions || ''}</span></p>
<p class="editor-paragraph" dir="ltr"><span>Awaiting your response, many thanks.</span></p>
<p class="editor-paragraph" dir="ltr"><br/></p>
`;
