import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { expandSidebar, clearSidebar } from 'redux/actions';
import { SidebarItemType } from 'components/Sidebar/types';

const sidebarItems = (id: number): SidebarItemType[] => [
  {
    name: 'Permissions',
    icon: 'format_list_bulleted',
    link: `/users/${id}/permissions`,
  },
  { name: 'Vessels', icon: 'directions_boat', link: `/users/${id}/vessels` },
  {
    name: 'Proteus Email',
    icon: 'mail',
    link: `/users/${id}/mail/inbox`,
  },
  {
    name: 'Authentication',
    icon: 'shield',
    link: `/users/${id}/authentication`,
  },
];

const Sidebar = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const id = parseInt(params.id!);

  useEffect(() => {
    dispatch(expandSidebar({ title: 'User', items: sidebarItems(id) }));
    return () => {
      dispatch(clearSidebar());
    };
  }, [dispatch, id]);

  return <>{children}</>;
};

export default Sidebar;
