import React, { useState } from 'react';
import { ParsedResultsModalType } from '../types';
import { Button, Card, Stack, Typography, styled } from '@mui/material';
import { BREAK_POINT_M } from 'lib/breakpoints';
import { blue40 } from 'lib/colors';
import EditParsedResults from './Modals/EditParsedResults';
import { authedPost, handleAPIError } from 'utils/api';
import VesselCard from './VesselCard';
import CostsTables from './Table/CostsTables';
import {
  AssistedCrewChangeBaseType,
  AssistedCrewChangeEmailParsedResults,
} from '@greywing-maritime/frontend-library/dist/types/acc';
import MaterialIcon, { MaterialIconType } from 'components/MaterialIcon';

const StyledCard = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 25%;
  row-gap: 0.5rem;
  padding: 1rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  .MuiTypography-body2 {
    font-size: 12px;
  }
  ul {
    margin: 0;
  }
`;

const StyledStack = styled(Stack)`
  flex-direction: row;
  @media only screen and (max-width: ${BREAK_POINT_M}) {
    flex-direction: column;
  }
`;

type TitleProps = {
  text: string;
  icon: MaterialIconType;
};
const Title = ({ text, icon }: TitleProps) => (
  <Stack direction="row">
    <MaterialIcon name={icon} size={20} style={{ marginRight: '0.8rem' }} />
    <Typography variant="body1" fontWeight="bold">
      {text}
    </Typography>
  </Stack>
);

export const UpdateParsedResultsMutation = async (
  values: AssistedCrewChangeEmailParsedResults & { aceIdforUpdate: number }
) => {
  const { aceIdforUpdate, ...parsedResults } = values;
  const endpoint = `/api/v2/omega/assisted-crew-changes/emails/${aceIdforUpdate}/add-parsed-results/`;
  const response = await authedPost(endpoint, parsedResults);
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

type Props = {
  parsedResults: AssistedCrewChangeEmailParsedResults;
  aceIdforUpdate?: number;
  acc?: AssistedCrewChangeBaseType;
};
const ParsedResults = ({ parsedResults, aceIdforUpdate, acc }: Props) => {
  const priceConditions = parsedResults.priceConditions;
  const costCalculation = parsedResults.appliedCosts?.costCalculation;
  const parsedRestrictions = parsedResults.parsedRestrictions;
  const [modal, setModal] = useState<ParsedResultsModalType | null>(null);

  const handleClose = () => setModal(null);

  return (
    <>
      <Stack gap={4} direction="row" flexWrap="wrap">
        {acc ? (
          <Stack width="100%" maxWidth={400}>
            <VesselCard acc={acc} />
          </Stack>
        ) : null}
        <CostsTables
          aceIdforUpdate={aceIdforUpdate}
          parsedResults={parsedResults}
        />
      </Stack>
      <StyledStack gap={4}>
        <StyledCard>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Title text="Price Conditions" icon="description" />
            {aceIdforUpdate ? (
              <Button
                variant="outlined"
                sx={{ height: '1.5rem' }}
                onClick={() => setModal('price-conditions')}
              >
                {priceConditions?.length ? 'Edit' : 'Add'}
              </Button>
            ) : null}
          </Stack>
          <Typography color={blue40} variant="caption">
            These are the rules that will be used to calculate the cost.
          </Typography>
          {priceConditions?.length ? (
            <Typography
              variant="body2"
              style={{ whiteSpace: 'pre-line' }}
              mt={1}
            >
              {priceConditions}
            </Typography>
          ) : null}
        </StyledCard>
        <StyledCard>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Title text="Cost Calculation" icon="attach_money" />
            {aceIdforUpdate ? (
              <Button
                variant="outlined"
                sx={{ height: '1.5rem' }}
                onClick={() => setModal('cost-calculation')}
              >
                {costCalculation?.length ? 'Edit' : 'Add'}
              </Button>
            ) : null}
          </Stack>
          <Typography color={blue40} variant="caption">
            This is the explanation of how the cost is calculated based on the
            Pricing Conditions.
          </Typography>
          {costCalculation?.length ? (
            <Typography
              variant="body2"
              style={{ whiteSpace: 'pre-line' }}
              mt={1}
            >
              {costCalculation}
            </Typography>
          ) : null}
        </StyledCard>
      </StyledStack>
      <StyledCard>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Title text="Restrictions" icon="anchor" />
          {aceIdforUpdate ? (
            <Button
              variant="outlined"
              sx={{ height: '1.5rem' }}
              onClick={() => setModal('parsed-restrictions')}
            >
              {parsedRestrictions?.length ? 'Edit' : 'Add'}
            </Button>
          ) : null}
        </Stack>
        <Typography color={blue40} variant="caption">
          These are the restrictions applicable to the Port.
        </Typography>
        <ul>
          {parsedRestrictions?.map((restriction, key) => (
            <li key={key}>
              <Typography variant="body2">{restriction}</Typography>
            </li>
          ))}
        </ul>
      </StyledCard>
      {modal === 'price-conditions' && aceIdforUpdate ? (
        <EditParsedResults
          type={modal}
          icon="description"
          aceIdforUpdate={aceIdforUpdate}
          parsedResults={parsedResults}
          closeFn={handleClose}
        />
      ) : null}
      {modal === 'cost-calculation' && aceIdforUpdate ? (
        <EditParsedResults
          type={modal}
          aceIdforUpdate={aceIdforUpdate}
          parsedResults={parsedResults}
          icon="attach_money"
          closeFn={handleClose}
        />
      ) : null}
      {modal === 'parsed-restrictions' && aceIdforUpdate ? (
        <EditParsedResults
          type={modal}
          icon="anchor"
          aceIdforUpdate={aceIdforUpdate}
          parsedResults={parsedResults}
          closeFn={handleClose}
        />
      ) : null}
    </>
  );
};

export default ParsedResults;
