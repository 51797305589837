import React from 'react';
import { styled, Stack } from '@mui/material';

import LottieAnimation from './LottieAnimation';

type Props = {
  color?: string;
  size?: string | number;
};

const Wrapper = styled(Stack)<{ color: string }>`
  path {
    stroke: ${({ color }) => color};
  }
`;

const LoadingIcon = ({ color = 'initial', size = '3rem' }: Props) => {
  return (
    <Wrapper color={color}>
      <LottieAnimation
        name="spinner-8cNJPEEWse"
        height={size}
        width={size}
        loop={true}
      />
    </Wrapper>
  );
};

export default LoadingIcon;
