import React from 'react';
import { Stack, Tooltip, IconButton, Button } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useLoaderData } from 'react-router-dom';
import { red, white } from 'lib/colors';
import { useState } from 'react';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import {
  GetPreferredPortsResponse,
  PreferredPort,
} from '@greywing-maritime/frontend-library/dist/types/omegaPreferredPorts';
import moment from 'moment';
import AddPreferredPort from '../components/AddPreferredPort';
import DeletePreferredPort from '../components/DeletePreferredPort';
import MaterialIcon from 'components/MaterialIcon';

const RenderActions = ({ deleteFn }: { deleteFn: () => void }) => {
  return (
    <Tooltip title="Delete Preferred Port" placement="top" arrow>
      <IconButton onClick={deleteFn}>
        <MaterialIcon name="delete" color={red} />
      </IconButton>
    </Tooltip>
  );
};

const columns: GridColDef[] = [
  {
    field: 'locode',
    headerName: 'Locode',
    width: 150,
  },
  {
    field: 'displayName',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 200,
    valueGetter: (params) => moment(params.row.createdAt).format('MMM D, YYYY'),
  },
];

function PreferredPorts() {
  const { preferredPorts: ports } =
    useLoaderData() as GetPreferredPortsResponse;
  const [port, setPort] = useState<PreferredPort | null>(null);
  const [showAddPortModal, setShowAddPortModal] = useState<boolean>(false);
  const [showDeletePortModal, setShowDeletePortModal] =
    useState<boolean>(false);

  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    setPort(row);
  };

  const handleAddModal = () => {
    setShowAddPortModal(!showAddPortModal);
  };

  const handleDeleteModal = () => {
    setShowDeletePortModal(!showDeletePortModal);
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: () => <RenderActions deleteFn={handleDeleteModal} />,
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Preferred Ports"
        subtitle="Update and manage preferred ports"
        chipText={ports?.length + ' ports'}
        icon="anchor"
        actions={
          <Button
            variant="contained"
            startIcon={<MaterialIcon name="add" color={white} />}
            onClick={handleAddModal}
          >
            Add
          </Button>
        }
      />

      <Table
        height="100%"
        rows={ports}
        getRowId={(row) => row.locode}
        columns={[...columns, actions]}
        onRowClick={handleRowClick}
        noRowsText="No preferred ports found"
        nonClickable
      />

      {showAddPortModal ? (
        <AddPreferredPort
          ports={ports}
          open={showAddPortModal}
          closeFn={handleAddModal}
        />
      ) : null}

      {port && showDeletePortModal ? (
        <DeletePreferredPort
          ports={ports}
          delPort={port}
          open={showDeletePortModal}
          closeFn={handleDeleteModal}
        />
      ) : null}
    </Stack>
  );
}

export default PreferredPorts;
