import { AssistedCrewChangeBaseType } from '@greywing-maritime/frontend-library/dist/types/acc';
import { Card, Stack, Typography, styled } from '@mui/material';
import { blue, gray10, white } from 'lib/colors';
import moment from 'moment';

const StyledVesselCard = styled(Card)`
  height: 100%;
  .MuiTypography-root {
    font-size: 12.5px;
  }
`;

const VesselCard = ({ acc }: { acc: AssistedCrewChangeBaseType }) => (
  <StyledVesselCard>
    <Stack padding={1} sx={{ backgroundColor: blue, color: white }}>
      <Typography variant="button" fontWeight={500}>
        {acc.flotillaVessel?.name || acc.vesselName}
      </Typography>
    </Stack>
    <Stack direction="row" padding={1} justifyContent="space-between">
      <Typography variant="button" fontWeight={500}>
        Date of crew change
      </Typography>
      <Typography variant="button" fontWeight={500} textTransform="none">
        {acc.eventDate
          ? moment(acc.eventDate).format('h:mm a [on] DD MMM YYYY')
          : '-'}
      </Typography>
    </Stack>
    <Stack padding={1} sx={{ backgroundColor: gray10 }} height="100%">
      {acc.crewDetails.onsigners.length || acc.crewDetails.offsigners.length ? (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="button" fontWeight={700}>
            Type
          </Typography>
          <Typography variant="button" fontWeight={700}>
            Nationality
          </Typography>
        </Stack>
      ) : (
        <Typography variant="body2" fontWeight={500}>
          No crew member present.
        </Typography>
      )}
      {[
        ...acc.crewDetails.onsigners.map((c) => ({
          ...c,
          type: `Onsigner${c.numCrew > 1 ? 's' : ''}`,
        })),
        ...acc.crewDetails.offsigners.map((c) => ({
          ...c,
          type: `Offsigner${c.numCrew > 1 ? 's' : ''}`,
        })),
      ].map((crew, key) => (
        <Stack direction="row" justifyContent="space-between" key={key}>
          <Typography variant="button" fontWeight={500} textTransform="none">
            {crew.numCrew} {crew.type}
          </Typography>
          <Typography
            variant="button"
            fontWeight={500}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <img
              loading="lazy"
              src={
                'https://flagsapi.com/' +
                crew.nationalityIsoCode +
                '/flat/16.png'
              }
              alt={crew.nationalityIsoCode}
            />
            {crew.nationalityIsoCode}
          </Typography>
        </Stack>
      ))}
    </Stack>
  </StyledVesselCard>
);

export default VesselCard;
