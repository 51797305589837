import { RouteObject } from 'react-router';
import CMSJobsPage from './CmsJobs';
import { CmsLoader } from './loader';

const routes: RouteObject[] = [
  {
    path: '/cms-jobs',
    element: <CMSJobsPage />,
    loader: CmsLoader,
  },
];

export default routes;
