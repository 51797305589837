import React, { memo } from 'react';
import { useController, FieldValues, FieldPathByValue } from 'react-hook-form';
import {
  SwitchProps,
  FormControlLabel,
  FormHelperText,
  Switch as MuiSwitch,
  styled,
} from '@mui/material';
import { blue50 } from 'lib/colors';

const SWITCH_SIZES = ['medium', 'large', 'xlarge'] as const;
type SwitchSizes = (typeof SWITCH_SIZES)[number];

type CustomSwitchProps = SwitchProps & {
  customSize?: SwitchSizes;
};

const SWITCH_BODY_SIZE: { [key in SwitchSizes]: number } = {
  medium: 40,
  large: 46,
  xlarge: 52,
};

const SWITCH_BODY_HEIGHT_SIZE: { [key in SwitchSizes]: number } = {
  medium: 20,
  large: 26,
  xlarge: 32,
};

const SWITCH_THUMB_SIZE: { [key in SwitchSizes]: number } = {
  medium: 16,
  large: 22,
  xlarge: 28,
};
const SwitchV2 = styled(({ customSize, ...props }: CustomSwitchProps) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme, customSize = 'medium' }) => ({
  width: SWITCH_BODY_SIZE[customSize],
  height: SWITCH_BODY_HEIGHT_SIZE[customSize],
  padding: 0,
  marginRight: 10,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: blue50,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: blue50,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: SWITCH_THUMB_SIZE[customSize],
    height: SWITCH_THUMB_SIZE[customSize],
  },
  '& .MuiSwitch-track': {
    borderRadius: SWITCH_BODY_HEIGHT_SIZE[customSize] / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function Switch<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  control,
  name,
  label,
  ...props
}: SwitchProps & { name: TPath; label: string; control: any }) {
  const {
    field: { ref, ...field },
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name,
    control,
  });
  return (
    <>
      <FormControlLabel
        sx={{ margin: '0px' }}
        control={
          <SwitchV2
            {...props}
            {...field}
            checked={field.value}
            value={field.value}
          />
        }
        label={label}
      />
      {invalid ? <FormHelperText error>{error?.message}</FormHelperText> : null}
    </>
  );
}

export default memo(Switch);
