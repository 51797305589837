import React from 'react';
import Modal from 'components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { authedPost, handleAPIError } from 'utils/api';
import ModalHeader from 'components/ModalHeader';
import { TextField } from 'components/Forms';
import { AssignPICSchema } from '../validations';
import { useParams, useRevalidator } from 'react-router-dom';
import { COMPANY_VESSELS_KEY, USER_VESSELS_KEY } from 'query/queries';

type AssignPICType = {
  newPicId?: number;
  imo: number;
};

type Props = {
  type: 'company' | 'user';
  imo: number;
  open: boolean;
  closeFn: () => void;
};

const AssignPICMutation = async (values: AssignPICType) => {
  const { newPicId, imo } = values;
  const endpoint = `/api/v2/omega/flotilla-vessels/assign-pic`;
  const response = await authedPost(endpoint, {
    newPicId,
    flotVesIds: [imo],
  });
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};

const AssignPIC = ({ type, imo, open, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const id = parseInt(params.id!);
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<AssignPICType>({
    defaultValues: {
      imo,
    },
    resolver: yupResolver(AssignPICSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: AssignPICMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey:
          type === 'company' ? COMPANY_VESSELS_KEY(id) : USER_VESSELS_KEY(id),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });
  const onSubmit = async (values: AssignPICType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Assign PIC"
      icon="person"
      subtitle={`Assign new PIC to vessel with IMO (${imo})`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Assign
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">New PIC ID</Typography>
          <TextField
            type="number"
            control={control}
            name="newPicId"
            size="small"
            placeholder="01"
            autoFocus
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default AssignPIC;
