import { RouteObject } from 'react-router';
import PortAgentsPage from './PortAgents';
import { PortAgentsLoader } from './loader';

const routes: RouteObject[] = [
  {
    path: '/port-agents',
    element: <PortAgentsPage />,
    loader: PortAgentsLoader,
  },
];

export default routes;
