import { RouteObject } from 'react-router';
import PortAgencyCostsPage from './PortAgencyCosts';
import { PortAgencyCostsLoader } from './loader';

const routes: RouteObject[] = [
  {
    path: '/port-agency-costs',
    element: <PortAgencyCostsPage />,
    loader: PortAgencyCostsLoader,
  },
];

export default routes;
