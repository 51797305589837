import { Card, Stack, Typography, styled } from '@mui/material';
import { useMemo } from 'react';
import moment from 'moment';
import { blue, green, red } from 'lib/colors';
import { AssistedCrewChangeConvoSummaryResp } from '@greywing-maritime/frontend-library/dist/types/acc';
import MaterialIcon from 'components/MaterialIcon';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  .MuiTypography-body2 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;

    &#label {
      font-size: 9px;
    }
  }
  .MuiTypography-h4 {
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 700;
    color: ${blue};
  }
`;

function Stats({ data }: { data: AssistedCrewChangeConvoSummaryResp[] }) {
  const emailsSinceToday = useMemo(() => {
    const today = moment().startOf('day');
    return data.reduce((total, convo) => {
      if (moment(convo.createdAt).isAfter(today)) {
        return total + 1;
      }
      return total;
    }, 0);
  }, [data]);
  const requireChaser = useMemo(
    () =>
      data.reduce((total, convo) => {
        if (convo.numEmailsReceived === 0) return total + 1;
        return total;
      }, 0),
    [data]
  );
  const requireReview = useMemo(
    () =>
      data.reduce((total, convo) => {
        if (convo.latestOnHold) return total + 1;
        return total;
      }, 0),
    [data]
  );
  const totalCosts = useMemo(() => {
    const today = moment().startOf('day');
    return data.reduce(
      (combined, convo) => {
        if (
          convo.latestParsedResultsAmongSuccessfulEmails?.appliedCosts
            ?.appliedAgencyCosts
        ) {
          combined.total += 1;
          if (
            moment(
              convo.latestParsedResultsAmongSuccessfulEmails.createdAt
            ).isAfter(today)
          ) {
            combined.today += 1;
          }
        }
        return combined;
      },
      {
        total: 0,
        today: 0,
      }
    );
  }, [data]);

  const totalRestrictions = useMemo(() => {
    const today = moment().startOf('day');
    return data.reduce(
      (combined, convo) => {
        if (
          convo.latestParsedResultsAmongSuccessfulEmails?.parsedRestrictions
            ?.length
        ) {
          combined.total += 1;
          if (
            moment(
              convo.latestParsedResultsAmongSuccessfulEmails.createdAt
            ).isAfter(today)
          ) {
            combined.today += 1;
          }
        }
        return combined;
      },
      {
        total: 0,
        today: 0,
      }
    );
  }, [data]);
  return (
    <Stack gap={2} alignItems="flex-start" mb={4} direction="row">
      <StyledCard sx={{ textAlign: 'center' }}>
        <Typography variant="body2" gutterBottom>
          Total Emails
        </Typography>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="h4">{data.length}</Typography>
          {!!emailsSinceToday && (
            <Stack>
              <Stack direction="row" marginLeft={1}>
                <Typography color={green}>{emailsSinceToday}</Typography>
                <MaterialIcon name="keyboard_double_arrow_up" color={green} />
              </Stack>
              <Typography id="label" variant="body2">
                today
              </Typography>
            </Stack>
          )}
        </Stack>
      </StyledCard>
      <StyledCard sx={{ textAlign: 'center' }}>
        <Typography variant="body2" gutterBottom>
          Require Chasers
        </Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <MaterialIcon name="warning" color={red} />
          <Typography variant="h4">{requireChaser}</Typography>
        </Stack>
      </StyledCard>
      <StyledCard sx={{ textAlign: 'center' }}>
        <Typography variant="body2" gutterBottom>
          Require Review
        </Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <MaterialIcon name="search" color={red} />
          <Typography variant="h4">{requireReview}</Typography>
        </Stack>
      </StyledCard>
      <StyledCard sx={{ textAlign: 'center' }}>
        <Typography variant="body2" gutterBottom>
          Total Costs Extracted
        </Typography>

        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <MaterialIcon name="request_quote" color={green} />
          <Typography variant="h4">{totalCosts.total}</Typography>
          {!!totalCosts.today && (
            <Stack>
              <Stack direction="row" marginLeft={1}>
                <Typography color={green}>{totalCosts.today}</Typography>
                <MaterialIcon name="keyboard_double_arrow_up" color={green} />
              </Stack>
              <Typography id="label" variant="body2">
                today
              </Typography>
            </Stack>
          )}
        </Stack>
      </StyledCard>

      <StyledCard sx={{ textAlign: 'center' }}>
        <Typography variant="body2" gutterBottom>
          Total Restrictions
        </Typography>

        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <MaterialIcon name="description" color={blue} />
          <Typography variant="h4">{totalRestrictions.total}</Typography>
          {!!totalRestrictions.today && (
            <Stack>
              <Stack direction="row" marginLeft={1}>
                <Typography color={green}>{totalRestrictions.today}</Typography>
                <MaterialIcon name="keyboard_double_arrow_up" color={green} />
              </Stack>
              <Typography id="label" variant="body2">
                today
              </Typography>
            </Stack>
          )}
        </Stack>
      </StyledCard>
    </Stack>
  );
}

export default Stats;
