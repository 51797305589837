import * as yup from 'yup';

export const UpdateFeatureSchema = yup.object().shape({
  isPublic: yup.string().oneOf(['Public', 'Private']).required(),
  config: yup
    .string()
    .test('is-valid-object', 'Invalid JSON string', (value) => {
      try {
        if (value) {
          if (value === '') return true;
          const parsedObject = JSON.parse(value);
          return typeof parsedObject === 'object' && parsedObject !== null;
        }
      } catch (error) {
        return false; // JSON parsing failed
      }
    })
    .optional(),
});

export const AddFeatureSchema = yup.object().shape({
  displayName: yup.string().required(),
  isPublic: yup.string().oneOf(['Public', 'Private']).required(),
  config: yup
    .string()
    .test('is-valid-object', 'Invalid JSON string', (value) => {
      try {
        if (value) {
          if (value === '') return true;
          const parsedObject = JSON.parse(value);
          return typeof parsedObject === 'object' && parsedObject !== null;
        }
      } catch (error) {
        return false; // JSON parsing failed
      }
    })
    .optional(),
});
