import { getAuthToken } from '@greywing-maritime/frontend-library/dist/utils/auth';
import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';

export function headers(token: string) {
  return {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

// common util to use for handling invalid response code
// should throw an exception, which will be caught by `catch` block
export const handleAPIError = (
  responseStatus: number,
  result?: any,
  skipStatus?: number[]
) => {
  if (skipStatus?.includes(responseStatus)) return;
  if (responseStatus < 200 || responseStatus >= 300) {
    throw new Error(result?.message || 'Invalid response code.');
  }
};

export function requireAuth() {
  return new Promise((resolve, reject) => {
    const token = getAuthToken();
    if (token.success && token.token) {
      resolve(token.token);
    } else {
      reject(false);
    }
  });
}

export const authedPaginatedGet = async (
  baseUrl: string,
  params?: any,
  pageSize?: number
) => {
  const pages = [];
  let nextPageToken = null;
  let totalCount = 0;
  while (1) {
    const p = new URLSearchParams();
    if (params)
      Object.keys(params).forEach((key) => {
        p.append(key, params[key]);
      });
    if (pageSize) p.append('pageSize', pageSize.toString());
    if (nextPageToken) {
      p.append('pageToken', nextPageToken);
      p.append('nextPageToken', nextPageToken);
    }
    const t = p.toString();
    const url = baseUrl + (t ? `?${t}` : '');
    const response: any = await authedGet(url);
    const data = await response.json();
    handleAPIError(response.status, data);
    if ('nextPageToken' in data) {
      if (nextPageToken === data.nextPageToken) break;
      else {
        nextPageToken = data.nextPageToken;
        totalCount = data.totalCount;
        pages.push(data);
      }
    } else {
      pages.push(data);
      break;
    }
  }
  const data = pages.reduce((result, obj) => {
    const { nextPageToken, totalCount, ...rest } = obj;
    Object.keys(rest).forEach((key) => {
      if (!result[key]) {
        result[key] = rest[key];
      } else {
        // Merge arrays with the same name
        if (Array.isArray(result[key])) {
          result[key] = [...result[key], ...rest[key]];
        }
        // Merge objects with the same name
        else if (typeof result[key] === 'object') {
          result[key] = { ...result[key], ...rest[key] };
        }
      }
    });
    return result;
  }, {});
  return {
    ...data,
    totalCount,
  };
};

export function authedGet(url: string) {
  return authedFetchHelper('GET', url);
}

export function authedPost(url: string, params?: any) {
  return authedFetchHelper('POST', url, params);
}

export function authedUpdate(url: string, params?: any) {
  return authedFetchHelper('PUT', url, params);
}

export function authedPatch(url: string, params?: any) {
  return authedFetchHelper('PATCH', url, params);
}

export function authedDelete(url: string, params?: any) {
  return authedFetchHelper('DELETE', url, params);
}

function authedFetchHelper(method: string, url: string, params?: any) {
  return requireAuth().then(async function (authToken) {
    return await fetch(getAPIUrl() + url, {
      method: method,
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: params ? JSON.stringify(params) : null,
    });
  });
}
