import { Card, styled } from '@mui/material';
import { codeBg, codeText } from 'lib/colors';

export const SimpleCard = styled(Card)`
  min-width: 200px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  row-gap: 0.2rem;
  font-size: 12.8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
`;

export const CodeCard = styled(SimpleCard)`
  background-color: ${codeBg};
  color: ${codeText};
  padding: 0px 1rem;
`;
