import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'components/Modal';
import { TextField } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { authedUpdate, handleAPIError } from 'utils/api';
import { useRevalidator } from 'react-router-dom';
import { COMPANY_PREFERRED_PORTS_KEY } from 'query/queries';
import {
  PreferredPort,
  PutPreferredPortsBody,
} from '@greywing-maritime/frontend-library/dist/types/omegaPreferredPorts';
import MaterialIcon from 'components/MaterialIcon';
import { orange } from 'lib/colors';

type Props = {
  ports: PreferredPort[];
  delPort: PreferredPort;
  open: boolean;
  closeFn: () => void;
};

const DeletePreferredPortMutation = async (values: PutPreferredPortsBody) => {
  const endpoint = `/api/v2/omega/preferred-ports`;
  const response = await authedUpdate(endpoint, values);
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};

const DeletePreferredPort = ({ ports, delPort, open, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<{ locode: string | null }>({
    defaultValues: {
      locode: null,
    },
    resolver: yupResolver(
      yup.object().shape({
        locode: yup
          .string()
          .required()
          .test(
            'confirm-delete',
            "Value doesn't match vessel locode.",
            (value) => {
              return value === delPort.locode;
            }
          ),
      })
    ),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: DeletePreferredPortMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: COMPANY_PREFERRED_PORTS_KEY(delPort.companyId),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    const locodes = ports
      .map((port) => port.locode)
      .filter((locode) => locode !== delPort.locode);
    mutate({ locodes, companyId: delPort.companyId });
  };

  const headerComp = () => (
    <ModalHeader
      title="Delete Preferred Port"
      icon="delete"
      subtitle={`Remove preferred port with LOCODE (${delPort.locode})`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        color="error"
        variant="contained"
        disabled={isLoading}
      >
        Delete
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Button
          size="large"
          color="warning"
          variant="outlined"
          sx={{
            textTransform: 'none',
            textAlign: 'initial',
            width: '450px',
          }}
        >
          <MaterialIcon
            name="warning"
            color={orange}
            size={50}
            style={{
              marginRight: '1rem',
            }}
          />
          Once you delete this preferred port, there is no going back. Please be
          certain.
        </Button>
        <Stack spacing={2} mt={2.5}>
          <Typography variant="subtitle2">
            Enter preferred port LOCODE to delete
          </Typography>
          <TextField
            control={control}
            name="locode"
            placeholder={delPort.locode.toString()}
            autoFocus
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default DeletePreferredPort;
