import { styled } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const Wrapper = styled('div')`
  white-space: pre-wrap;
`;

const LinkRenderer = (props: any) => {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
};

const RenderMarkdown = ({ text }: { text: string }) => {
  return (
    <Wrapper>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{ a: LinkRenderer }}
        allowedElements={[
          'div',
          'table',
          'tbody',
          'td',
          'tr',
          'span',
          'p',
          'a',
          'li',
          'ul',
          'ol',
          'b',
          'i',
          'strong',
        ]}
      >
        {text.replace(/\n/gi, '&nbsp; \n')}
      </ReactMarkdown>
    </Wrapper>
  );
};

export default RenderMarkdown;
