import React from 'react';
import { stringAvatar } from 'utils/avatar';
import { Typography, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { StyledAvatar } from 'styles/common';

export const AvatarCard = ({ value }: { value: string }) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems="center">
      <StyledAvatar {...stringAvatar(value, 'initials')} />
      <Typography variant="body2" fontWeight={500}>
        {value}
      </Typography>
    </Stack>
  );
};

const RenderAvatar = (props: GridRenderCellParams<any, string>) => {
  const { value } = props;

  return value ? <AvatarCard value={value} /> : null;
};

export default RenderAvatar;
