import React, { useMemo, useState } from 'react';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import PageHeader from 'components/PageHeader';
import { useLoaderData, useParams, useRevalidator } from 'react-router-dom';
import {
  FlotillaVesselCommon,
  ListFlotVesResp,
} from '@greywing-maritime/frontend-library/dist/types/omegaFlotillaVessels';
import Table from 'components/Table';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { red, white } from 'lib/colors';
import MaterialIcon from 'components/MaterialIcon';
import DeleteBunkeringEstimates from '../components/DeleteBunkeringEstimates';
import { VisuallyHiddenInput } from 'styles/common';
import { handleAPIError, requireAuth } from 'utils/api';
import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Error, Loading, Success } from 'components/State';
import { COMPANY_VESSELS_KEY } from 'query/queries';

const UploadBunkeringEstimatesMutation = async ({
  companyId,
  file,
}: {
  companyId: number;
  file: File;
}) => {
  const endpoint = `/api/v2/omega/flotilla-vessels/bunkering-estimates`;
  const formData = new FormData();
  formData.append('companyId', companyId.toString());
  formData.append('file', file);
  const response = await requireAuth().then(async function (authToken) {
    return await fetch(getAPIUrl() + endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    });
  });
  handleAPIError(response.status);
  return;
};

const RenderActions = ({ deleteFn }: { deleteFn: () => void }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Delete Bunkering Estimate" placement="top" arrow>
        <IconButton onClick={deleteFn}>
          <MaterialIcon name="delete" color={red} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'vesselName', headerName: 'Vessel Name', width: 200 },
  { field: 'cpp', headerName: 'CPP', width: 200 },
  { field: 'dpp', headerName: 'DPP', width: 200 },
  { field: 'ballast', headerName: 'Ballast', width: 200 },
  { field: 'designDraft', headerName: 'Design Draft', width: 200 },
];

const BunkeringEstimatesPage = () => {
  const params = useParams();
  const companyId = parseInt(params.id!);
  const revalidator = useRevalidator();
  const queryClient = useQueryClient();
  const { flotillaVessels } = useLoaderData() as ListFlotVesResp;
  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UploadBunkeringEstimatesMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: COMPANY_VESSELS_KEY(companyId),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const estimates = useMemo(() => {
    return flotillaVessels
      .filter((vessel) => vessel.bunkeringEstimates)
      .map(({ id, vesselName, bunkeringEstimates }) => ({
        id,
        vesselName,
        cpp: bunkeringEstimates?.vesselStatus.cpp.description,
        dpp: bunkeringEstimates?.vesselStatus.dpp.description,
        ballast: bunkeringEstimates?.vesselStatus.ballast.description,
        designDraft: bunkeringEstimates?.vesselStatus.designDraft.description,
      }));
  }, [flotillaVessels]);

  const [vessel, setVessel] = useState<FlotillaVesselCommon | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    setVessel(row);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      mutate({
        companyId,
        file: e.target.files[0],
      });
    }
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: () => <RenderActions deleteFn={handleDeleteModal} />,
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Bunkering Estimates"
        subtitle="View and manage vessel bunkering estimates"
        chipText={estimates.length + ' vessels'}
        icon="oil_barrel"
        actions={
          <Button
            component="label"
            variant="contained"
            startIcon={<MaterialIcon name="cloud_upload" color={white} />}
          >
            Upload
            <VisuallyHiddenInput
              type="file"
              accept=".xlsx"
              onChange={handleFileUpload}
            />
          </Button>
        }
      />
      <Table
        height="100%"
        rows={estimates}
        columns={[...columns, actions]}
        onRowClick={handleRowClick}
        noRowsText="No vessel with bunkering estimates found"
        nonClickable
      />

      {vessel && showDeleteModal ? (
        <DeleteBunkeringEstimates
          id={vessel.id}
          open={showDeleteModal}
          closeFn={handleDeleteModal}
        />
      ) : null}

      {isLoading ? <Loading /> : null}
      {isSuccess ? <Success /> : null}
      {isError ? <Error text={error as Error} /> : null}
    </Stack>
  );
};

export default BunkeringEstimatesPage;
