import React from 'react';
import { Overlay } from 'styles/common';
import LottieAnimation from '../LottieAnimation';

const Success = () => {
  return (
    <Overlay>
      <LottieAnimation name="success-T7LG1jCPjO" width="20rem" loop={false} />
    </Overlay>
  );
};

export default Success;
