import React from 'react';
import 'material-icons/iconfont/material-icons.css';
import IconDict from 'material-icons/_data/versions.json';
import { black } from 'lib/colors';
import { Stack, styled } from '@mui/material';

export type MaterialIconType = keyof typeof IconDict;

const Icon = styled('span')<{ size?: number; angle?: number; color?: string }>`
  font-size: ${({ size }) => size}px;
  color: transparent;
  background: ${({ color }) => color ?? black};
  background-clip: text;
  transform: ${({ angle }) => (angle ? `rotate(${angle}deg)` : 'initial')};
`;

type Props = {
  name: MaterialIconType;
  type?: 'filled' | 'outlined' | 'round' | 'sharp' | 'two-tone';
  size?: number;
  rotate?: number;
  color?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};
const MaterialIcon = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      name,
      type = 'round',
      color,
      size = 24,
      style,
      rotate,
      onClick,
      ...props
    },
    ref
  ) => {
    return (
      <Stack ref={ref} {...props}>
        <Icon
          id="material-icons"
          className={
            type === 'filled' ? 'material-icons' : `material-icons-${type}`
          }
          color={color}
          size={size}
          angle={rotate}
          onClick={onClick}
          style={{
            ...style,
          }}
        >
          {name}
        </Icon>
      </Stack>
    );
  }
);

export default MaterialIcon;
