import { configureStore } from '@reduxjs/toolkit';
import { isDevelopment } from 'lib/environments';
import rootReducer from './reducers';
import middlewares from './middlewares';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // TODO: remove this check after refactor of redux states; e. g - `flotillaSearch.searchResults`
    // Avoid putting non-serializable values in redux store
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  devTools: isDevelopment,
});
