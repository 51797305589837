export const THEME_OPTIONS = ['light', 'dark'] as const;

export type ThemeMode = (typeof THEME_OPTIONS)[number];

export type CurrencyOption = {
  Flag: string;
  CountryName: string;
  Currency: string;
  Code: string;
  Symbol: string;
};
