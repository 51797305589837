import React from 'react';
import {
  isRouteErrorResponse,
  useLocation,
  useNavigate,
  useNavigation,
  useRouteError,
} from 'react-router-dom';
import { styled, Stack, Typography } from '@mui/material';
import Logo from './Logo';
import httpStatusCodes from 'lib/httpStatusCodes';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { selectUserAuth } from 'redux/selectors';
import MaterialIcon from './MaterialIcon';
import { white } from 'lib/colors';

const Container = styled(Stack)`
  width: 100%;
  height: 100vh;
  z-index: 0;
`;

const Header = styled(Stack)`
  display: flex;
  padding: 1rem 2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
`;

const LogoWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.8rem;
`;

const LogoIcon = styled(Logo)`
  height: 30px;
`;

const Wrapper = styled(Stack)`
  positon: relative;
  padding: 4rem;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Background = styled(Stack)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: -1;
  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 90%; /* Adjust the height of the fading effect as needed */
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 1)
    ); /* Fading effect for text with color stops */
    pointer-events: none; /* Allow interaction with elements behind it */
  }
`;

const ErrorBoundary = () => {
  const isAuthed = useSelector(selectUserAuth);
  const { state } = useNavigation();
  const { pathname, key } = useLocation();
  const error = useRouteError();
  const navigate = useNavigate();
  let status: number | string = '';
  let statusText: string = 'Oops! Unexpected Error';
  let data: string = '';

  if (isRouteErrorResponse(error)) {
    status = error.status;
    statusText =
      status in httpStatusCodes ? httpStatusCodes[status] : statusText;
    data = error?.data;
  } else if (error instanceof Error) {
    status = 'Err';
    data = error.message;
  }

  const goBack = () => {
    if (pathname && isRouteErrorResponse(error) && error?.status === 403)
      navigate('/?redirectTo=' + pathname);
    else {
      if (key === 'default') {
        navigate('/dashboard');
      } else {
        navigate(-1);
      }
    }
  };

  if (isRouteErrorResponse(error) || error instanceof Error) {
    return (
      <Container>
        <Header>
          <LogoWrapper>
            <LogoIcon />
            <Typography variant="h6">Greywing</Typography>
          </LogoWrapper>
          <LoadingButton
            variant="contained"
            onClick={goBack}
            loading={state === 'loading'}
          >
            <MaterialIcon
              name={isAuthed ? 'arrow_back_ios' : 'login'}
              color={state === 'loading' ? 'transparent' : white}
              size={16}
              style={{ marginRight: '0.5rem' }}
            />
            {isAuthed ? 'Go Back' : 'Login'}
          </LoadingButton>
        </Header>
        <Wrapper>
          <Background>
            <Typography
              variant="h1"
              color="#e2f2ff"
              fontSize="min(50vw, 40rem)"
              fontWeight={600}
            >
              {status}
            </Typography>
          </Background>
          <Stack>
            <Typography variant="h2" fontWeight={600} gutterBottom>
              {statusText}
            </Typography>
            <Typography variant="body1" fontWeight={300}>
              {data}
            </Typography>
          </Stack>
        </Wrapper>
      </Container>
    );
  } else {
    return null;
  }
};

export default ErrorBoundary;
