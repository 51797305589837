import React, { useCallback } from 'react';
import { styled, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { logoutUser } from 'utils/auth';
import MaterialIcon from 'components/MaterialIcon';
import { white } from 'lib/colors';

const StyledButton = styled(Button)`
  height: 3rem;
  min-width: 3rem;
`;

const LogoutButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClearState = useCallback(() => {
    logoutUser(dispatch);
    navigate('/');
  }, []); // eslint-disable-line

  return (
    <StyledButton onClick={handleClearState}>
      <MaterialIcon name="logout" size={20} color={white} />
    </StyledButton>
  );
};

export default LogoutButton;
