import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { CompanyCommon } from '@greywing-maritime/frontend-library/dist/types/companies';
import PageHeader from 'components/PageHeader';
import AssignUsers from './components/AssignUsers';
import RenderUser from 'components/Table/RenderAvatar';
import { blue, blue10, green, textGray, white } from 'lib/colors';
import Table from 'components/Table';
import { StyledChip } from 'styles/common';
import { companyQuery } from 'query/queries';
import { useQuery } from '@tanstack/react-query';
import { UserType } from './types';
import AddUser from './components/AddUser';
import MaterialIcon from 'components/MaterialIcon';

const RenderPIC = (props: GridRenderCellParams<any, number[]>) => {
  const { value } = props;
  return value ? (
    <StyledChip
      label={
        value.length > 0
          ? `${value.length} Vessel${value.length > 1 ? 's' : ''}`
          : 'No Vessels'
      }
      sx={{ backgroundColor: value.length > 0 ? blue : blue10 }}
    />
  ) : null;
};

const RenderApproval = (props: GridRenderCellParams<any, number[]>) => {
  const { value } = props;
  return value ? (
    <MaterialIcon name="verified" size={20} color={green} />
  ) : (
    <MaterialIcon name="hourglass_top" size={20} color={textGray} />
  );
};

const RenderCompany = (props: GridRenderCellParams<any, string>) => {
  const { value } = props;
  return value ? value : '-';
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'displayName',
    headerName: 'Name',
    width: 300,
    renderCell: RenderUser,
  },
  {
    field: 'isApproved',
    headerName: 'Is Approved ?',
    width: 150,
    renderCell: RenderApproval,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
  {
    field: 'companyName',
    headerName: 'Company',
    width: 200,
    renderCell: RenderCompany,
  },
  {
    field: 'vesselIdsPic',
    headerName: 'PIC (Vessels)',
    width: 150,
    renderCell: RenderPIC,
  },
];

function UsersPage({ type }: { type: 'company' | 'user' }) {
  const params = useParams();
  const users = useLoaderData() as UserType[];
  const navigate = useNavigate();
  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const showCompanyUsers = type === 'company';
  const { data: company } = useQuery({
    ...companyQuery(parseInt(params.id!)),
    enabled: showCompanyUsers && 'id' in params,
  });

  const getNavUrl = (id: number) => `/users/${id}/permissions`;

  const handleRowClick = (params: GridRowParams) => {
    const {
      row: { id },
    } = params;
    navigate(getNavUrl(id));
  };

  const handleAssignModal = () => {
    setShowAssignModal(!showAssignModal);
  };
  const handleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Users"
        subtitle="View and manage users"
        icon="groups"
        actions={
          <>
            <Button
              variant="contained"
              startIcon={<MaterialIcon name="add" color={white} />}
              onClick={handleAddModal}
            >
              Add
            </Button>
            {showCompanyUsers && company ? (
              <Button
                type="submit"
                variant="contained"
                disabled={users.length === 0}
                onClick={handleAssignModal}
              >
                Assign Existing Users
              </Button>
            ) : null}
          </>
        }
        chipText={users.length + ' members'}
      />

      <Table
        height="100%"
        rows={users}
        columns={columns}
        onRowClick={handleRowClick}
        openInNewTab={getNavUrl}
        noRowsText="No users found"
      />

      {showAddModal ? (
        <AddUser
          open={showAddModal}
          closeFn={handleAddModal}
          company={company as CompanyCommon}
        />
      ) : null}

      {showAssignModal ? (
        <AssignUsers
          open={showAssignModal}
          users={users}
          company={company as CompanyCommon}
          closeFn={handleAssignModal}
        />
      ) : null}
    </Stack>
  );
}

export default UsersPage;
