import * as React from 'react';
import {
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import Autocomplete, {
  autocompleteClasses,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { CurrencyOption } from 'utils/types';
import { CURRENCIES_LIST, getCurrencyFromCode } from 'lib/currencies';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  height: '100%',
  [`& .${autocompleteClasses.inputRoot}`]: {
    ...theme.typography.body2,
    padding: '1px 0',
    height: '100%',
    '& input': {
      padding: '0 16px',
      height: '100%',
    },
  },
})) as typeof Autocomplete;

type CurrencyOptionType = CurrencyOption;

export function EditCurrency(props: GridRenderEditCellParams<any, string>) {
  const { id, value, field } = props;

  const apiRef = useGridApiContext();

  const handleChange = React.useCallback<
    NonNullable<
      AutocompleteProps<CurrencyOptionType, false, true, false>['onChange']
    >
  >(
    async (event, newValue) => {
      await apiRef.current.setEditCellValue(
        {
          id,
          field,
          value: newValue.Code,
        },
        event
      );
      apiRef.current.stopCellEditMode({ id, field });
    },
    [apiRef, field, id]
  );

  return (
    <StyledAutocomplete<CurrencyOptionType, false, true, false>
      value={getCurrencyFromCode(value)}
      onChange={handleChange}
      options={CURRENCIES_LIST}
      getOptionLabel={(option: CurrencyOptionType) => option.Code}
      autoHighlight
      fullWidth
      open
      disableClearable
      renderOption={(optionProps, option: CurrencyOptionType) => (
        <Box
          component="li"
          sx={{
            '& > img': {
              mr: 1.5,
              flexShrink: 0,
            },
          }}
          {...optionProps}
        >
          <img loading="lazy" width="20" src={option.Flag} alt={option.Code} />
          {option.Code}
        </Box>
      )}
      renderInput={(params) => (
        <InputBase
          autoFocus
          fullWidth
          id={params.id}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          {...params.InputProps}
        />
      )}
    />
  );
}

export function renderEditCurrency(
  params: GridRenderEditCellParams<any, string>
) {
  return <EditCurrency {...params} />;
}

const Currency = React.memo(
  ({ value: { Flag, Code } }: { value: CurrencyOption }) => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '&  > img': {
          mr: 1,
          flexShrink: 0,
          width: '20px',
        },
      }}
    >
      <img loading="lazy" width="20" src={Flag} alt="" />
      <Box
        component="span"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {Code}
      </Box>
    </Box>
  )
);

export function renderCurrency({ value }: GridRenderCellParams<any, string>) {
  const currency = getCurrencyFromCode(value);
  return currency ? <Currency value={currency} /> : value;
}
