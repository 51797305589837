import React, { useEffect, useMemo, useState } from 'react';
import { useLoaderData, useParams, useRevalidator } from 'react-router-dom';
import { AssistedCrewChangeEmail, ParsedStatus, mailType } from '../types';
import {
  Button,
  Card,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import PageHeader from 'components/PageHeader';
import { AvatarCardV2 } from 'components/Table/RenderAvatarV2';
import {
  blue,
  blue10,
  green,
  orange,
  textGray,
  yellow10,
  yellowOrange,
} from 'lib/colors';
import moment from 'moment';
import Table from 'components/Table';
import {
  GridCellParams,
  GridColDef,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridRowHeightParams,
} from '@mui/x-data-grid-pro';
import { StyledChip } from 'styles/common';
import ChaserEmail from '../components/Modals/ChaserEmail';
import Email from '../components/Modals/Email';
import SeaGPTParsing from '../components/Modals/SeaGPTParsing';
import ParsedResults from '../components/ParsedResults';
import {
  DefaultToolbarItems,
  DefaultToolbarSearch,
} from 'components/Table/Toolbar';
import RefreshButton from 'components/RefreshButton';
import ClarificationEmail from '../components/Modals/ClarificationEmail';
import HoldRelease from '../components/Modals/HoldRelease';
import ConvoNavigate from '../components/ConvoNavigate';
import PortAgentDetails from '../components/Modals/PortAgentDetails';
import CompareParsedResults from '../components/Modals/CompareParsedResults';
import { AssistedCrewChangeConvoResp } from '@greywing-maritime/frontend-library/dist/types/acc';
import MaterialIcon from 'components/MaterialIcon';

const StyledCard = styled(Card)`
  display: flex;
  padding: 1rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  .MuiTypography-body2 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
  }
`;

const RenderStatus = (props: GridRenderCellParams<any, string>) => {
  const { value } = props;
  if (typeof value === 'undefined') return null;
  if (value in ParsedStatus) {
    const status = ParsedStatus[value as keyof typeof ParsedStatus];
    return (
      <Tooltip
        title={value === 'N/A' ? 'Sent' : value.split('_').join(' ')}
        arrow
        placement="top"
      >
        <StyledChip
          label={status.text}
          icon={
            <MaterialIcon name={status.icon} color={status.color} size={18} />
          }
          sx={{ color: status.color, backgroundColor: status.color + '10' }}
        />
      </Tooltip>
    );
  }
  return value;
};

export const columns: GridColDef[] = [
  {
    field: 'fromEmail',
    headerName: 'From',
    width: 160,
  },
  {
    field: 'toEmail',
    headerName: 'To',
    width: 160,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 140,
    valueGetter: (params) => {
      const value = params.row.updatedAt;
      if (typeof value === 'undefined') return null;
      return moment(params.row.updatedAt).format('MMM D, YYYY');
    },
  },
  {
    field: 'parsedStatus',
    headerName: 'Status',
    width: 180,
    renderCell: RenderStatus,
  },
];

const Conversation = ({ type }: { type: (typeof mailType)[number] }) => {
  const { conversation, emails, ...acc } =
    useLoaderData() as AssistedCrewChangeConvoResp;
  const params = useParams();
  const convoId = parseInt(params.convoId!);
  const [showChaserEmail, setShowChaserEmail] = useState<boolean>(false);
  const [showClarificationEmail, setShowClarificationEmail] =
    useState<boolean>(false);
  const [showAgentDetails, setShowAgentDetails] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [email, setEmail] = useState<AssistedCrewChangeEmail | null>(null);
  const [showSeaGPTParsing, setShowSeaGPTParsing] = useState<boolean>(false);
  const [showHoldReleaseResults, setShowHoldReleaseResults] =
    useState<boolean>(false);
  const [showComparisonView, setShowComparisonView] = useState<boolean>(false);
  const revalidator = useRevalidator();
  const pollingInterval = 60000;

  useEffect(() => {
    setInterval(revalidator.revalidate, pollingInterval);
  }, []); //eslint-disable-line

  const toolbar = () => {
    return (
      <>
        <DefaultToolbarItems />
        <RefreshButton onClick={revalidator.revalidate} />
        <DefaultToolbarSearch />
      </>
    );
  };

  const [starredMail, setStarredMail] = useState<number[]>(() => {
    const localStorageData = localStorage.getItem('starredMail') || '[]';
    return JSON.parse(localStorageData);
  });

  useEffect(() => {
    const updateStarredMail = () => {
      const localStorageData = localStorage.getItem('starredMail') || '[]';
      setStarredMail(JSON.parse(localStorageData));
    };
    window.addEventListener('storage', updateStarredMail);
    return () => {
      window.removeEventListener('storage', updateStarredMail);
    };
  }, []);

  const isStarred = useMemo(() => {
    return starredMail.indexOf(convoId) !== -1;
  }, [starredMail]); //eslint-disable-line

  const renderConvoInfo = () => (
    <StyledCard>
      <Stack
        width="100%"
        direction={'row'}
        spacing={3}
        justifyContent="space-between"
      >
        <Stack direction={'row'} spacing={5} alignItems="center">
          <AvatarCardV2 value={acc.creator} />
          <MaterialIcon name="send" color={blue10} size={20} />
          <AvatarCardV2
            value={{
              email: conversation.agent.email,
              name: conversation.agent.displayName,
            }}
          />
          <Button variant="outlined" onClick={handleAgentDetails}>
            Show Agent Details
          </Button>
        </Stack>
        <Stack direction={'row'} spacing={3} alignItems="center">
          <Typography variant="body2" fontWeight={500}>
            {moment(conversation.updatedAt).format('MMM D, YYYY')}
          </Typography>
          <IconButton
            style={{ marginTop: '-0.35rem' }}
            onClick={() => {
              starFn(convoId);
            }}
          >
            <MaterialIcon name="star" color={isStarred ? orange : yellow10} />
          </IconButton>
        </Stack>
      </Stack>
    </StyledCard>
  );

  const starFn = (convoId: number) => {
    const index = starredMail.indexOf(convoId);
    index !== -1 ? starredMail.splice(index, 1) : starredMail.push(convoId);
    localStorage.setItem('starredMail', JSON.stringify(starredMail));
    window.dispatchEvent(new Event('storage'));
  };

  const handleSeaGPTParsing = () => {
    setShowSeaGPTParsing(!showSeaGPTParsing);
  };

  const handleChaserEmail = () => {
    setShowChaserEmail(!showChaserEmail);
  };
  const handleClarificationEmail = () => {
    setShowClarificationEmail(!showClarificationEmail);
  };

  const handleShowHoldReleaseEmail = () => {
    setShowHoldReleaseResults(!showHoldReleaseResults);
  };

  const handleEmail = () => {
    setShowEmail(!showEmail);
  };

  const handleAgentDetails = () => {
    setShowAgentDetails(!showAgentDetails);
  };

  const handleComparisonView = () => {
    setShowComparisonView(!showComparisonView);
  };

  const handleCellClick = (params: GridCellParams) => {
    const { row, field } = params;
    if (row.id === -1) {
      setEmail(emails[0]);
      if (emails[0].isOutbound) handleChaserEmail();
      else handleClarificationEmail();
    } else {
      setEmail(row);
      if (field !== 'actions') handleEmail();
    }
  };

  const currDate = moment.now();
  const lastEmailDate = moment(emails[0].updatedAt);
  const dateDiff = -lastEmailDate.diff(currDate, 'days');

  const getPinnedRow = () => {
    let subject = '';
    if (emails[0].isOutbound && dateDiff > 5)
      subject = `Last email was sent ${dateDiff} days ago. Follow up ?`;
    if (!emails[0].isOutbound && conversation.latestResults?.questions)
      subject = `${emails[0].fromEmail} has included some information which may require further clarification. Send an email to clarify?`;
    return subject.length
      ? [
          {
            id: -1,
            subject,
          },
        ]
      : [];
  };

  const pinnedRows: GridPinnedRowsProp = {
    top: getPinnedRow(),
  };

  const actions: GridColDef = {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: ({ row }: GridRenderCellParams<AssistedCrewChangeEmail>) => {
      if (row.id === -1) return null;
      const { id, onHold, isOutbound, parsedStatus } = row;
      const showComparisonViewForEmail =
        conversation.latestParsedResultsAmongSuccessfulEmails &&
        !isOutbound &&
        parsedStatus.startsWith('PARSED') &&
        id !== conversation.latestParsedResultsAmongSuccessfulEmails?.aceId;
      return (
        <Stack flexDirection="row" gap={1}>
          {onHold && !isOutbound && (
            <Tooltip
              title="This email is not visible to the user"
              placement="top"
              arrow
            >
              <IconButton onClick={handleShowHoldReleaseEmail}>
                <MaterialIcon name="block" color={textGray} size={20} />
              </IconButton>
            </Tooltip>
          )}
          {!onHold && !isOutbound && (
            <Tooltip
              title="This email is visible to the user"
              placement="top"
              arrow
            >
              <IconButton onClick={handleShowHoldReleaseEmail}>
                <MaterialIcon name="done_all" color={green} size={20} />
              </IconButton>
            </Tooltip>
          )}
          {!isOutbound && (
            <Tooltip title="Parse Email using Proteus" placement="top" arrow>
              <IconButton onClick={handleSeaGPTParsing}>
                <MaterialIcon name="document_scanner" color={blue} size={20} />
              </IconButton>
            </Tooltip>
          )}
          {isOutbound && (
            <Tooltip title="Send Chaser Email" placement="top" arrow>
              <IconButton onClick={handleChaserEmail}>
                <MaterialIcon name="mail" color={blue} size={20} />
              </IconButton>
            </Tooltip>
          )}
          {showComparisonViewForEmail && (
            <Tooltip title="Compare Parsed Results" placement="top" arrow>
              <IconButton onClick={handleComparisonView}>
                <MaterialIcon name="compare" color={blue} size={20} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      );
    },
  };

  const extraColumns: GridColDef[] = [
    {
      field: 'subject',
      headerName: 'Email',
      flex: 1,
      minWidth: 500,
    },
    {
      field: 'id',
      headerName: '',
      width: 60,
      renderCell: ({ value }) => {
        return (
          value ===
            conversation.latestParsedResultsAmongSuccessfulEmails?.aceId && (
            <Tooltip
              title="This email is being used for latest parsed results"
              placement="top"
              arrow
            >
              <MaterialIcon name="push_pin" color={blue} size={20} />
            </Tooltip>
          )
        );
      },
    },
  ];

  return (
    <Stack>
      <PageHeader
        title={
          acc.portName && acc.portLocode
            ? `${acc.portName} - ${acc.portLocode}`
            : `${acc.portName || acc.portLocode || '-'}`
        }
        chipText={`${acc.crewDetails.onsigners.reduce(
          (acc, crew) => acc + crew.numCrew,
          0
        )} Onsigners - ${acc.crewDetails.offsigners.reduce(
          (acc, crew) => acc + crew.numCrew,
          0
        )} Offsigners`}
        icon="inbox"
        actions={ConvoNavigate({ type })}
      />
      <Stack gap={4}>
        {renderConvoInfo()}

        {conversation.latestParsedResultsAmongSuccessfulEmails ? (
          <ParsedResults
            parsedResults={
              conversation.latestParsedResultsAmongSuccessfulEmails
            }
            aceIdforUpdate={conversation.latestResults?.aceId!}
            acc={acc}
          />
        ) : null}

        <Typography variant="h4" fontWeight="600">
          Email History
        </Typography>
        <Table
          height="500px"
          rows={emails}
          pinnedRows={pinnedRows}
          columns={[actions, ...extraColumns, ...columns]}
          onCellClick={handleCellClick}
          noRowsText="No assisted crew change emails found"
          getRowHeight={({ id }: GridRowHeightParams) => {
            if ((id as number) === -1) {
              return 30;
            }

            return null;
          }}
          toolbar={toolbar()}
          sx={{
            '.MuiDataGrid-pinnedRows': {
              color: yellowOrange,
              bgcolor: yellow10,
              fontSize: 12.5,
              fontWeight: '500',
              boxShadow: 'none',
            },
          }}
        />
      </Stack>
      {email && showChaserEmail ? (
        <ChaserEmail
          email={email}
          open={showChaserEmail}
          closeFn={handleChaserEmail}
        />
      ) : null}
      {email && showClarificationEmail ? (
        <ClarificationEmail
          questions={conversation.latestResults?.questions}
          email={email}
          open={showClarificationEmail}
          closeFn={handleClarificationEmail}
        />
      ) : null}
      {email && showSeaGPTParsing ? (
        <SeaGPTParsing
          aceId={email.id}
          open={showSeaGPTParsing}
          closeFn={handleSeaGPTParsing}
        />
      ) : null}
      {email && showEmail ? (
        <Email email={email} open={showEmail} closeFn={handleEmail} />
      ) : null}
      {email && showHoldReleaseResults && (
        <HoldRelease
          email={email}
          closeFn={() => setShowHoldReleaseResults(false)}
        />
      )}
      {conversation.agent && showAgentDetails && (
        <PortAgentDetails
          portAgent={conversation.agent}
          open={showAgentDetails}
          closeFn={handleAgentDetails}
        />
      )}
      {email &&
        showComparisonView &&
        conversation.latestParsedResultsAmongSuccessfulEmails && (
          <CompareParsedResults
            parsedResults={
              conversation.latestParsedResultsAmongSuccessfulEmails
            }
            aceIdforUpdate={conversation.latestResults?.aceId!}
            emailId={email.id}
            open={showComparisonView}
            closeFn={handleComparisonView}
          />
        )}
    </Stack>
  );
};

export default Conversation;
