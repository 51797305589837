import queryClient from 'query';
import { vesselTagsQuery } from 'query/queries';

export const VesselTagsLoader = async () => {
  const query = vesselTagsQuery;
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
