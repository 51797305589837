import { CompaniesWithStatsLoader } from 'pages/company/loader';
import DashboardPage from './Dashboard';
import { RouteObject } from 'react-router';

const routes: RouteObject[] = [
  {
    path: '/dashboard',
    element: <DashboardPage />,
    loader: CompaniesWithStatsLoader,
  },
];

export default routes;
