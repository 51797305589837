import React from 'react';
import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { green, red } from 'lib/colors';
import { AssistedCrewChangeCrewDetails } from '@greywing-maritime/frontend-library/dist/types/acc';
import MaterialIcon from 'components/MaterialIcon';

const RenderCrew = (
  props: GridRenderCellParams<any, AssistedCrewChangeCrewDetails>
) => {
  const { value } = props;

  return value ? (
    <Stack direction={'row'} spacing={2} alignItems="center">
      <Stack direction={'row'} spacing={1}>
        <MaterialIcon name="person_add" color={green} size={18} />
        <Typography variant="body2" fontWeight={700}>
          {value.onsigners.reduce((acc, crew) => acc + crew.numCrew, 0)}
        </Typography>
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <MaterialIcon name="person_remove" color={red} size={18} />
        <Typography variant="body2" fontWeight={700}>
          {value.offsigners.reduce((acc, crew) => acc + crew.numCrew, 0)}
        </Typography>
      </Stack>
    </Stack>
  ) : null;
};

export default RenderCrew;
