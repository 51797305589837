import { ListRiskAreasResp } from '@greywing-maritime/frontend-library/dist/types/riskAreas';
import {
  ListCompaniesResp,
  CompanyCommon,
} from '@greywing-maritime/frontend-library/dist/types/companies';
import { GetStatsResp } from '@greywing-maritime/frontend-library/dist/types/usageStats';
import { ListFeaturesResp } from '@greywing-maritime/frontend-library/dist/types/features';
import {
  ListUserAccountsResp,
  OmegaUserFullCommon,
} from '@greywing-maritime/frontend-library/dist/types/omegaUsers';
import { ListCmsJobsResp } from '@greywing-maritime/frontend-library/dist/types/cms';
import { VesselTags } from 'pages/vessel-tags/types';
import { authedGet, authedPaginatedGet, handleAPIError } from 'utils/api';
import { ListVmsJobsResp } from '@greywing-maritime/frontend-library/dist/types/vms';
import { ListFlotVesResp } from '@greywing-maritime/frontend-library/dist/types/omegaFlotillaVessels';
import {
  GetPortAgentResp,
  ListAllPortAgentsResp,
} from '@greywing-maritime/frontend-library/dist/types/omegaPortAgents';
import { GetPreferredPortsResponse } from '@greywing-maritime/frontend-library/dist/types/omegaPreferredPorts';
import { ListAgencyCostsResp } from '@greywing-maritime/frontend-library/dist/types/omegaAgencyCosts';
import { ListPortRestrictionsResp } from '@greywing-maritime/frontend-library/dist/types/omegaRestrictionsData';
import { AssistedCrewChangeConvoResp } from '@greywing-maritime/frontend-library/dist/types/acc';
import { OmegaListAssistedCrewChangeConvosResp } from '@greywing-maritime/frontend-library/dist/types/omegaAcc';
import { ListDataPushJobsResp } from '@greywing-maritime/frontend-library/dist/types/dataPushJobs';

/* ----- Query Function Keys -----*/

export const FEATURES_KEY = ['features'];
export const VESSEL_TAGS_KEY = ['vessel-tags'];
export const RISKAREA_KEY = ['riskArea'];

export const STATS_KEY = ['companies-stats'];
export const COMPANY_STATS_KEY = (id: number) => ['company-stats', id];

export const COMPANIES_KEY = ['companies'];
export const COMPANY_KEY = (id: number) => ['company', id];

export const USERS_KEY = ['users'];
export const USER_KEY = (id: number) => ['user', id];

export const CMS_JOBS_KEY = ['cms-jobs'];
export const VMS_JOBS_KEY = ['vms-jobs'];
export const DATA_PUSH_JOBS_KEY = ['data-push-jobs'];

export const COMPANY_VESSELS_KEY = (id: number) => ['company-vessels', id];
export const USER_VESSELS_KEY = (id: number) => ['user-vessels', id];

export const PORT_AGENTS_KEY = ['port-agents'];
export const PORT_AGENT_KEY = (id: number) => ['port-agent', id];

export const PORT_RESTRICTIONS_KEY = ['port-restrictions'];

export const PORT_AGENCY_COSTS_KEY = ['port-agency-costs'];
export const COMPANY_PORT_AGENCY_COSTS_KEY = (id: number) => [
  'port-agency-costs',
  'company',
  id,
];

export const INBOX_KEY = ['inbox', 'all'];
export const COMPANY_INBOX_KEY = (id: number) => ['inbox', 'company', id];
export const USER_INBOX_KEY = (id: number) => ['inbox', 'user', id];

export const CONVERSATION_KEY = (id: number) => ['conversation', id];

export const COMPANY_PREFERRED_PORTS_KEY = (id: number) => [
  'company-preferred-ports',
  id,
];

export const COMPANY_FLIGHTS_CONFIG_KEY = (id: number) => [
  'company-flights-config',
  id,
];

export const PARSED_RESULTS_FOR_EMAIL_KEY = (id: number) => [
  'parsed-results-for-email',
  id,
];

/* ----- Query Functions -----*/

export const featuresQuery = {
  queryKey: FEATURES_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/features`;
    const data = await authedPaginatedGet(endpoint);
    return data as ListFeaturesResp;
  },
};

export const vesselTagsQuery = {
  queryKey: VESSEL_TAGS_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/vessels/tags`;
    const data = await authedPaginatedGet(endpoint);
    return data as VesselTags;
  },
};

export const riskAreaQuery = {
  queryKey: RISKAREA_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/risk-areas`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as ListRiskAreasResp;
  },
};

export const statsQuery = {
  queryKey: STATS_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/stats`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as GetStatsResp;
  },
};
export const companyStatsQuery = (id: number) => ({
  queryKey: COMPANY_STATS_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/stats?companyId=${id}`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as GetStatsResp;
  },
});

export const companiesQuery = {
  queryKey: COMPANIES_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/companies`;
    const data = await authedPaginatedGet(endpoint);
    return data as ListCompaniesResp;
  },
};

export const companyQuery = (id: number) => ({
  queryKey: COMPANY_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/companies/${id}`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as CompanyCommon;
  },
});

export const usersQuery = {
  queryKey: USERS_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/user-accounts`;
    const data = await authedPaginatedGet(endpoint);
    return data as ListUserAccountsResp;
  },
};

export const userQuery = (id: number) => ({
  queryKey: USER_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/user-accounts/${id}`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as OmegaUserFullCommon;
  },
});

export const cmsJobsQuery = {
  queryKey: CMS_JOBS_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/cms/jobs`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as ListCmsJobsResp;
  },
};

export const vmsJobsQuery = {
  queryKey: VMS_JOBS_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/vms/jobs`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as ListVmsJobsResp;
  },
};

export const dataPushJobsQuery = {
  queryKey: DATA_PUSH_JOBS_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/data-push/jobs`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as ListDataPushJobsResp;
  },
};

export const companyVesselsQuery = (id: number) => ({
  queryKey: COMPANY_VESSELS_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/flotilla-vessels?companyId=${id}`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as ListFlotVesResp;
  },
});

export const userVesselsQuery = (id: number) => ({
  queryKey: USER_VESSELS_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/flotilla-vessels?userId=${id}`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as ListFlotVesResp;
  },
});

export const portAgentsQuery = {
  queryKey: PORT_AGENTS_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/port-agents`;
    const data = await authedPaginatedGet(endpoint, {}, 1000);
    return data as ListAllPortAgentsResp;
  },
};

export const portAgentQuery = (id: number) => ({
  queryKey: PORT_AGENT_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/port-agents/${id}`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as GetPortAgentResp;
  },
});

export const portAgencyCostsQuery = {
  queryKey: PORT_AGENCY_COSTS_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/agency-costs`;
    const data = await authedPaginatedGet(
      endpoint,
      {
        showHidden: true,
      },
      10000
    );
    return data as ListAgencyCostsResp;
  },
};

export const companyPortAgencyCostsQuery = (id: number) => ({
  queryKey: COMPANY_PORT_AGENCY_COSTS_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/agency-costs`;
    const data = await authedPaginatedGet(
      endpoint,
      {
        companyId: id,
        showHidden: true,
      },
      10000
    );
    return data as ListAgencyCostsResp;
  },
});

export const portRestrictionsQuery = {
  queryKey: PORT_RESTRICTIONS_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/restrictions-data/ports`;
    const data = await authedPaginatedGet(endpoint);
    return data as ListPortRestrictionsResp;
  },
};

export const inboxQuery = {
  queryKey: INBOX_KEY,
  queryFn: async () => {
    const endpoint = `/api/v2/omega/assisted-crew-changes/convos`;
    const data = await authedPaginatedGet(endpoint, { scope: 'ALL' });
    return data as OmegaListAssistedCrewChangeConvosResp;
  },
};

export const companyInboxQuery = (id: number) => ({
  queryKey: COMPANY_INBOX_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/assisted-crew-changes/convos`;
    const data = await authedPaginatedGet(endpoint, {
      scope: 'COMPANY',
      companyId: id,
    });
    return data as OmegaListAssistedCrewChangeConvosResp;
  },
});

export const userInboxQuery = (id: number) => ({
  queryKey: USER_INBOX_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/assisted-crew-changes/convos`;
    const data = await authedPaginatedGet(endpoint, {
      scope: 'USER',
      userId: id,
    });
    return data as OmegaListAssistedCrewChangeConvosResp;
  },
});

export const conversationQuery = (id: number) => ({
  queryKey: CONVERSATION_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/assisted-crew-changes/convos/${id}`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as AssistedCrewChangeConvoResp;
  },
});

export const companyPreferredPortsQuery = (id: number) => ({
  queryKey: COMPANY_PREFERRED_PORTS_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/preferred-ports?companyId=${id}`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data as GetPreferredPortsResponse;
  },
});

export const companyFlightsQuery = (id: number) => ({
  queryKey: COMPANY_FLIGHTS_CONFIG_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/flights-config?companyId=${id}`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data;
  },
});

export const ParsedResultsForEmailQuery = (id: number) => ({
  queryKey: PARSED_RESULTS_FOR_EMAIL_KEY(id),
  queryFn: async () => {
    const endpoint = `/api/v2/omega/assisted-crew-changes/emails/${id}/parsed-results`;
    const response = await authedGet(endpoint);
    const data = await response.json();
    handleAPIError(response.status, data);
    return data;
  },
});
