import * as yup from 'yup';

export const EmailSchema = yup.object().shape({
  aceId: yup.number().positive().required(),
  subject: yup.string().required(),
  body: yup.string().required(),
  attachments: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        contentType: yup.string().required(),
        content: yup.string().required(),
      })
    )
    .optional(),
});
