import React, { useCallback } from 'react';
import { Stack, Tooltip, Typography, styled } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import { useLoaderData } from 'react-router';
import {
  AgencyCostCommon,
  CategoryCostCommon,
  ListAgencyCostsResp,
} from '@greywing-maritime/frontend-library/dist/types/omegaAgencyCosts';
import moment from 'moment';
import { capitalize, startCase } from 'lodash';
import { formatCurrencyDisplay } from 'utils/helpers';
import {
  DataGridProProps,
  useGridApiRef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { StyledAvatar, StyledChip } from 'styles/common';
import { getCountryFromAlpha2 } from 'lib/countries';
import { stringAvatar } from 'utils/avatar';
import { SimpleCard } from 'components/Table/Cards';
import { PaxCountType } from '@greywing-maritime/frontend-library/dist/types/proxPorts';

const StyledCard = styled(SimpleCard)`
  h4 {
    margin: 0;
    font-weight: 600;
    span {
      font-weight: 400;
    }
  }
`;

const StyledStack = styled(Stack)`
  padding: 0.5rem;
  margin-top: 0.3rem;
  background-color: #f2f0f9;
  border-radius: 5px;
`;

type CardItemProps = {
  label: string;
  value?: string | number;
};

const CardItem = ({ label, value }: CardItemProps) => (
  <h4>
    {label}: <span>{value}</span>
  </h4>
);

const RenderHasCompany = (props: GridRenderCellParams<any, number | null>) => {
  const { value } = props;
  return value ? (
    <StyledChip label={`Company ID: ${value}`} size="small" />
  ) : (
    '-'
  );
};

const RenderCategories = (props: GridRenderCellParams<any, string[]>) => {
  const value = props.value?.filter(Boolean);

  return (
    <Stack direction={'row'} spacing={-1}>
      {value && value.length > 0
        ? value.slice(0, 5).map((c, key) => (
            <Tooltip key={key} title={c || ''} placement="top" arrow>
              <StyledAvatar {...stringAvatar(c, 'initials')} />
            </Tooltip>
          ))
        : '-'}
      {value && value.length > 5 ? (
        <Tooltip title={value.slice(5).join(', ')} placement="top" arrow>
          <StyledAvatar {...stringAvatar('+' + (value.length - 5))} />
        </Tooltip>
      ) : null}
    </Stack>
  );
};

// TODO: update current assumption that there is only 1 pax
const PAX_COUNT = 1;

const columns: GridColDef[] = [
  {
    field: 'locode',
    headerName: 'Port',
    width: 100,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 180,
    valueGetter: ({ row }) =>
      getCountryFromAlpha2(row.locode.slice(0, 2))?.name,
  },
  {
    field: 'companyId',
    headerName: 'Company ?',
    width: 150,
    renderCell: RenderHasCompany,
  },
  {
    field: 'isSeaGPT',
    headerName: 'isProteus ?',
    width: 150,
    valueGetter: ({ row }) =>
      row.accConvoId && row.aceParsedResultsId ? 'Yes' : 'No',
  },
  {
    field: 'agencyName',
    headerName: 'Agency Name',
    width: 120,
    valueFormatter: ({ value }) => value.toUpperCase(),
  },
  {
    field: 'categories',
    headerName: 'Categories',
    width: 200,
    valueGetter: ({ row }) =>
      row.breakdown.map(({ category }: { category: string }) => category),
    renderCell: RenderCategories,
  },
  {
    field: 'totalCost',
    headerName: 'Indicative Total Cost',
    width: 200,
    valueGetter: ({ row }) => {
      const totalIndicative = row.breakdown.reduce(
        (acc: number, { indicative, perPaxType }: CategoryCostCommon) => {
          if (perPaxType === PaxCountType.PER_PAX) {
            acc += indicative * PAX_COUNT;
          } else {
            acc += indicative;
          }
          return acc;
        },
        0
      );
      return formatCurrencyDisplay(totalIndicative, row.breakdown[0].currency);
    },
  },
  {
    field: 'agencyCostType',
    headerName: 'Agency Cost Type',
    width: 200,
    valueFormatter: ({ value }) => capitalize(value),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 150,
    valueGetter: ({ row }) => moment(row.updatedAt).format('MMM D, YYYY'),
  },
  {
    field: 'checkedAt',
    headerName: 'Checked At',
    width: 150,
    valueGetter: ({ row }) => moment(row.updatedAt).format('MMM D, YYYY'),
  },
];

function DetailPanelContent({ row: { breakdown } }: { row: AgencyCostCommon }) {
  return (
    <Stack direction="row" spacing={2}>
      {breakdown.map(
        (
          {
            category,
            indicative,
            currency,
            perPaxType,
            relevantNumCrew,
            nationalitySpecificCosts,
          },
          index
        ) => (
          <StyledCard key={index}>
            <CardItem label="Category" value={category} />
            <CardItem
              label="Cost"
              value={formatCurrencyDisplay(indicative, currency)}
            />
            <CardItem
              label="Pax Type"
              value={startCase(perPaxType.toLowerCase().split('_').join(' '))}
            />
            {relevantNumCrew ? (
              <StyledStack>
                <CardItem label="Number of Crew" />
                <Stack ml={1}>
                  <CardItem label="Min" value={relevantNumCrew.min ?? '-'} />
                  <CardItem label="Max" value={relevantNumCrew.max ?? '-'} />
                </Stack>
              </StyledStack>
            ) : null}
            {nationalitySpecificCosts ? (
              <StyledStack>
                <CardItem label="Nationality Specific Costs" />
                <Stack ml={1}>
                  {Object.entries(nationalitySpecificCosts).map(
                    ([key, value]: any) => {
                      const { indicative, currency, perPaxType } = value;
                      return (
                        <Stack key={key}>
                          <CardItem label={key} />
                          <Stack ml={1}>
                            <CardItem
                              label="Cost"
                              value={formatCurrencyDisplay(
                                indicative,
                                currency
                              )}
                            />
                            <CardItem
                              label="Pax Type"
                              value={startCase(
                                perPaxType.toLowerCase().split('_').join(' ')
                              )}
                            />
                          </Stack>
                        </Stack>
                      );
                    }
                  )}
                </Stack>
              </StyledStack>
            ) : null}
          </StyledCard>
        )
      )}
    </Stack>
  );
}

const PortAgencyCostsPage = () => {
  const { agencyCosts } = useLoaderData() as ListAgencyCostsResp;
  const apiRef = useGridApiRef();

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <DetailPanelContent row={row} />, []);

  return (
    <Stack height="100%">
      <PageHeader
        title="Port Agency Costs"
        subtitle="View Port Agency Costs around the world"
        chipText={agencyCosts.length + ' agency costs'}
      />
      <Typography variant="caption" paragraph>
        Note: Indicative Total Cost = Total cost calculated on the basis of a 1
        person crew change.
        <br />
        It doesn't include the cost associated with nationality specific costs.
      </Typography>
      <Table
        apiRef={apiRef}
        height="100%"
        rows={agencyCosts}
        columns={columns}
        noRowsText="No port agency costs found"
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        onRowClick={(params) => {
          apiRef.current.toggleDetailPanel(params.id);
        }}
      />
    </Stack>
  );
};

export default PortAgencyCostsPage;
