import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Stack,
  Button,
  Radio,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { authedPost, handleAPIError } from 'utils/api';
import LottieAnimation from 'components/LottieAnimation';

type MutateProps = { aceId: number; forceParseAttachments?: boolean };

const ParsingMutation = async ({
  aceId,
  forceParseAttachments,
}: MutateProps) => {
  const endpoint = `/api/v2/omega/assisted-crew-changes/emails/${aceId}/parse`;
  const response = await authedPost(endpoint, { forceParseAttachments });
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};

type Props = {
  aceId: number;
  open: boolean;
  closeFn: () => void;
};
const SeaGPTParsing = ({ aceId, open, closeFn }: Props) => {
  const [forceParseAttachments, setForceParseAttachments] =
    useState<boolean>(false);

  const handleForceParseAttachments = () => {
    setForceParseAttachments(!forceParseAttachments);
  };

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: ParsingMutation,
    onSuccess: () => {
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    mutate({ aceId, forceParseAttachments });
  };

  const headerComp = () => (
    <ModalHeader
      title="Proteus Parsing"
      icon="document_scanner"
      subtitle={`Parse Email using Proteus`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        disabled={isLoading}
      >
        Parse
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <LottieAnimation
        name="doc-scan-h4crCHniv7"
        height={200}
        width={200}
        loop={true}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Radio
              checked={!forceParseAttachments}
              onChange={handleForceParseAttachments}
            />
          }
          label="Parse normally"
        />
        <FormControlLabel
          control={
            <Radio
              checked={forceParseAttachments}
              onChange={handleForceParseAttachments}
            />
          }
          label="Parse all attachments"
          sx={{ mt: '-5px', mb: '-10px' }}
        />
      </FormGroup>
    </Modal>
  );
};

export default SeaGPTParsing;
