import { Button, Stack, styled } from '@mui/material';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import React from 'react';
import ParsedResults from '../ParsedResults';
import { useQuery } from '@tanstack/react-query';
import { ParsedResultsForEmailQuery } from 'query/queries';
import { black, blue, gray20, white } from 'lib/colors';
import moment from 'moment';
import { StyledChip } from 'styles/common';
import { AssistedCrewChangeEmailParsedResults } from '@greywing-maritime/frontend-library/dist/types/acc';

type Props = {
  parsedResults: AssistedCrewChangeEmailParsedResults;
  aceIdforUpdate: number;
  emailId: number;
  open: boolean;
  closeFn: () => void;
};

const StyledView = styled(Stack)`
  width: 50%;
  height: fit-content;
`;

const Divider = styled('div')`
  border: 1px solid ${gray20};
  margin: 0 16px;
`;

const TitleWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
`;

const Title = styled(Stack)`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  background-color: ${black};
  color: ${white};
  border-radius: 4px;
  width: fit-content;
  padding: 0.5rem 1rem;
`;

const Time = styled(StyledChip)`
  font-size: 12px;
  background-color: ${blue + 10};
  color: ${black};
  font-weight: 500;
  border-left: 2px solid ${blue};
  border-radius: 2px;
`;

const formatDate = (date: Date) =>
  `${moment(date).format('MMM DD, YYYY, h:mm A')} (${moment(date).fromNow()})`;
const CompareParsedResults = ({
  parsedResults,
  aceIdforUpdate,
  emailId,
  open,
  closeFn,
}: Props) => {
  const { isLoading, isError, error, data } = useQuery(
    ParsedResultsForEmailQuery(emailId)
  );
  const headerComp = () => (
    <ModalHeader
      title="Compare Parsed Results"
      icon="compare"
      subtitle={`View and compare email parsed results with latest parsed results`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
    </Stack>
  );

  let emailParsedResults = null;
  if (data)
    emailParsedResults = (
      data as { data: AssistedCrewChangeEmailParsedResults[] }
    ).data[0];

  return (
    <Modal
      width={'calc(100% - 4rem)'}
      height={'calc(100% - 4rem)'}
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      error={isError && error instanceof Error ? error.message : null}
    >
      {emailParsedResults ? (
        <Stack direction="row" width="calc(100% - 48px)">
          <StyledView gap={4}>
            <TitleWrapper>
              <Title>Latest Parsed Results</Title>
              <Time label={formatDate(parsedResults.updatedAt)} />
            </TitleWrapper>
            <ParsedResults
              parsedResults={parsedResults}
              aceIdforUpdate={aceIdforUpdate}
            />
          </StyledView>
          <Divider />
          <StyledView gap={4}>
            <TitleWrapper>
              <Title>Email Parsed Results</Title>
              <Time label={formatDate(emailParsedResults.updatedAt)} />
            </TitleWrapper>
            <ParsedResults parsedResults={emailParsedResults} />
          </StyledView>
        </Stack>
      ) : null}
    </Modal>
  );
};

export default CompareParsedResults;
