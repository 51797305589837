import { RouteObject } from 'react-router';
import RiskAreaPage from './RiskArea';
import { RiskAreaLoader } from 'pages/risk-area/loader';

const routes: RouteObject[] = [
  {
    path: '/risk-area',
    element: <RiskAreaPage />,
    loader: RiskAreaLoader,
  },
];

export default routes;
