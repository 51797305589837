import React from 'react';
import { mailType } from '../../types';
import RenderAvatar from 'components/Table/RenderAvatarV2';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { blue10 } from 'lib/colors';
import RenderEmailCount from '../Table/RenderEmailCount';
import Table from 'components/Table';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { Button, Stack } from '@mui/material';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { Loading } from 'components/State';
import { AssistedCrewChangeConvoSummaryResp } from '@greywing-maritime/frontend-library/dist/types/acc';
import MaterialIcon from 'components/MaterialIcon';

export const columns: GridColDef[] = [
  {
    field: 'creator',
    headerName: 'Executed between',
    width: 250,
    renderCell: RenderAvatar,
    valueGetter: (params) => params.row.acc.creator,
  },
  {
    field: 'icon',
    headerName: '',
    width: 50,
    resizable: false,
    disableColumnMenu: true,
    renderCell: () => {
      return <MaterialIcon name="send" color={blue10} size={18} />;
    },
  },
  {
    field: 'agent',
    headerName: '',
    width: 250,
    renderCell: RenderAvatar,
    valueGetter: (params) => ({
      email: params.row.agent.email,
      name: params.row.agent.displayName,
    }),
  },
  {
    field: 'emailCount',
    headerName: 'Sent/received',
    width: 120,
    renderCell: RenderEmailCount,
    valueGetter: (params) => ({
      numEmailsSent: params.row.numEmailsSent,
      numEmailsReceived: params.row.numEmailsReceived,
    }),
  },
];

type Props = {
  conversations: AssistedCrewChangeConvoSummaryResp[];
  open: boolean;
  closeFn: () => void;
  type: (typeof mailType)[number];
};

const Conversations = ({ conversations, open, type, closeFn }: Props) => {
  const navigate = useNavigate();
  const pageParams = useParams();
  const { state } = useNavigation();

  const getNavUrl = (id: number) =>
    type === 'all'
      ? `/mail/inbox/${id}`
      : `/${type}/${parseInt(pageParams.id!)}/mail/inbox/${id}`;

  const handleRowClick = (params: GridRowParams) => {
    const {
      row: { id },
    } = params;
    navigate(getNavUrl(id));
  };

  const headerComp = () => (
    <ModalHeader title="Agents Contacted" icon="person" />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
    </Stack>
  );
  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      height={800}
      width={800}
    >
      {state === 'loading' ? <Loading /> : null}
      <Table
        height={400}
        rows={conversations}
        columns={columns}
        onRowClick={handleRowClick}
        openInNewTab={getNavUrl}
        noRowsText="No assisted crew change conversation found"
        disableScrollRestoration
      />
    </Modal>
  );
};

export default Conversations;
