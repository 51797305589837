// ---------------------- Palette ----------------------

// Grays
export const gray90 = '#424242';
export const gray80 = '#616161';
export const gray70 = '#757575';
export const gray60 = '#9E9E9E';
export const gray50 = '#BDBDBD';
export const gray40 = '#E0E0E0';
export const gray30 = '#EEEEEE';
export const gray20 = '#F5F5F5';
export const gray10 = '#FAFAFA';

export const blue90 = '#12263F';
export const blue70 = '#21378C';
export const blue50 = '#385dea';
export const blue40 = '#3353d3';
export const blue10 = '#879DF2';

export const red50 = '#E24F4C';
export const red10 = '#FCEAEA';

export const yellow50 = '#ffb547';
export const yellow10 = '#ffedd3';

export const black90 = '#212121';
// General
export const blue = blue50;
export const borderGray = gray40;
export const gray = gray20;
export const lightGray = gray10;
export const black = '#000000';
export const white = '#ffffff';
export const green = '#50b72e';
export const purple = '#534AB5';
export const red = '#FF6B6B';
export const orange = '#FFA500';
export const yellowOrange = '#F29F39';
export const bluePurple = '#DCE4F3';
export const darkBlue = '#233a88';
export const blueGray = '#5972A3';
export const darkerBlue = '#101828';

// Typography
export const textBlack = '#2a2a2c';
export const iconGray = gray50;
export const textGray = gray60;

//code
export const codeText = '#bfc6fa';
export const codeBg = '#323e55';
