import { RouteObject } from 'react-router';
import VesselTagsPage from './VesselTags';
import { VesselTagsLoader } from './loader';

const routes: RouteObject[] = [
  {
    path: '/vessel-tags',
    element: <VesselTagsPage />,
    loader: VesselTagsLoader,
  },
];

export default routes;
