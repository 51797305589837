import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PermissionType } from '../types';
import { PermissionSchema } from '../validation';
import Modal from 'components/Modal';
import { TextField } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { authedDelete, handleAPIError } from 'utils/api';
import { useRevalidator } from 'react-router-dom';
import { COMPANY_KEY, USER_KEY } from 'query/queries';
import MaterialIcon from 'components/MaterialIcon';
import { orange } from 'lib/colors';

type Props = {
  permission: PermissionType & { id: number };
  type: 'company' | 'user';
  open: boolean;
  closeFn: () => void;
};

type DeletePermissionType = yup.InferType<typeof PermissionSchema>;

const DeleteUserPermissionsMutation = async (values: DeletePermissionType) => {
  const { id, featureId } = values;
  const endpoint = `/api/v2/omega/users/${id}/user-perms/features/${featureId}`;
  const response = await authedDelete(endpoint);
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

const DeleteCompanyPermissionsMutation = async (
  values: DeletePermissionType
) => {
  const { id, featureId } = values;
  const endpoint = `/api/v2/omega/companies/${id}/company-perms/features/${featureId}}`;
  const response = await authedDelete(endpoint);
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};
const DeletePermission = ({ permission, type, open, closeFn }: Props) => {
  const { id, displayName } = permission;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<{ permissionName: string }>({
    defaultValues: {
      permissionName: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        permissionName: yup
          .string()
          .required()
          .test(
            'confirm-delete',
            "Value doesn't match permission name.",
            (value) => {
              return value === displayName;
            }
          ),
      })
    ),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn:
      type === 'company'
        ? DeleteCompanyPermissionsMutation
        : DeleteUserPermissionsMutation,
    onSuccess: async () => {
      type === 'company'
        ? await queryClient.refetchQueries({
            queryKey: COMPANY_KEY(id),
            exact: true,
          })
        : await queryClient.refetchQueries({
            queryKey: USER_KEY(id),
            exact: true,
          });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    mutate(permission);
  };

  const headerComp = () => (
    <ModalHeader
      title="Delete Permission"
      icon="delete"
      subtitle={`Remove access to ${permission.displayName}`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        color="error"
        variant="contained"
        disabled={isLoading}
      >
        Delete
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Button
          size="large"
          color="warning"
          variant="outlined"
          sx={{
            textTransform: 'none',
            textAlign: 'initial',
            width: '450px',
          }}
        >
          <MaterialIcon
            name="warning"
            color={orange}
            size={50}
            style={{
              marginRight: '1rem',
            }}
          />
          Once you delete this permission, there is no going back. Please be
          certain.
        </Button>
        <Stack spacing={2} mt={2.5}>
          <Typography variant="subtitle2">
            Enter permission name to delete
          </Typography>
          <TextField
            control={control}
            name="permissionName"
            placeholder={permission.displayName}
            autoFocus
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default DeletePermission;
