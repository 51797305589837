import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button, Divider } from '@mui/material';
import PageHeader from 'components/PageHeader';
import { Error, Loading, Success } from 'components/State';
import { authedPost, handleAPIError } from 'utils/api';
import { useLoaderData, useParams, useRevalidator } from 'react-router-dom';
import { OmegaUserFullCommon } from '@greywing-maritime/frontend-library/dist/types/omegaUsers';
import moment from 'moment';
import CopyLink from 'components/CopyLink';
import { USERS_KEY, USER_KEY } from 'query/queries';
import MaterialIcon from 'components/MaterialIcon';
import { white } from 'lib/colors';

type UserApprovalProps = {
  type: 'approve' | 'reject';
  userId: number;
  shouldSendWelcomeEmail?: boolean;
};

type MagicLinkProps = {
  userId: number;
  shouldEmailUser: boolean;
};

const UserApprovalMutation = async (values: UserApprovalProps) => {
  const { userId, shouldSendWelcomeEmail, type } = values;
  const endpoint =
    type === 'approve'
      ? `/api/v2/omega/user-accounts/${userId}/approve`
      : `/api/v2/omega/user-accounts/${userId}/reject`;
  const response = await authedPost(
    endpoint,
    type === 'approve' && shouldSendWelcomeEmail
      ? { shouldSendWelcomeEmail }
      : {}
  );
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};

const SendMagicLinkMutation = async (values: MagicLinkProps) => {
  const { userId, shouldEmailUser } = values;
  const endpoint = `/api/v2/omega/user-accounts/${userId}/create-magic-link`;
  const response = await authedPost(endpoint, { shouldEmailUser });
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};
const Authentication = () => {
  const params = useParams();
  const {
    displayName,
    isApproved,
    magicLink: magicLinkText,
    magicLinkTokenExpiresAt,
  } = useLoaderData() as OmegaUserFullCommon;
  const userId = parseInt(params.id!);
  const revalidator = useRevalidator();
  const queryClient = useQueryClient();

  const userApproval = useMutation({
    mutationFn: UserApprovalMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: USER_KEY(userId),
        exact: true,
      });
      await queryClient.refetchQueries({
        queryKey: USERS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        userApproval.reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        userApproval.reset();
      }, 5000);
    },
  });

  const magicLink = useMutation({
    mutationFn: SendMagicLinkMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: USER_KEY(userId),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        magicLink.reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        magicLink.reset();
      }, 5000);
    },
  });

  const handleApprove = (shouldSendWelcomeEmail: boolean) => {
    userApproval.mutate({
      userId,
      type: 'approve',
      shouldSendWelcomeEmail,
    });
  };

  const handleReject = () => {
    userApproval.mutate({
      userId,
      type: 'reject',
    });
  };

  const handleMagicLink = (shouldEmailUser: boolean) => {
    magicLink.mutate({ userId, shouldEmailUser });
  };

  return (
    <Stack>
      <PageHeader
        title="Authentication"
        subtitle="Manager user authentication"
        icon="shield"
      />
      <Stack spacing={5}>
        <Stack spacing={2}>
          <Typography variant="button" sx={{ fontWeight: 'bold' }}>
            User Approval
          </Typography>
          <Typography variant="body2" mt={1}>
            Approve/Reject platform access to {displayName}.
          </Typography>
          <Stack direction="row" spacing={2}>
            {!isApproved ? (
              <>
                <Button
                  onClick={() => handleApprove(false)}
                  variant="contained"
                  disabled={userApproval.isLoading}
                  sx={{ width: '15rem' }}
                >
                  Approve
                  <MaterialIcon
                    name="how_to_reg"
                    color={white}
                    size={18}
                    style={{ marginLeft: '1rem' }}
                  />
                </Button>
                <Button
                  onClick={() => handleApprove(true)}
                  variant="contained"
                  disabled={userApproval.isLoading}
                  sx={{ width: '15rem' }}
                >
                  Approve and Email
                  <MaterialIcon
                    name="mail"
                    color={white}
                    size={18}
                    style={{ marginLeft: '1rem' }}
                  />
                </Button>
              </>
            ) : (
              <Button
                onClick={handleReject}
                variant="contained"
                disabled={userApproval.isLoading}
                color="error"
                sx={{ width: '15rem' }}
              >
                Reject
                <MaterialIcon
                  name="person_off"
                  color={white}
                  size={18}
                  style={{ marginLeft: '1rem' }}
                />
              </Button>
            )}
          </Stack>
        </Stack>

        <Divider />
        <Stack spacing={2}>
          <Typography variant="button" sx={{ fontWeight: 'bold' }}>
            Magic Link
          </Typography>
          <Typography variant="body2" mt={1}>
            Create magic link for {displayName}. The user will be able to login
            directly by clicking on the link without entering any password.
          </Typography>
          {magicLinkText && magicLinkTokenExpiresAt ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: '14rem' }}
                disableElevation
              >
                <Typography variant="button" fontSize={10}>
                  Expires on:{' '}
                  {moment(magicLinkTokenExpiresAt).format(
                    'MMM DD YYYY HH:mm:ss'
                  )}
                </Typography>
              </Button>
              <Typography variant="body2" fontSize={12}>
                Magic Link without Analytics (for admin use)
              </Typography>
              <CopyLink text={magicLinkText! + '&disable-analytics=true'} />
              <Typography variant="body2" fontSize={12}>
                Magic Link with Analytics (for sharing with user)
              </Typography>
              <CopyLink text={magicLinkText!} />
            </>
          ) : null}
          <Stack direction="row" spacing={2}>
            <Button
              onClick={() => handleMagicLink(false)}
              variant="contained"
              disabled={magicLink.isLoading}
              sx={{ width: '15rem' }}
            >
              Create Link
              <MaterialIcon
                name="add_link"
                color={white}
                size={22}
                style={{ marginLeft: '1rem' }}
              />
            </Button>
            <Button
              onClick={() => handleMagicLink(true)}
              variant="contained"
              disabled={magicLink.isLoading}
              sx={{ width: '15rem' }}
            >
              Create Link and Email
              <MaterialIcon
                name="mail"
                color={white}
                size={18}
                style={{ marginLeft: '1rem' }}
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {userApproval.isLoading || magicLink.isLoading ? <Loading /> : null}
      {userApproval.isSuccess || magicLink.isSuccess ? <Success /> : null}
      {userApproval.isError ? (
        <Error text={userApproval.error as Error} />
      ) : null}
      {magicLink.isError ? <Error text={magicLink.error as Error} /> : null}
    </Stack>
  );
};

export default Authentication;
