import React, { memo } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  TextField,
  AutocompleteProps,
  createFilterOptions,
} from '@mui/material';
import { useController, FieldValues, FieldPathByValue } from 'react-hook-form';

type Option = { inputValue?: string; label: string };

function AutocompleteWithAdd<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  name,
  control,
  options,
  ...props
}: Omit<
  AutocompleteProps<
    Option,
    boolean | undefined,
    boolean | undefined,
    true | undefined
  >,
  'renderInput'
> & {
  name: TPath;
  control: any;
  options: Option[];
}) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name,
    control,
  });

  const filter = createFilterOptions<Option>();

  return (
    <MuiAutocomplete
      {...props}
      {...field}
      ref={field.ref}
      value={field.value}
      onChange={(_, newValue) => {
        if (typeof newValue === 'string') {
          field.onChange(newValue);
        } else if (newValue) {
          // Create a new value from the user input
          if ('inputValue' in newValue) field.onChange(newValue.inputValue);
          else if ('label' in newValue) field.onChange(newValue.label);
        }
      }}
      options={options}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.label
        );
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option: Option) => (
        <li {...props}>{option.label}</li>
      )}
      renderInput={(params) => (
        <TextField {...params} error={invalid} helperText={error?.message} />
      )}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
}

export default memo(AutocompleteWithAdd);
