import React from 'react';
import Modal from 'components/Modal';
import * as yup from 'yup';
import { EmailSchema } from '../../validations';
import { authedPost, handleAPIError } from 'utils/api';
import { useParams, useRevalidator } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CONVERSATION_KEY } from 'query/queries';
import ModalHeader from 'components/ModalHeader';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { TextField, LexicalEditorField } from 'components/Forms';
import Attachment from 'components/Attachment';
import { black, white } from 'lib/colors';
import { AssistedCrewChangeEmail } from '../../types';
import moment from 'moment';
import { filterOptionalParams } from 'utils/helpers';
import { ChaserEmailTemplate } from '../../email-templates';
import MaterialIcon from 'components/MaterialIcon';
import { VisuallyHiddenInput } from 'styles/common';

type Props = {
  email: AssistedCrewChangeEmail;
  open: boolean;
  closeFn: () => void;
};

type ChaserEmailType = yup.InferType<typeof EmailSchema>;

const ChaserEmailMutation = async (values: ChaserEmailType) => {
  const { aceId, attachments, ...rest } = values;
  const endpoint = `/api/v2/omega/assisted-crew-changes/emails/${aceId}/send-chaser`;
  const response = await authedPost(endpoint, {
    ...rest,
    ...filterOptionalParams({ attachments }),
  });
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};

const ChaserEmail = ({ email, open, closeFn }: Props) => {
  const params = useParams();
  const convoId = parseInt(params.convoId!);
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const { id: aceId, subject, body, fromEmail, sentOn } = email;

  const { handleSubmit, control, watch, setValue, getValues } =
    useForm<ChaserEmailType>({
      defaultValues: {
        aceId: aceId,
        subject: subject.startsWith('Re: ') ? subject : `Re: ${subject}`,
        body: body.trim().length
          ? ChaserEmailTemplate +
            `<blockquote class="editor-quote ltr" dir="ltr"><p class="editor-paragraph" dir="ltr"><span>${
              sentOn
                ? `On ${moment(sentOn).format('ddd, MMM D, YYYY [at] h:mmA')}`
                : ''
            } Greywing &lt;${fromEmail}&gt; wrote:</span></p><br/>${body}</blockquote>`
          : ChaserEmailTemplate,
      },
      resolver: yupResolver(EmailSchema),
    });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: ChaserEmailMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: CONVERSATION_KEY(convoId),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: ChaserEmailType) => {
    mutate(values);
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const name = e.target.files[0].name;
      const contentType = e.target.files[0].type;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        const f = reader.result;
        if (typeof f === 'string') {
          const content = f.split(',')[1];
          const attachment = {
            name,
            contentType,
            content,
          };
          const attachments = getValues('attachments');
          if (attachments) {
            if (!attachments.some((a) => a.content === content))
              setValue('attachments', [...attachments, attachment]);
          } else setValue('attachments', [attachment]);
        }
      };
    }
  };

  const handleRemoveFile = (index: number) => {
    const attachments = getValues('attachments');
    if (attachments) {
      attachments.splice(index, 1);
      setValue('attachments', attachments);
    }
  };

  const headerComp = () => (
    <ModalHeader
      title="Send Chaser Email"
      icon="mail"
      subtitle="Compose a chaser email for the assisted crew change"
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Send
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
      width={800}
      height={700}
    >
      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Subject</Typography>
          <TextField
            control={control}
            name="subject"
            placeholder="Email Subject"
            size="small"
            autoFocus
          />
          <Typography variant="subtitle2">Body</Typography>
          <LexicalEditorField
            control={control}
            name="body"
            isUserEditedEmail
            sx={{ '#lexical-toolbar': { top: '-20px' } }}
          />
          <Typography variant="subtitle2">Attachments</Typography>
          <Button
            component="label"
            variant="contained"
            startIcon={<MaterialIcon name="cloud_upload" color={white} />}
            sx={{ width: 'fit-content' }}
          >
            Upload file
            <VisuallyHiddenInput
              // to allow re-uploading the same file
              key={watch('attachments')?.length}
              type="file"
              accept=".jpg, .jpeg, .png, .pdf, .xlsx"
              onChange={handleFileInput}
            />
          </Button>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {watch('attachments')?.map((o, key) => (
              <Stack key={key} position="relative">
                <Attachment attachment={o} disableDownload />
                <IconButton
                  onClick={() => handleRemoveFile(key)}
                  sx={{ position: 'absolute', top: '-12px', right: '-15px' }}
                >
                  <MaterialIcon name="cancel" color={black} />
                </IconButton>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </form>
    </Modal>
  );
};

export default ChaserEmail;
