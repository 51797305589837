import React, { memo } from 'react';
import { styled, Stack } from '@mui/material';
import { selectSidebar } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { useIsLightMode } from 'hooks';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import MainSidebar from './components/MainSidebar';
import SecondarySidebar from './components/SecondarySidebar';

const Wrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  @media (max-width: ${BREAK_POINT_XS}) {
    display: none;
  }
`;

export const Sidebar = () => {
  const { show, expanded, title, items } = useSelector(selectSidebar);
  const isLightMode = useIsLightMode();
  return (
    <Wrapper>
      <MainSidebar
        isSecondarySidebarVisible={show}
        isSecondarySidebarExpanded={expanded}
        isLightMode={isLightMode}
      />
      <SecondarySidebar
        title={title}
        show={show}
        expanded={expanded}
        isLightMode={isLightMode}
        items={items}
      />
    </Wrapper>
  );
};

export default memo(Sidebar);
