import { CompanyCommon } from '@greywing-maritime/frontend-library/dist/types/companies';
import { ListFeaturesResp } from '@greywing-maritime/frontend-library/dist/types/features';
import { OmegaUserFullCommon } from '@greywing-maritime/frontend-library/dist/types/omegaUsers';
import { CompanyLoader } from 'pages/company/loader';
import { FeaturesLoader } from 'pages/features/loader';
import { UserLoader } from 'pages/users/loader';
import { LoaderFunctionArgs } from 'react-router-dom';

export const CompanyPermissionsLoader = async (props: LoaderFunctionArgs) => {
  const company = await CompanyLoader(props);
  const { features } = (await FeaturesLoader()) as ListFeaturesResp;
  return features.map((f) => {
    const feature = (company as CompanyCommon).companyPerms.find(
      (p) => f.id === p.featureId
    );
    if (feature)
      return {
        ...feature,
        displayName: f?.displayName || '',
      };
    return {
      featureId: f.id,
      displayName: f?.displayName || '',
    };
  });
};

export const UserPermissionsLoader = async (props: LoaderFunctionArgs) => {
  const user = await UserLoader(props);
  const { features } = (await FeaturesLoader()) as ListFeaturesResp;
  return features.map((f) => {
    const feature = (user as OmegaUserFullCommon).perms[f.id];
    return {
      ...feature,
      featureId: f.id,
      displayName: f?.displayName || '',
    };
  });
};
