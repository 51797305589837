import queryClient from 'query';
import {
  portAgencyCostsQuery,
  companyPortAgencyCostsQuery,
} from 'query/queries';
import { LoaderFunctionArgs } from 'react-router-dom';

export const PortAgencyCostsLoader = async () => {
  const query = portAgencyCostsQuery;
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export const CompanyPortAgencyCostsLoader = async (
  props: LoaderFunctionArgs
) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('Company not found', { status: 404 });
  }
  const query = companyPortAgencyCostsQuery(parseInt(id));
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
