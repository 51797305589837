import React from 'react';
import Modal from 'components/Modal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { authedPost, handleAPIError } from 'utils/api';
import { AddFeatureSchema } from '../validation';
import { AccessControl } from '../types';
import ModalHeader from 'components/ModalHeader';
import { TextField, Select, JsonEditorField } from 'components/Forms';
import { useRevalidator } from 'react-router-dom';
import { FEATURES_KEY } from 'query/queries';
import { filterOptionalParams, parseNullableJson } from 'utils/helpers';

type AddFeatureType = yup.InferType<typeof AddFeatureSchema>;

type Props = {
  open: boolean;
  closeFn: () => void;
};

const AddFeatureMutation = async (values: AddFeatureType) => {
  const { displayName, isPublic, config } = values;
  const endpoint = `/api/v2/omega/features`;
  const response = await authedPost(endpoint, {
    displayName,
    isPublic: isPublic === 'Public',
    ...filterOptionalParams({ config: parseNullableJson(config) }),
  });
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

const AddCompany = ({ open, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<AddFeatureType>({
    defaultValues: {
      displayName: '',
      isPublic: 'Private',
      config: '{}',
    },
    resolver: yupResolver(AddFeatureSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: AddFeatureMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: FEATURES_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: AddFeatureType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Add Feature"
      icon="add_circle"
      subtitle="Add new feature by providing feature details"
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Add
      </Button>
    </Stack>
  );

  return (
    <Modal
      width={800}
      height={700}
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Display Name</Typography>
          <TextField
            control={control}
            name="displayName"
            placeholder="Greywing"
            size="small"
            autoFocus
          />
          <Typography variant="subtitle2">Access Control</Typography>
          <Select
            control={control}
            name="isPublic"
            size="small"
            options={AccessControl.map((o) => ({
              value: o,
              label: o,
            }))}
          />
          <Typography variant="subtitle2">Config</Typography>
          <JsonEditorField control={control} name="config" />
        </Stack>
      </form>
    </Modal>
  );
};

export default AddCompany;
