import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  LexicalEditor,
  KEY_TAB_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  INDENT_CONTENT_COMMAND,
  COMMAND_PRIORITY_EDITOR,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isParentElementRTL, $wrapNodes } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
  insertList,
} from '@lexical/list';
import { createPortal } from 'react-dom';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from '@lexical/rich-text';

import { gray70, white } from 'lib/colors';
import { styled } from '@mui/material';
import MaterialIcon from 'components/MaterialIcon';

const LowPriority = 1;

const supportedBlockTypes = new Set([
  'paragraph',
  'quote',
  'code',
  'h1',
  'h2',
  'ul',
  'ol',
]);

const blockTypeToBlockName = {
  code: 'Code Block',
  h1: 'Large Heading',
  h2: 'Small Heading',
  h3: 'Heading',
  h4: 'Heading',
  h5: 'Heading',
  ol: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
  ul: 'Bulleted List',
};

const Toolbar = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1px;
  background: ${white};
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  gap: 2px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
`;

const Button = styled('button')<{ active?: boolean }>`
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 4px 8px;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
  &:hover {
    background-color: rgba(223, 232, 250, 0.3);
  }
  background-color: ${({ active }) =>
    active ? 'rgba(223, 232, 250, 0.3)' : 'none'};
  path {
    opacity: ${({ active }) => (active ? '1' : '0.7')};
  }
`;

const BlockText = styled('span')`
  font-family: HK Grotesk, Roboto;
  text-align: center;
  font-weight: 500;
`;

const Divider = styled('div')`
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
`;

const Text = styled('span')`
  display: flex;
  line-height: 20px;
  width: 70px;
  height: 20px;
  justify-content: center;
  vertical-align: middle;
  font-family: HK Grotesk, Roboto;
  font-weight: 500;
  font-size: 14px;
  color: #777;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Dropdown = styled('div')`
  z-index: 1500;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: ${white};
  gap: 8px;
  padding: 8px;
`;

const DropDownButton = styled('button')`
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 12px;
  cursor: pointer;
  line-height: 15px;
  font-size: 15px;
  background-color: ${white};
  border-radius: 8px;
  border: 0;
  min-width: 200px;
  opacity: 0.8;
  path {
    opacity: 0.8;
  }
  svg {
    width: 20px;
  }
  &:hover {
    background-color: #eee;
    opacity: 1;
    path {
      opacity: 1;
    }
  }
`;

const BlockIcon = (blockType: BlockType) => {
  switch (blockType) {
    case 'paragraph':
      return 'view_headline';
    case 'quote':
      return 'format_quote';
    case 'ul':
      return 'format_list_bulleted';
    case 'ol':
      return 'format_list_numbered';
    default:
      return 'view_headline';
  }
};

type BlockType = keyof typeof blockTypeToBlockName;

type BlockOptionsDropdownListProps = {
  editor: LexicalEditor;
  blockType: BlockType;
  toolbarRef: MutableRefObject<HTMLDivElement | null>;
  setShowBlockOptionsDropDown: (value: boolean) => void;
};

const BlockOptionsDropdownList = ({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
}: BlockOptionsDropdownListProps) => {
  const dropDownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left + 4}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event: MouseEvent) => {
        const target = event.target as Node;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <Dropdown ref={dropDownRef}>
      <DropDownButton onClick={formatParagraph}>
        <MaterialIcon name="view_headline" size={18} color={gray70} />
        <BlockText>Normal</BlockText>
      </DropDownButton>
      <DropDownButton onClick={formatLargeHeading}>
        H1
        <BlockText>Large Heading</BlockText>
      </DropDownButton>
      <DropDownButton onClick={formatSmallHeading}>
        H2
        <BlockText>Small Heading</BlockText>
      </DropDownButton>
      <DropDownButton onClick={formatBulletList}>
        <MaterialIcon name="format_list_bulleted" size={18} color={gray70} />
        <BlockText>Bullet List</BlockText>
      </DropDownButton>
      <DropDownButton onClick={formatNumberedList}>
        <MaterialIcon name="format_list_numbered" size={18} color={gray70} />
        <BlockText>Numbered List</BlockText>
      </DropDownButton>
      <DropDownButton onClick={formatQuote}>
        <MaterialIcon name="format_quote" size={18} color={gray70} />
        <BlockText>Quote</BlockText>
      </DropDownButton>
    </Dropdown>
  );
};

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [, setIsRTL] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type as BlockType);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsCode(selection.hasFormat('code'));
      setIsRTL($isParentElementRTL(selection));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        KEY_TAB_COMMAND,
        (payload) => {
          const event: KeyboardEvent = payload;
          event.preventDefault();
          return editor.dispatchCommand(
            event.shiftKey ? OUTDENT_CONTENT_COMMAND : INDENT_CONTENT_COMMAND,
            undefined
          );
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        INSERT_UNORDERED_LIST_COMMAND,
        () => {
          insertList(editor, 'bullet');
          return true;
        },
        LowPriority
      ),
      editor.registerCommand(
        INSERT_ORDERED_LIST_COMMAND,
        () => {
          insertList(editor, 'number');
          return true;
        },
        LowPriority
      ),
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const undo = () => editor.dispatchCommand(UNDO_COMMAND, undefined);
  const redo = () => editor.dispatchCommand(REDO_COMMAND, undefined);
  const handleBlockOptionsDropDown = () =>
    setShowBlockOptionsDropDown(!showBlockOptionsDropDown);

  const bold = () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
  const italic = () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
  const underline = () =>
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
  const strikethrough = () =>
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
  const code = () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');

  const leftAlign = () =>
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
  const centerAlign = () =>
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
  const rightAlign = () =>
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
  const alignJustify = () =>
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');

  return (
    <Toolbar ref={toolbarRef}>
      <Button disabled={!canUndo} onClick={undo} aria-label="Undo">
        <MaterialIcon name="undo" size={18} color={gray70} />
      </Button>
      <Button disabled={!canRedo} onClick={redo} aria-label="Redo">
        <MaterialIcon name="redo" size={18} color={gray70} />
      </Button>
      <Divider />
      {supportedBlockTypes.has(blockType) && (
        <>
          <Button
            style={{ width: '120px' }}
            onClick={(e) => {
              e.preventDefault();
              handleBlockOptionsDropDown();
            }}
            aria-label="Formatting Options"
          >
            {blockType === 'h1' ? 'H1' : null}
            {blockType === 'h2' ? 'H2' : null}
            <MaterialIcon
              name={BlockIcon(blockType)}
              size={18}
              color={gray70}
            />
            <Text>{blockTypeToBlockName[blockType]}</Text>
            <MaterialIcon name="expand_more" size={18} color={gray70} />
          </Button>
          {showBlockOptionsDropDown &&
            createPortal(
              <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
              />,
              document.body
            )}
          <Divider />
        </>
      )}
      <Button
        type="button"
        onClick={bold}
        active={isBold}
        aria-label="Format Bold"
      >
        <MaterialIcon name="format_bold" size={18} color={gray70} />
      </Button>
      <Button
        type="button"
        onClick={italic}
        active={isItalic}
        aria-label="Format Italics"
      >
        <MaterialIcon name="format_italic" size={18} color={gray70} />
      </Button>
      <Button
        type="button"
        onClick={underline}
        active={isUnderline}
        aria-label="Format Underline"
      >
        <MaterialIcon name="format_underlined" size={18} color={gray70} />
      </Button>
      <Button
        type="button"
        onClick={strikethrough}
        active={isStrikethrough}
        aria-label="Format Strikethrough"
      >
        <MaterialIcon name="format_strikethrough" size={18} color={gray70} />
      </Button>
      <Button
        type="button"
        onClick={code}
        active={isCode}
        aria-label="Insert Code"
      >
        <MaterialIcon name="code" size={18} color={gray70} />
      </Button>
      <Divider />
      <Button type="button" onClick={leftAlign} aria-label="Left Align">
        <MaterialIcon name="format_align_left" size={18} color={gray70} />
      </Button>
      <Button type="button" onClick={centerAlign} aria-label="Center Align">
        <MaterialIcon name="format_align_center" size={18} color={gray70} />
      </Button>
      <Button type="button" onClick={rightAlign} aria-label="Right Align">
        <MaterialIcon name="format_align_right" size={18} color={gray70} />
      </Button>
      <Button type="button" onClick={alignJustify} aria-label="Justify Align">
        <MaterialIcon name="format_align_justify" size={18} color={gray70} />
      </Button>
    </Toolbar>
  );
}
