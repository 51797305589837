import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { authedPatch, handleAPIError } from 'utils/api';
import { EditPortAgentSchema } from '../validation';
import Modal from 'components/Modal';
import { TextField, Switch, JsonEditorField } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { useRevalidator } from 'react-router-dom';
import { PORT_AGENTS_KEY } from 'query/queries';
import { OmegaPortAgentCommon } from '@greywing-maritime/frontend-library/dist/types/omegaPortAgents';
import { filterOptionalParams, parseNullableJson } from 'utils/helpers';

type Props = {
  portAgent: OmegaPortAgentCommon;
  open: boolean;
  closeFn: () => void;
};

type UpdatePortAgentType = yup.InferType<typeof EditPortAgentSchema>;

const UpdatePortAgentMutation = async (values: UpdatePortAgentType) => {
  const { id, meta, ...rest } = values;
  const endpoint = `/api/v2/omega/port-agents/` + id;
  const response = await authedPatch(
    endpoint,
    filterOptionalParams({
      ...rest,
      meta: parseNullableJson(meta),
    })
  );
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};
const EditPortAgent = ({ portAgent, open, closeFn }: Props) => {
  const {
    id,
    countryIso,
    portLocode,
    region,
    agency,
    public: isPublic,
    email,
    phone,
    phoneAfterHours,
    meta,
  } = portAgent;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<UpdatePortAgentType>({
    defaultValues: {
      id,
      countryIso,
      portLocode,
      region,
      agency,
      public: isPublic,
      email,
      phone,
      phoneAfterHours,
      meta: meta ? JSON.stringify(meta) : '{}',
    },
    resolver: yupResolver(EditPortAgentSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UpdatePortAgentMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: PORT_AGENTS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: UpdatePortAgentType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Edit Port Agent"
      icon="edit"
      subtitle={`Update parameters for ${email}`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Update
      </Button>
    </Stack>
  );

  return (
    <Modal
      width={800}
      height={700}
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Switch control={control} name="public" label={'Is Public'} />
          <Typography variant="subtitle2">Country ISO</Typography>
          <TextField
            control={control}
            name="countryIso"
            placeholder="SG"
            size="small"
          />
          <Typography variant="subtitle2">Port Locode</Typography>
          <TextField
            control={control}
            name="portLocode"
            placeholder="SGSIN"
            size="small"
          />
          <Typography variant="subtitle2">Region</Typography>
          <TextField
            control={control}
            name="region"
            placeholder="Asia"
            size="small"
          />
          <Typography variant="subtitle2">Agency</Typography>
          <TextField
            control={control}
            name="agency"
            placeholder="Greywing"
            size="small"
          />
          <Typography variant="subtitle2">Email</Typography>
          <TextField
            control={control}
            name="email"
            placeholder="port-agent@greywing.com"
            size="small"
          />
          <Typography variant="subtitle2">Phone No.</Typography>
          <TextField
            control={control}
            name="phone"
            placeholder="(+)12 13 1234-1234"
            size="small"
          />
          <Typography variant="subtitle2">After Hours Phone No.</Typography>
          <TextField
            control={control}
            name="phoneAfterHours"
            placeholder="(+)12 13 1234-1234"
            size="small"
          />
          <Typography variant="subtitle2">Meta Info</Typography>
          <JsonEditorField control={control} name="meta" />
        </Stack>
      </form>
    </Modal>
  );
};

export default EditPortAgent;
