import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import queryClient from 'query';
import { AppWithDarkmode } from 'components/Theming';

// react query
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// redux
import { Provider } from 'react-redux';
import { store } from 'redux/configureStore';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const IS_LOCAL_DEV = process.env?.REACT_APP_LOCAL_DEV === 'true';

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppWithDarkmode />
        {IS_LOCAL_DEV && <ReactQueryDevtools />}
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
