import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { expandSidebar, clearSidebar } from 'redux/actions';
import { mailType } from '../types';
import { SidebarItemType } from 'components/Sidebar/types';

const sidebarItems = (
  type: (typeof mailType)[number],
  id?: number
): SidebarItemType[] => {
  const path = type === 'all' ? '/mail' : `/${type}/${id}/mail`;
  return [
    { name: 'Inbox', icon: 'mail', link: `${path}/inbox` },
    {
      name: 'Starred',
      icon: 'star',
      link: `${path}/starred`,
    },
    {
      name: 'Crew Changes',
      icon: 'directions_boat',
      link: `${path}/crew-changes`,
    },
  ];
};

type Props = {
  type: (typeof mailType)[number];
  children: React.ReactNode;
};

const Sidebar = ({ children, type }: Props) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const id = parseInt(params.id!);

  useEffect(() => {
    dispatch(
      expandSidebar({
        title: 'Proteus Email',
        items: sidebarItems(type, type === 'all' ? undefined : id),
      })
    );
    return () => {
      dispatch(clearSidebar());
    };
  }, [dispatch, id, type]);

  return <>{children}</>;
};

export default Sidebar;
