import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { expandSidebar, clearSidebar } from 'redux/actions';
import { SidebarItemType } from 'components/Sidebar/types';

const sidebarItems = (id: number): SidebarItemType[] => [
  { name: 'Details', icon: 'flag', link: `/company/${id}` },
  { name: 'Users', icon: 'groups', link: `/company/${id}/users` },
  {
    name: 'Permissions',
    icon: 'format_list_bulleted',
    link: `/company/${id}/permissions`,
  },
  {
    name: 'Proteus Email',
    icon: 'mail',
    link: `/company/${id}/mail/inbox`,
  },
  { name: 'Vessels', icon: 'directions_boat', link: `/company/${id}/vessels` },
  {
    name: 'Preferred Ports',
    icon: 'anchor',
    link: `/company/${id}/preferred-ports`,
  },
  {
    name: 'Port Agency Costs',
    icon: 'paid',
    link: `/company/${id}/port-agency-costs`,
  },
  {
    name: 'Travel Vendors',
    icon: 'store',
    link: `/company/${id}/travel-vendors`,
  },
  {
    name: 'Bunkering Estimates',
    icon: 'oil_barrel',
    link: `/company/${id}/bunkering-estimates`,
  },
];

const Sidebar = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const id = parseInt(params.id!);

  useEffect(() => {
    dispatch(expandSidebar({ title: 'Company', items: sidebarItems(id) }));
    return () => {
      dispatch(clearSidebar());
    };
  }, [dispatch, id]);

  return <>{children}</>;
};

export default Sidebar;
