import { phoneRegExp } from 'utils/regex';
import * as yup from 'yup';

export const EditPortAgentSchema = yup.object().shape({
  id: yup.number().required(),
  countryIso: yup
    .string()
    .nullable()
    .optional()
    .test('len', 'Must be exactly 2 characters', (val) => val?.length === 2),
  portLocode: yup
    .string()
    .nullable()
    .optional()
    .test('len', 'Must be exactly 5 characters', (val) => val?.length === 5),
  region: yup.string().nullable().optional(),
  agency: yup.string().nullable().optional(),
  public: yup.boolean().optional(),
  email: yup.string().email().optional(),
  phone: yup
    .string()
    .nullable()
    .optional()
    .matches(phoneRegExp, 'Phone number is not valid'),
  phoneAfterHours: yup
    .string()
    .nullable()
    .optional()
    .matches(phoneRegExp, 'Phone number is not valid'),
  meta: yup
    .string()
    .nullable()
    .optional()
    .test('is-valid-object', 'Invalid JSON string', (value) => {
      try {
        if (value) {
          if (value === '') return true;
          const parsedObject = JSON.parse(value);
          return typeof parsedObject === 'object' && parsedObject !== null;
        }
      } catch (error) {
        return false; // JSON parsing failed
      }
    }),
});
