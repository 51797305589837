import queryClient from 'query';
import { portRestrictionsQuery } from 'query/queries';

export const portRestrictionsLoader = async () => {
  const query = portRestrictionsQuery;
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
