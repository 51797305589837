import { RouteObject } from 'react-router';
import Settings from './Settings';

const routes: RouteObject[] = [
  {
    path: '/settings',
    element: <Settings />,
  },
];

export default routes;
