import React from 'react';
import { Avatar, Chip, Stack, Typography, styled } from '@mui/material';
import { blue, blue10, textGray, white } from 'lib/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatNumberDisplay } from 'utils/helpers';
import MaterialIcon, { MaterialIconType } from './MaterialIcon';

const StyledChip = styled(Chip)`
  background-color: ${blue10};
  color: ${white};
`;

const Wrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${white};
  position: sticky;
  top: 0;
  left: -2rem;
  z-index: 5;
  width: 100%;
  transform: translate(-2rem, -2rem);
  padding: 1rem 2rem;
  border-radius: 0 0 20px 20px;
  gap: 16px;
  box-shadow: rgba(57, 53, 236, 0.05) 0px 0px 12px;
`;

type Props = {
  title: string;
  icon?: MaterialIconType;
  subtitle?: string;
  chipText?: string;
  actions?: React.ReactNode;
};

const PageHeader = ({ title, icon, subtitle, chipText, actions }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    if (location.key === 'default') {
      navigate('/dashboard');
    } else {
      navigate(-1);
    }
  };

  return (
    <Wrapper>
      <Stack direction={'row'} spacing={2}>
        <Stack direction={'row'} spacing={2} alignItems="center">
          <MaterialIcon
            name="arrow_back_ios"
            color={textGray}
            onClick={goBack}
            style={{ cursor: 'pointer' }}
          />
          {icon ? (
            <Avatar sx={{ backgroundColor: blue, width: 50, height: 50 }}>
              <MaterialIcon name={icon} color={white} />
            </Avatar>
          ) : null}
        </Stack>
        <Stack justifyContent="center">
          <Stack direction={'row'} spacing={1} alignItems="center">
            <Typography variant="h6" fontWeight={600}>
              {title}
            </Typography>
            {chipText ? (
              <StyledChip
                label={chipText.replace(/\d+(\.\d+)?/g, (match) =>
                  formatNumberDisplay(parseFloat(match))
                )}
                size="small"
              />
            ) : null}
          </Stack>
          {subtitle ? (
            <Typography variant="subtitle2" fontWeight={300}>
              {subtitle}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
      <Stack direction={'row'} gap={1} flexWrap="wrap">
        {actions}
      </Stack>
    </Wrapper>
  );
};

export default PageHeader;
