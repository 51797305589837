import React, { useMemo, useState } from 'react';
import {
  GridColDef,
  GridRowSelectionModel,
  GridRowId,
} from '@mui/x-data-grid-pro';
import {
  Stack,
  styled,
  Button,
  Divider,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { capitalize, isEqual, sortBy } from 'lodash';
import Table from 'components/Table';
import {
  ACEConvoCostsRow,
  computeSelectedCost,
  getInitialSelection,
} from './helpers';
import { blue, red, white } from 'lib/colors';
import { formatCurrencyDisplay } from 'utils/helpers';
import {
  AgencyCostTypes,
  ExtractCostTypes,
  ExtractedCost,
} from '@greywing-maritime/greywing-copilot/dist/gpt/inbound-agent-email/types';
import {
  renderCurrency,
  renderEditCurrency,
} from 'components/Table/RenderCurrency';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams, useRevalidator } from 'react-router-dom';
import { CONVERSATION_KEY } from 'query/queries';
import { Error, Success } from 'components/State';
import { LoadingButton } from '@mui/lab';
import { UpdateParsedResultsMutation } from '../ParsedResults';
import { AssistedCrewChangeEmailParsedResults } from '@greywing-maritime/frontend-library/dist/types/acc';
import MaterialIcon from 'components/MaterialIcon';

const Title = styled(Stack)`
  box-sizing: border-box;
  width: 35px;
  border-radius: 0px 5px 5px 0px;
  background-color: #6f79f3;
  color: ${white};
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -4px;
  z-index: 1;
`;

const summaryColumns: GridColDef[] = [
  {
    field: 'appliesTo',
    headerName: 'Category (Onsigner / Offsigner / Both)',
    valueGetter: (params) => capitalize(params.row.appliesTo),
    width: 300,
  },
  {
    field: 'cost',
    headerName: 'Per Person Cost',
    renderCell: ({ value, row }) => formatCurrencyDisplay(value, row.currency),
    width: 150,
  },
];

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    editable: true,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 250,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.values(AgencyCostTypes),
  },
  {
    field: 'appliesTo',
    headerName: 'Applies To',
    width: 100,
    valueGetter: (params) => capitalize(params.row.appliesTo),
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.values(ExtractCostTypes),
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 100,
    editable: true,
    renderCell: renderCurrency,
    renderEditCell: renderEditCurrency,
  },
  {
    field: 'itemType',
    headerName: 'Item type',
    width: 150,
    editable: true,
  },
  {
    field: 'costPerPerson',
    headerName: 'Cost Per Person',
    width: 150,
    renderCell: ({ value, row }) => formatCurrencyDisplay(value, row.currency),
    editable: true,
    type: 'number',
  },
  {
    field: 'perItemCost',
    headerName: 'Cost Per Item',
    width: 160,
    renderCell: ({ value, row }) =>
      `${formatCurrencyDisplay(value, row.currency)} ${row.itemType ?? ''}`,
    editable: true,
    type: 'number',
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 200,
    editable: true,
  },
  {
    field: 'whatFor',
    headerName: 'What For',
    width: 160,
    editable: true,
  },
];

const defaultCostRow: ExtractedCost = {
  costPerPerson: 0,
  currency: 'USD',
  name: '',
  whatFor: '',
  type: AgencyCostTypes.OTHER,
  appliesTo: ExtractCostTypes.BOTH,
  notes: '',
  perItemCost: 0,
  itemType: 'Per person',
};

type EditToolbarProps = {
  setRows: React.Dispatch<React.SetStateAction<ACEConvoCostsRow[]>>;
  saveFn: () => void;
  resetFn: () => void;
  isLoading: boolean;
  isEdited: boolean;
  editable: boolean;
};

const EditToolbar = (props: EditToolbarProps) => {
  const { setRows, saveFn, resetFn, isLoading, isEdited, editable } = props;

  const handleAdd = () => {
    setRows((oldRows) => [
      ...oldRows,
      { ...defaultCostRow, id: oldRows.length + 1 },
    ]);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p="4px"
        overflow="auto"
      >
        <Typography variant="button" fontWeight="bold" ml={2}>
          Extracted Costs
        </Typography>
        {editable ? (
          <Stack direction="row" spacing="5px">
            {isEdited ? (
              <Tooltip title="Clear Changes" placement="top" arrow>
                <Button
                  variant="outlined"
                  startIcon={<MaterialIcon name="close" color={blue} />}
                  onClick={resetFn}
                  disabled={isLoading}
                >
                  Clear
                </Button>
              </Tooltip>
            ) : null}
            <Tooltip title="Add new costs" placement="top" arrow>
              <Button
                variant="outlined"
                startIcon={<MaterialIcon name="add" color={blue} />}
                onClick={handleAdd}
                disabled={isLoading}
              >
                Add
              </Button>
            </Tooltip>
            {isEdited ? (
              <Tooltip title="Save costs" placement="top" arrow>
                <LoadingButton
                  variant="outlined"
                  startIcon={<MaterialIcon name="save" color={blue} />}
                  onClick={saveFn}
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              </Tooltip>
            ) : null}
          </Stack>
        ) : null}
      </Stack>
      <Divider />
    </>
  );
};

type Props = {
  parsedResults: AssistedCrewChangeEmailParsedResults;
  aceIdforUpdate?: number;
};

const CostsTables = ({ parsedResults, aceIdforUpdate }: Props) => {
  const costs = parsedResults.appliedCosts?.appliedAgencyCosts;
  const initialCosts: ACEConvoCostsRow[] = useMemo(() => {
    return costs
      ? sortBy(costs, ['type', 'name']).map((o, index) => ({
          id: index,
          ...o,
        }))
      : [];
  }, []); // eslint-disable-line
  const [originalCosts, setOriginalCosts] =
    useState<ACEConvoCostsRow[]>(initialCosts);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    getInitialSelection(originalCosts)
  );

  const isEdited = useMemo(() => {
    return !isEqual(initialCosts, originalCosts);
  }, [initialCosts, originalCosts]);

  const summaryCosts = computeSelectedCost(originalCosts, selectionModel);

  const handleDelete = (id: GridRowId) => () => {
    setOriginalCosts(originalCosts.filter((c) => c.id !== id));
  };

  const handleUpdate = (newRow: ACEConvoCostsRow) => {
    setOriginalCosts(
      originalCosts.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const handleReset = () => setOriginalCosts(initialCosts);

  const actions: GridColDef = {
    field: 'actions',
    type: 'actions',
    headerName: '',
    width: 100,
    getActions: ({ id }) => {
      return [
        <Tooltip title="Delete Row" placement="top" arrow>
          <IconButton onClick={handleDelete(id)}>
            <MaterialIcon name="delete" color={red} />
          </IconButton>
        </Tooltip>,
      ];
    },
  };

  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const params = useParams();
  const convoId = parseInt(params.convoId!);

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UpdateParsedResultsMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: CONVERSATION_KEY(convoId),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    if (originalCosts.length && aceIdforUpdate) {
      mutate({
        ...parsedResults,
        appliedCosts: {
          costCalculation: parsedResults.appliedCosts?.costCalculation || null,
          appliedAgencyCosts: originalCosts.map(({ id, ...rest }) => rest),
        },
        aceIdforUpdate,
      });
    }
  };

  return (
    <>
      <Stack direction="row" flex={1} maxWidth="100%">
        <Title>Merged Costs</Title>
        <Table
          height="100%"
          width="calc(100% - 35px)"
          columns={summaryColumns}
          rows={summaryCosts}
          toolbar={null}
          sx={{ minHeight: '180px' }}
          noRowsText="No Costs available."
          nonClickable
          hideFooter
          autoHeight
          disableScrollRestoration
        />
      </Stack>
      <Table
        height="100%"
        columns={aceIdforUpdate ? [...columns, actions] : columns}
        rows={originalCosts}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(values) => setSelectionModel(values)}
        processRowUpdate={handleUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {
            setRows: setOriginalCosts,
            saveFn: onSubmit,
            resetFn: handleReset,
            isEdited,
            isLoading,
            editable: !!aceIdforUpdate,
          },
        }}
        noRowsText="No Costs available."
        disableRowSelectionOnClick
        checkboxSelection
        hideFooter
        autoHeight
        disableScrollRestoration
      />
      {isSuccess ? <Success /> : null}
      {isError ? <Error text={error as Error} /> : null}
    </>
  );
};

export default CostsTables;
