import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../types';
import { SidebarItemType } from 'components/Sidebar/types';

type SidebarProps = {
  show: boolean;
  expanded: boolean;
  title: string;
  items: SidebarItemType[];
};

export const initialSidebar: SidebarProps = {
  show: false,
  expanded: true,
  title: '',
  items: [],
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: initialSidebar,
  reducers: {
    expandSidebar: (
      state,
      action: PayloadAction<Pick<SidebarProps, 'title' | 'items'>>
    ) => {
      return {
        title: action.payload.title,
        items: action.payload.items,
        show: true,
        expanded: state.expanded,
      };
    },
    clearSidebar: (state) => {
      return { ...initialSidebar, expanded: state.expanded };
    },
    toggleSidebar: (state) => {
      return {
        ...state,
        expanded: !state.expanded,
      };
    },
  },
});

/* ----- selectors -----*/

export const selectSidebar = ({ sidebar }: RootState) => sidebar;

/* ----- actions -----*/

export const { expandSidebar, clearSidebar, toggleSidebar } =
  sidebarSlice.actions;

/* ----- reducer -----*/

export default sidebarSlice.reducer;
