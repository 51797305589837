import { styled } from '@mui/material';
import { gray20, gray50, purple } from 'lib/colors';
import { Attachment as EmailAttachment } from '@greywing-maritime/frontend-library/dist/types/acc';
import { base64ToFile } from 'utils/helpers';
import MaterialIcon from 'components/MaterialIcon';

const ImageWrapper = styled('img')`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;

const FileWrapper = styled('a')<{ isDisabled?: boolean }>`
  display: flex;
  text-decoration: none;
  column-gap: 0.5rem;
  color: ${({ isDisabled }) => (isDisabled ? gray50 : purple)};
  border: 1px solid ${({ isDisabled }) => (isDisabled ? gray50 : purple)};
  border-radius: 4px;
  padding: 0px 0.6rem;
  height: 40px;
  max-width: 160px;
  align-items: center;
  * {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }

  label {
    white-space: nowrap;
    display: block;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }

  svg {
    font-size: 1.5rem;
    color: ${purple};
  }

  ${({ isDisabled }) => (isDisabled ? `background: ${gray20};` : '')}
`;

function Attachment({
  attachment,
  disableDownload,
}: {
  attachment: EmailAttachment;
  disableDownload?: boolean;
}) {
  const { name, content, contentType } = attachment;
  const base64 = content.toString();
  const file = base64ToFile(base64, contentType, name);
  const dataUrl = `data:${contentType};base64,${base64}`;
  const params = disableDownload
    ? {}
    : { href: URL.createObjectURL(file), download: name };

  if (contentType.startsWith('image/')) {
    return (
      <FileWrapper {...params}>
        <ImageWrapper src={dataUrl} alt={name} />
        <label>{name}</label>
      </FileWrapper>
    );
  }

  if (contentType === 'application/pdf') {
    return (
      <FileWrapper {...params}>
        <MaterialIcon name="picture_as_pdf" color={purple} />
        <label title={name}>{name}</label>
      </FileWrapper>
    );
  }

  if (
    contentType.includes(
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
  ) {
    return (
      <FileWrapper {...params}>
        <MaterialIcon name="disabled_by_default" color={purple} />
        <label title={name}>{name}</label>
      </FileWrapper>
    );
  }
  return (
    <FileWrapper isDisabled>
      <label title={name}>{name}</label>
    </FileWrapper>
  );
}

export default Attachment;
