import { useLoaderData } from 'react-router-dom';
import {
  AmadeusOfficeDetails,
  AtpiFlightsEffectiveConfig,
  DuffelFlightsEffectiveConfig,
  GetEffectiveConfigsResp,
} from '@greywing-maritime/frontend-library/dist/types/omegaFlightsConfig';
import PageHeader from 'components/PageHeader';
import { Chip, Stack } from '@mui/material';
import Table from 'components/Table';
import { useCallback, useMemo } from 'react';
import {
  DataGridProProps,
  GridColDef,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { CodeCard } from 'components/Table/Cards';

type TravelVendor = {
  vendorName: string;
  enabled: boolean;
  meta:
    | AtpiFlightsEffectiveConfig
    | AmadeusOfficeDetails
    | DuffelFlightsEffectiveConfig
    | null;
};

const columns: GridColDef[] = [
  {
    field: 'vendorName',
    headerName: 'Vendor',
    flex: 1,
  },
  {
    field: 'enabled',
    headerName: '',
    flex: 1,
    renderCell: ({ value }) => (
      <Chip
        label={value ? 'Enabled' : 'Disabled'}
        color={value ? 'primary' : 'error'}
      />
    ),
  },
];

const ALL_VENDORS = [
  'ATPI',
  'NDC',
  'Global GDS',
  'Kyvernitis',
  'Travelcue',
  'GMT',
  'Antaeus',
  'Riya',
];

function TravelVendors() {
  const { allowedVendors, atpi, general } =
    useLoaderData() as GetEffectiveConfigsResp;
  const apiRef = useGridApiRef();
  const travelVendors = useMemo(() => {
    return ALL_VENDORS.map((vendor) => {
      const obj: TravelVendor = {
        vendorName: vendor,
        enabled: false,
        meta: null,
      };
      if (allowedVendors.includes(vendor)) {
        obj.enabled = true;
        switch (vendor) {
          case 'ATPI':
            obj.meta = atpi;
            break;
          case 'NDC':
            obj.meta = general?.duffel || null;
            break;
          default:
            // amadeus, kyvernitis, travelcue
            obj.meta =
              general?.amadeus?.officeDetails.find(
                (details) => details.officeName === vendor
              ) || null;
            break;
        }
      }
      return obj;
    });
  }, [allowedVendors, atpi, general]);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(
    ({ row: { meta } }) => (
      <CodeCard>
        <pre>
          {meta && Object.keys(meta).length
            ? JSON.stringify(meta, null, 2)
            : 'No Meta Info available.'}
        </pre>
      </CodeCard>
    ),
    []
  );

  return (
    <Stack height="100%">
      <PageHeader
        title="Travel Vendors"
        subtitle="View enabled travel vendors"
        chipText={allowedVendors.length + ' vendors'}
        icon="store"
      />

      <Table
        apiRef={apiRef}
        height="100%"
        rows={travelVendors}
        getRowId={(row) => row.vendorName}
        columns={columns}
        noRowsText="No travel vendors found"
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        onRowClick={(params) => {
          apiRef.current.toggleDetailPanel(params.id);
        }}
      />
    </Stack>
  );
}

export default TravelVendors;
