import Users from './Users';
import Authentication from './pages/Authentication';
import { UserPermissionsLoader } from 'pages/permissions/loader';
import { UserLoader, UsersLoader } from './loader';
import Vessels from 'pages/vessels';
import { UserVesselsLoader } from 'pages/vessels/loader';
import Sidebar from './components/Sidebar';
import UserPermissions from 'pages/permissions/UserPermissions';
import { RouteObject } from 'react-router';

const routesArr: RouteObject[] = [
  {
    path: '/users',
    element: <Users type="user" />,
    loader: UsersLoader,
  },
  {
    path: '/users/:id/vessels',
    element: <Vessels type="user" />,
    loader: UserVesselsLoader,
  },
  {
    path: '/users/:id/permissions',
    element: <UserPermissions />,
    loader: UserPermissionsLoader,
  },
  {
    path: '/users/:id/authentication',
    element: <Authentication />,
    loader: UserLoader,
  },
];

const routes = routesArr.map((route, index) => ({
  ...route,
  element: index === 0 ? route.element : <Sidebar>{route.element}</Sidebar>,
}));

export default routes;
