import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import OpenAI from 'openai';
import { CompletionCreateParams } from 'openai/resources/chat/completions';
import initFlightSearcher, {
  SeaGptFlightSearcher,
} from '@greywing-maritime/seagpt-flight-search';
import {
  LlmClientError,
  LlmErrorTypes,
} from '@greywing-maritime/seagpt-flight-search/llm';
import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';
import { getAuthToken } from '@greywing-maritime/frontend-library/dist/utils/auth';
import { TextField } from 'components/Forms';
import { useForm } from 'react-hook-form';
import { useFlightStore } from '../../flights/quickfly';
import { FlightResult } from '../../flights/quickfly/types';
import { headers } from 'utils/api';

enum GptErrorTypes {
  BAD_REQUEST = 'BAD_REQUEST',
  AUTH_ERROR = 'AUTH_ERROR',
  RATE_LIMIT = 'RATE_LIMIT',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNKNOWN = 'UNKNOWN',
}

export type GptClientError = GptErrorTypes | undefined;

const FormBody = styled('form')`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  h1,
  h2,
  p {
    margin: 0;
  }
`;

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  max-height: 300px;
  overflow: auto;
`;

const Message = styled(`div`)`
  padding: 0.5rem;
  p[data-role='user'] {
    text-align: left;
  }

  p[data-role='proteus'] {
    margin-bottom: 0.5rem;
    text-align: right;
  }
`;

const FlightCard = styled('div')`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  p {
    background: white;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
`;

function AskLLM() {
  const { newFlightResult, history } = useFlightStore();
  const flightSearcher = useRef<SeaGptFlightSearcher | null>(null);

  const askLlm = async (
    model: any,
    messages: CompletionCreateParams.CompletionCreateParamsNonStreaming['messages'],
    temperature?: number
  ): Promise<[string, LlmClientError]> => {
    try {
      const resp = await fetch(`${getAPIUrl()}/seagpt-service/api/v1/llm/ask`, {
        method: 'post',
        headers: headers(getAuthToken()?.token || ''),
        body: JSON.stringify({
          messages,
          modelName: 'gpt-3.5-turbo-0613',
          temperature,
        }),
      });
      const content = await resp.json();
      return content?.message
        ? [content.message, undefined]
        : ['', LlmErrorTypes.UNKNOWN];
    } catch (err) {
      if (err instanceof OpenAI.APIError) {
        // https://github.com/openai/openai-node/tree/v4
        switch (err.status) {
          case 400:
            return ['', LlmErrorTypes.BAD_REQUEST];
          case 401:
          case 403:
            return ['', LlmErrorTypes.AUTH_ERROR];
          case 429:
            return ['', LlmErrorTypes.RATE_LIMIT];
          case undefined:
            return ['', LlmErrorTypes.CONNECTION_ERROR];
          default:
            return ['', LlmErrorTypes.INTERNAL_SERVER_ERROR];
        }
      } else {
        // log errors
        return ['', LlmErrorTypes.UNKNOWN];
      }
    }
  };

  useEffect(() => {
    if (flightSearcher.current) return;
    try {
      initFlightSearcher(
        { askLlm },
        'https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.8.0/sql-wasm.wasm'
      )
        .then((fs) => {
          flightSearcher.current = fs;
          if (newFlightResult?.flights?.length) {
            flightSearcher.current.addFlights(newFlightResult.flights);
          }
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.error('Failed here...', error);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (flightSearcher.current) {
      if (newFlightResult?.flights?.length) {
        console.log('Adding new flights');
        try {
          flightSearcher.current.addFlights(newFlightResult.flights);
        } catch (error) {
          console.error('Error adding flights that already exist', error);
        }
      }
    }
  }, [newFlightResult]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm<{
    message: string;
  }>({
    defaultValues: {
      message: '',
    },
  });
  const onSubmit = async ({ message }: { message: string }) => {
    reset();
    useFlightStore.getState().addToHistory({
      role: 'user',
      message,
    });
    const searchedFlightResponse:
      | {
          flights: FlightResult[];
          explanation?: string;
        }
      | undefined = await flightSearcher.current?.searchFlights(
      message,
      10,
      false,
      true
    );
    console.log('Result is', searchedFlightResponse);
    useFlightStore.getState().addToHistory({
      role: 'proteus',
      message: searchedFlightResponse?.explanation || 'Fetched',
      flights: searchedFlightResponse?.flights,
    });
  };
  return (
    <FormBody onSubmit={handleSubmit(onSubmit)}>
      <p>Chat and identify the most suitable flight for you!</p>
      <Body>
        {history.map((o, index) => (
          <Message key={index}>
            <p data-role={o.role}>{o.role}:</p>
            <p data-role={o.role}>{o.message}</p>
            {!!o.flights?.length && (
              <FlightCard>
                {o.flights.map((p) => (
                  <p key={p.uid} data-role={o.role} id="flights">
                    <strong>{p.source}</strong>
                    <br />
                    {p.price.currency} {p.price.amount}
                    <br />
                    {p.flightNumbers.join(' -> ')}
                    <br />
                    {p.type.type} - {p.cabinClass}
                    <br />
                    {moment(p.departure.time).format('DD/MM/YYYY HH:mm')} (
                    {/* @ts-ignore */}
                    {p.totalFlightTime?.toFixed(1)} hour)
                  </p>
                ))}
              </FlightCard>
            )}
          </Message>
        ))}
      </Body>
      <TextField size="small" control={control} name="message" />
      <LoadingButton variant="contained" loading={isSubmitting} type="submit">
        Send
      </LoadingButton>
    </FormBody>
  );
}

export default AskLLM;
