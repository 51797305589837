import { IconButton, Stack, Tooltip } from '@mui/material';
import PageHeader from 'components/PageHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { mailType } from '../types';
import { OmegaListAssistedCrewChangeConvosResp } from '@greywing-maritime/frontend-library/dist/types/omegaAcc';
import Table from 'components/Table';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import RenderAvatar from 'components/Table/RenderAvatarV2';
import RenderEmailCount from '../components/Table/RenderEmailCount';
import { blue, blue10, green, orange, red, yellow10 } from 'lib/colors';
import { StyledChip } from 'styles/common';
import Stats from '../components/Stats';
import MaterialIcon from 'components/MaterialIcon';

const RenderPort = (props: GridRenderCellParams<any, string>) => {
  const { value } = props;
  return <StyledChip label={value} size="small" />;
};

export const columns: GridColDef[] = [
  {
    field: 'action',
    headerName: '',
    width: 120,
    valueGetter: (params) => ({
      actionRequired: params.row.numEmailsReceived === 0,
      resultHeld: params.row.latestOnHold,
      hasRestrictions:
        !!params.row.latestParsedResultsAmongSuccessfulEmails
          ?.parsedRestrictions,
      hasCosts:
        !!params.row.latestParsedResultsAmongSuccessfulEmails?.appliedCosts
          ?.appliedAgencyCosts &&
        typeof params.row.latestParsedResultsAmongSuccessfulEmails?.appliedCosts
          ?.appliedAgencyCosts !== 'string',
    }),
    renderCell: (params) => {
      const {
        value: { actionRequired, hasRestrictions, hasCosts, resultHeld },
      } = params;
      return (
        <Stack display="flex" flexDirection="row" gap={1}>
          {resultHeld ? (
            <Tooltip title="Review Results" arrow placement="top">
              <MaterialIcon name="search" color={red} />
            </Tooltip>
          ) : null}
          {actionRequired ? (
            <Tooltip title="Action Required !" arrow placement="top">
              <MaterialIcon name="warning" color={orange} />
            </Tooltip>
          ) : null}
          {hasRestrictions ? (
            <Tooltip title="Parsed Restrictions" arrow placement="top">
              <MaterialIcon name="description" color={blue} />
            </Tooltip>
          ) : null}
          {hasCosts ? (
            <Tooltip title="Parsed Costs" arrow placement="top">
              <MaterialIcon name="request_quote" color={green} />
            </Tooltip>
          ) : null}
        </Stack>
      );
    },
  },
  {
    field: 'creator',
    headerName: 'Executed between',
    width: 250,
    renderCell: RenderAvatar,
    valueGetter: (params) => params.row.acc.creator,
  },
  {
    field: 'icon',
    headerName: '',
    width: 50,
    resizable: false,
    disableColumnMenu: true,
    renderCell: () => {
      return <MaterialIcon name="send" color={blue10} size={18} />;
    },
  },
  {
    field: 'agent',
    headerName: '',
    width: 250,
    renderCell: RenderAvatar,
    valueGetter: (params) => ({
      email: params.row.agent.email,
      name: params.row.agent.displayName,
    }),
  },
  {
    field: 'portOfCall',
    headerName: 'Port of Call',
    width: 160,
    renderCell: RenderPort,
    valueGetter: (params) =>
      params.row.acc.portName && params.row.acc.portLocode
        ? `${params.row.acc.portName} - ${params.row.acc.portLocode}`
        : `${params.row.acc.portName || params.row.acc.portLocode || '-'}`,
  },
  {
    field: 'vesselName',
    headerName: 'Vessel',
    width: 205,
    valueGetter: (params) =>
      params.row.acc.flotillaVessel?.name || params.row.acc.vesselName || '-',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 140,
    valueGetter: (params) => moment(params.row.updatedAt).format('MMM D, YYYY'),
  },
  {
    field: 'emailCount',
    headerName: 'Sent/received',
    width: 120,
    renderCell: RenderEmailCount,
    valueGetter: (params) => ({
      numEmailsSent: params.row.numEmailsSent,
      numEmailsReceived: params.row.numEmailsReceived,
    }),
  },
];

const Inbox = ({ type }: { type: (typeof mailType)[number] }) => {
  const { data } = useLoaderData() as OmegaListAssistedCrewChangeConvosResp;
  const navigate = useNavigate();
  const pageParams = useParams();

  const [starredMail, setStarredMail] = useState<number[]>(() => {
    const localStorageData = localStorage.getItem('starredMail') || '[]';
    return JSON.parse(localStorageData);
  });

  useEffect(() => {
    const updateStarredMail = () => {
      const localStorageData = localStorage.getItem('starredMail') || '[]';
      setStarredMail(JSON.parse(localStorageData));
    };
    window.addEventListener('storage', updateStarredMail);
    return () => {
      window.removeEventListener('storage', updateStarredMail);
    };
  }, []);

  const starFn = (convoId: number) => {
    const index = starredMail.indexOf(convoId);
    index !== -1 ? starredMail.splice(index, 1) : starredMail.push(convoId);
    localStorage.setItem('starredMail', JSON.stringify(starredMail));
    window.dispatchEvent(new Event('storage'));
  };

  const actions: GridColDef[] = [
    {
      field: 'star',
      headerName: '',
      width: 80,
      renderCell: useCallback(
        (props: GridRenderCellParams<any, number>) => {
          const { value: convoId } = props;
          const isStarred = starredMail.indexOf(convoId!) !== -1;
          return (
            convoId && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  starFn(convoId);
                }}
              >
                <MaterialIcon
                  name="star"
                  color={isStarred ? orange : yellow10}
                />
              </IconButton>
            )
          );
        },
        [starredMail] //eslint-disable-line
      ),
      valueGetter: (params) => params.row.id,
    },
  ];

  const getNavUrl = (id: number) =>
    type === 'all'
      ? `/mail/inbox/${id}`
      : `/${type}/${parseInt(pageParams.id!)}/mail/inbox/${id}`;

  const handleRowClick = (params: GridRowParams) => {
    const {
      row: { id },
    } = params;
    navigate(getNavUrl(id));
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Inbox"
        subtitle="A list of assisted crew change emails"
        chipText={data.length + ' emails'}
        icon="inbox"
      />

      <Stats data={data} />

      <Table
        height="100%"
        rows={data}
        columns={[...columns, ...actions]}
        onRowClick={handleRowClick}
        openInNewTab={getNavUrl}
        noRowsText="No assisted crew change emails found"
      />
    </Stack>
  );
};

export default Inbox;
