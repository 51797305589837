import React, { useState } from 'react';
import Modal from 'components/Modal';
import { AssistedCrewChangeEmail, ParsedStatus } from '../../types';
import moment from 'moment';
import {
  Avatar,
  Button,
  Chip,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import {
  black,
  blue,
  codeText,
  gray20,
  green,
  white,
  yellowOrange,
} from 'lib/colors';
import Attachment from 'components/Attachment';
import RenderMarkdown from 'components/RenderMarkdown';
import { capitalize } from 'lodash';
import MaterialIcon from 'components/MaterialIcon';

type Props = {
  email: AssistedCrewChangeEmail;
  open: boolean;
  closeFn: () => void;
};

const StyledChip = styled(Chip)`
  border-radius: 5px;
  font-size: 12px;
  height: 25px;
  color: ${white};
`;

const Wrapper = styled(Stack)`
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: ${gray20};
  max-height: 400px;
  overflow-y: auto;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 1rem;
  min-height: 40px;
  button {
    text-transform: none;
    background-color: ${black};
    color: gray;
    font-weight: 600;
    min-height: 40px;
  }
  button:first-child {
    border-top-left-radius: 4px;
  }
  button:last-child {
    border-top-right-radius: 4px;
  }

  .Mui-selected {
    color: ${codeText} !important;
  }
  .MuiTabs-indicator {
    background-color: ${codeText};
    height: 4px;
  }
`;

const Email = ({ email, open, closeFn }: Props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabSwitch = (event: React.SyntheticEvent, val: number) => {
    setCurrentTab(val);
  };

  const renderDate = () => (
    <Tooltip
      title={
        <Stack>
          <span>
            {'Created On: ' +
              moment(email.createdAt).format('h:mm a [on] DD MMM YYYY')}
          </span>
          <span>
            {'Updated On: ' +
              moment(email.updatedAt).format('h:mm a [on] DD MMM YYYY')}
          </span>
          <span>
            {email.sentOn
              ? 'Sent On: ' +
                moment(email.sentOn).format('h:mm a [on] DD MMM YYYY')
              : null}
          </span>
          <span>
            {email.deliveredOn
              ? 'Delivered On: ' +
                moment(email.deliveredOn).format('h:mm a [on] DD MMM YYYY')
              : null}
          </span>
        </Stack>
      }
      arrow
      placement="bottom"
    >
      <StyledChip
        label={`${moment(email.createdAt).format('MMM DD, YYYY, h:mm A')} 
          (${moment(email.createdAt).fromNow()})`}
        sx={{
          bgcolor: blue + 10,
          color: black,
          fontWeight: '500',
          borderLeft: `2px solid ${blue}`,
          borderRadius: '2px',
          cursor: 'pointer',
        }}
      />
    </Tooltip>
  );

  const headerComp = () => (
    <Stack direction={'row'} spacing={2}>
      <Avatar sx={{ backgroundColor: blue, width: 50, height: 50 }}>
        <MaterialIcon name="mail" color={white} />
      </Avatar>
      <Stack spacing={1}>
        <Typography variant="h6" fontWeight={600}>
          {email.subject}
        </Typography>
        <Stack direction="row" spacing={1}>
          {email.isOutbound ? (
            <StyledChip
              label="Outbound"
              sx={{
                bgcolor: yellowOrange,
              }}
            />
          ) : null}
          {email.isSeaGptAutomated ? (
            <StyledChip
              label="Proteus Automated"
              sx={{
                bgcolor: blue,
              }}
            />
          ) : null}
          <StyledChip
            label={'Status: ' + capitalize(email.status)}
            sx={{
              bgcolor: green,
            }}
          />
          <StyledChip
            label={'Parsed Status: ' + email.parsedStatus}
            sx={{
              bgcolor:
                ParsedStatus[email.parsedStatus as keyof typeof ParsedStatus]
                  ?.color,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );

  const footerComp = () => (
    <Button fullWidth variant="outlined" onClick={closeFn}>
      Close
    </Button>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      height={800}
      width={800}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="subtitle2" fontWeight="bold">
              {email.fromEmail}
            </Typography>
            <Typography variant="body2">
              to {Array.from(new Set([email.toEmail, ...email.cc])).join(', ')}
            </Typography>
          </Stack>
          {renderDate()}
        </Stack>
        <Wrapper>
          <Typography variant="subtitle2">
            {email.body.trim().length ? (
              <RenderMarkdown text={email.body} />
            ) : (
              'No content available.'
            )}
          </Typography>
        </Wrapper>
        {email.attachments.length ? (
          <Stack spacing={2}>
            <Typography variant="subtitle2" fontWeight="bold">
              Attachments
            </Typography>
            <Stack direction="row" gap={1} flexWrap="wrap">
              {email.attachments.map((o) => (
                <Attachment key={o.name} attachment={o} />
              ))}
            </Stack>
          </Stack>
        ) : null}
        {!!email.extractedAttachments.length && (
          <Stack>
            <Typography variant="subtitle2" fontWeight="bold">
              Extracted Attachments
            </Typography>
            <StyledTabs
              value={currentTab}
              onChange={handleTabSwitch}
              variant="scrollable"
              scrollButtons="auto"
            >
              {email.extractedAttachments.map((o) => (
                <Tab label={o.metaStr} />
              ))}
            </StyledTabs>
            <Wrapper>
              <RenderMarkdown
                text={email.extractedAttachments[currentTab].contentStr}
              />
            </Wrapper>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default Email;
