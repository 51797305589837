import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { ListRiskAreasResp } from '@greywing-maritime/frontend-library/dist/types/riskAreas';
import { Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import PageHeader from 'components/PageHeader';
import { blue, red } from 'lib/colors';
import Table from 'components/Table';
import { StyledChip } from 'styles/common';

const RenderExpiry = (props: GridRenderCellParams<any, string>) => {
  const { value } = props;
  return (
    <StyledChip
      label={!!value ? 'Expired' : 'Active'}
      sx={{ backgroundColor: !value ? blue : red }}
    />
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'displayName',
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'timezone',
    headerName: 'Timezone',
    width: 200,
  },
  {
    field: 'version',
    headerName: 'Last Updated',
    width: 150,
  },
  {
    field: 'expiredAt',
    headerName: 'Is Expired?',
    width: 150,
    renderCell: RenderExpiry,
  },
];

function RiskAreaPage() {
  const { riskAreas } = useLoaderData() as ListRiskAreasResp;

  return (
    <Stack height="100%">
      <PageHeader
        title="Risk Area"
        subtitle="View risk areas around the globe"
        chipText={riskAreas.length + ' areas'}
        icon="crisis_alert"
      />
      <Table
        height="100%"
        rows={riskAreas}
        columns={columns}
        noRowsText="No riskareas found"
        nonClickable
      />
    </Stack>
  );
}

export default RiskAreaPage;
