import { blueGray, gray90, green, orange, red, yellowOrange } from 'lib/colors';
import { AssistedCrewChangeConvoResp } from '@greywing-maritime/frontend-library/dist/types/acc';
import { MaterialIconType } from 'components/MaterialIcon';

export const mailType = ['all', 'company', 'users'] as const;

export const ParsedStatus: Record<
  string,
  { text: string; icon: MaterialIconType; color: string }
> = {
  PENDING: {
    text: 'No Response',
    icon: 'subdirectory_arrow_left',
    color: gray90,
  },
  FAILED_TO_PARSE_EMAIL: {
    text: 'Parsing Failed',
    icon: 'close',
    color: red,
  },
  PARSED_EMAIL_BUT_FAILED_TO_APPLY_COSTS: {
    text: 'Parsed Partially',
    icon: 'subdirectory_arrow_left',
    color: yellowOrange,
  },
  PARSED_EMAIL_AND_APPLIED_COSTS: {
    text: 'Parsed',
    icon: 'done',
    color: green,
  },
  MANUALLY_PARSED: { text: 'Parsed Manually', icon: 'handyman', color: orange },
  ERROR: { text: 'Error', icon: 'warning', color: red },
  'N/A': { text: 'Sent', icon: 'send', color: blueGray },
};

export type EmailCountType = {
  numEmailsSent: number;
  numEmailsReceived: number;
};

export type AssistedCrewChangeEmail = AssistedCrewChangeConvoResp['emails'][0];

export type AgentType = {
  agentId: number;
  displayName: string;
  email: string;
};

export type ParsedResultsModalType =
  | 'price-conditions'
  | 'cost-calculation'
  | 'parsed-restrictions';
