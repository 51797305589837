import React from 'react';
import { Stack } from '@mui/material';
import PageHeader from 'components/PageHeader';

const SeaGPTPage = () => {
  return (
    <Stack>
      <PageHeader
        title="Proteus"
        subtitle="LLM based virtual assistant"
        icon="memory"
      />
      <Stack sx={{ height: 600, width: '100%' }}>
        <div>Nothing here yet</div>
      </Stack>
    </Stack>
  );
};

export default SeaGPTPage;
