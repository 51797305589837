import { CurrencyOption } from 'utils/types';
import world_currency_symbols from './world_currency_symbols.json';

export const CURRENCIES_LIST: CurrencyOption[] = Object.values(
  JSON.parse(JSON.stringify(world_currency_symbols))
);

export const getCurrencyFromCode = (
  code?: string
): CurrencyOption | undefined => {
  return CURRENCIES_LIST.find(({ Code }) => Code === code);
};
