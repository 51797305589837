import { Stack, Typography, styled } from '@mui/material';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { white } from 'lib/colors';

export const sideBarTransitionSpeed = 0.3;
export const staggerSpeed = 20;
export const sidebarWidth = 350;
export const shrinkSidebarWidth = 80;

export const staggerItems = {
  initial: { opacity: 0, x: 50 },
  animate: (index: number) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 1.0,
      delay: index / staggerSpeed,
    },
  }),
  exit: { opacity: 0, transition: { duration: 0.4 } },
};

export const animateText = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 0.4 } },
  exit: { opacity: 0 },
};

export const SidebarWrapper = styled(motion.div)<{ bgcolor: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${({ bgcolor }) => bgcolor};
  overflow: hidden;
  position: relative;
  padding: 2rem 1rem;
  color: ${white};
  user-select: none;
  a,
  svg {
    color: ${white};
    text-decoration: none;
  }
`;

export const StyledNavLink = styled(NavLink)`
  height: 1.57rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem 0.9rem;
  border-radius: 0.2rem;
  font-size: 1rem;
  white-space: nowrap;
  :hover {
    background-color: #385dea60 !important;
  }
`;

export const FooterWrapper = styled(Stack)`
  position: absolute;
  backdrop-filter: blur(10px);
  width: 100%;
  bottom: 0;
  right: 0;
  height: 5rem;
  padding: 1rem;
  padding-top: 0;
  box-sizing: border-box;
  overflow: hidden;
`;

export const LongText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
