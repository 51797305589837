import queryClient from 'query';
import { companyVesselsQuery, userVesselsQuery } from 'query/queries';
import { LoaderFunctionArgs } from 'react-router-dom';
export const CompanyVesselsLoader = async (props: LoaderFunctionArgs) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('Company not found', { status: 404 });
  }
  const query = companyVesselsQuery(parseInt(id));
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export const UserVesselsLoader = async (props: LoaderFunctionArgs) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('User not found', { status: 404 });
  }
  const query = userVesselsQuery(parseInt(id));
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
