import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Stack, Button } from '@mui/material';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { authedPost, handleAPIError } from 'utils/api';
import { CmsJobCommon } from '@greywing-maritime/frontend-library/dist/types/cms';
import LottieAnimation from 'components/LottieAnimation';
import { CMS_JOBS_KEY } from 'query/queries';
import { useRevalidator } from 'react-router-dom';

type Props = {
  cmsJob: CmsJobCommon;
  open: boolean;
  closeFn: () => void;
};

const QueueJobMutation = async (values: { companyId: number }) => {
  const { companyId } = values;
  const endpoint = `/api/v2/omega/cms/jobs/company/${companyId}/queue`;
  const response = await authedPost(endpoint);
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};
const QueueCmsJob = ({ cmsJob, open, closeFn }: Props) => {
  const { companyId } = cmsJob;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: QueueJobMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: CMS_JOBS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    mutate({ companyId });
  };

  const headerComp = () => (
    <ModalHeader
      title="Queue Cms Job"
      icon="sync"
      subtitle={`Add cms job to the queue`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        disabled={isLoading}
      >
        Queue
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <LottieAnimation
        name="platform-loading-X5sgEHtKwe"
        height={200}
        width={200}
        loop={true}
      />
    </Modal>
  );
};

export default QueueCmsJob;
