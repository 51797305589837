import { blue10 } from 'lib/colors';

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
const getEmailInitials = (email: string) => {
  return email
    .split('@')[0]
    .split(/[^a-zA-Z]+/)
    .filter((part) => part.length > 0)
    .map((part) => part[0].toUpperCase())
    .join('');
};

export const stringAvatar = (name: string, type?: 'initials') => {
  return {
    sx: {
      bgcolor: type === 'initials' ? stringToColor(name) : blue10,
    },
    children: `${type === 'initials' ? getEmailInitials(name) : name}`,
  };
};
