export const greywingTheme = {
  typography: {
    fontFamily: 'HK Grotesk, Roboto',
  },
  palette: {
    primary: {
      light: '#7a8aff',
      main: '#385dea',
      dark: '#0034b7',
      contrastText: '#fff',
    },
    error: {
      light: '#FF8888',
      main: '#FF6B6B',
      dark: '#B24A4A',
      contrastText: '#fff',
    },
    secondary: {
      light: '#333333',
      main: '#000000',
      dark: '#000000',
      contrastText: '#fff',
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 380,
      sm: 600,
      md: 768,
      lg: 900,
      xl: 1080,
      xxl: 1200,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // to fix scroll issue in textarea
          '.MuiInputBase-inputMultiline': { padding: 'inherit' },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          letterSpacing: '0.5px',
          textTransform: 'none' as const,
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          letterSpacing: '0.5px',
          textTransform: 'none' as const,
          ':disabled': {
            backgroundColor: '#385dea',
          },
        },
        loadingIndicator: {
          color: '#fff',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          paddingLeft: '1.5rem',
        },
        columnHeaderCheckbox: {
          paddingLeft: 'initial',
        },
        cell: {
          paddingLeft: '1.5rem',
          '&:focus': {
            outline: 'none',
          },
        },
        cellCheckbox: {
          paddingLeft: 'initial',
        },
        row: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
  },
};

export const greywingLightTheme = {
  palette: {
    mode: 'light',
  },
};

export const greywingDarkTheme = {
  palette: {
    mode: 'dark',
    background: {
      default: '#1e1e1e',
    },
  },
};
