import { IconButton, Stack } from '@mui/material';
import PageHeader from 'components/PageHeader';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { mailType } from '../types';
import { OmegaListAssistedCrewChangeConvosResp } from '@greywing-maritime/frontend-library/dist/types/omegaAcc';
import Table from 'components/Table';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { orange } from 'lib/colors';
import { columns } from './Inbox';
import MaterialIcon from 'components/MaterialIcon';

const Starred = ({ type }: { type: (typeof mailType)[number] }) => {
  const { data } = useLoaderData() as OmegaListAssistedCrewChangeConvosResp;
  const navigate = useNavigate();
  const pageParams = useParams();

  const [starredMail, setStarredMail] = useState<number[]>(() => {
    const localStorageData = localStorage.getItem('starredMail') || '[]';
    return JSON.parse(localStorageData);
  });

  useEffect(() => {
    const updateStarredMail = () => {
      const localStorageData = localStorage.getItem('starredMail') || '[]';
      setStarredMail(JSON.parse(localStorageData));
    };
    window.addEventListener('storage', updateStarredMail);
    return () => {
      window.removeEventListener('storage', updateStarredMail);
    };
  }, []);

  const starredConversations = useMemo(() => {
    return data.filter((convo) => {
      const index = starredMail.indexOf(convo.id);
      return index !== -1;
    });
  }, [starredMail]); // eslint-disable-line

  const starFn = (convoId: number) => {
    const index = starredMail.indexOf(convoId);
    if (index !== -1) {
      starredMail.splice(index, 1);
      localStorage.setItem('starredMail', JSON.stringify(starredMail));
      window.dispatchEvent(new Event('storage'));
    }
  };

  const actions: GridColDef[] = [
    {
      field: 'star',
      headerName: '',
      width: 80,
      renderCell: useCallback(
        (props: GridRenderCellParams<any, number>) => {
          const { value: convoId } = props;
          return (
            convoId && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  starFn(convoId);
                }}
              >
                <MaterialIcon name="star" color={orange} />
              </IconButton>
            )
          );
        },
        [starredMail] //eslint-disable-line
      ),
      valueGetter: (params) => params.row.id,
    },
  ];

  const getNavUrl = (id: number) =>
    type === 'all'
      ? `/mail/inbox/${id}`
      : `/${type}/${parseInt(pageParams.id!)}/mail/inbox/${id}`;

  const handleRowClick = (params: GridRowParams) => {
    const {
      row: { id },
    } = params;
    navigate(getNavUrl(id));
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Starred"
        subtitle="A list of starred assisted crew change emails"
        chipText={starredConversations.length + ' emails'}
        icon="star"
      />

      <Table
        height="100%"
        rows={starredConversations}
        columns={[...columns, ...actions]}
        onRowClick={handleRowClick}
        openInNewTab={getNavUrl}
        noRowsText="No assisted crew change emails found"
      />
    </Stack>
  );
};

export default Starred;
