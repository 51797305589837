import * as yup from 'yup';

export const AddVesselSchema = yup.object().shape({
  userId: yup.number().positive().required(),
  imo: yup
    .number()
    .positive()
    .required()
    .test(
      'is-seven-digits',
      'IMO must be exactly of 7 digits',
      (value) => String(value).length === 7
    ),
});

export const AssignPICSchema = yup.object().shape({
  newPicId: yup.number().positive().required(),
  imo: yup
    .number()
    .positive()
    .required()
    .test(
      'is-seven-digits',
      'IMO must be exactly of 7 digits',
      (value) => String(value).length === 7
    ),
});
