import React from 'react';
import { Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { create } from 'zustand';
import moment from 'moment';
import PageHeader from 'components/PageHeader';
import { FlightStore, QuickFlyHistory, QuickFlyResult } from './types';
import Inputs from './Inputs';
import Table from 'components/Table';
import AskLLM from '../components/AskLLM';

const columns: GridColDef<QuickFlyResult>[] = [
  { field: 'id', headerName: 'ID', flex: 0.5 },
  { field: 'startCode', headerName: 'Dep', flex: 0.5 },
  { field: 'endCode', headerName: 'Arr', flex: 0.5 },
  {
    field: 'startDate',
    headerName: 'Date',
    flex: 1,
    renderCell: (params) => moment(params.value as string).format('DD/MM'),
  },
  { field: 'source', headerName: 'Source', flex: 1 },
  {
    field: 'timeTaken',
    headerName: 'Time Taken (ms)',
    flex: 1,
    renderCell: (params) =>
      Intl.NumberFormat('en-US').format(params.value as number),
  },
  { field: 'count', headerName: 'Flights Retrieved', flex: 1 },
  {
    field: 'cached',
    headerName: 'Cached?',
    flex: 1,
    renderCell: ({ row }) => (row.meta?.isCacheHit ? 'Yes' : 'No'),
  },
];

const QuickFlyTable = ({ data }: { data: QuickFlyResult[] }) => {
  return (
    <Table
      height={500}
      rows={data}
      columns={columns}
      noRowsText="No flights found"
    />
  );
};

export const useFlightStore = create<FlightStore>((set, get) => ({
  flights: [] as QuickFlyResult[],
  newFlightResult: null, // this is for the new flights that are being fetched
  updateFlights: (flights: QuickFlyResult) =>
    set({ flights: [...get().flights, flights], newFlightResult: flights }),
  history: [],
  addToHistory: (message: QuickFlyHistory) =>
    set({ history: [...get().history, message] }),
}));

const Quickfly = () => {
  const { flights } = useFlightStore();

  return (
    <Stack>
      <PageHeader
        title="Flights"
        subtitle="Search for flights around the globe"
        icon="flight"
      />

      <Stack marginBottom={2}>
        <Typography variant="h4" fontWeight={600} mb={4}>
          Flights
        </Typography>
        <Inputs />
        <Typography variant="h4" fontWeight={600} mb={4} mt={4}>
          Results
        </Typography>
        <QuickFlyTable
          data={flights.map((o, id) => ({ ...o, id })).reverse()}
        />
      </Stack>
      <Stack sx={{ height: 600, width: '100%' }}>
        <AskLLM />
      </Stack>
    </Stack>
  );
};

export default Quickfly;
