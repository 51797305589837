import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Stack, Button } from '@mui/material';
import Modal from 'components/Modal';
import { TextField } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { authedUpdate, handleAPIError } from 'utils/api';
import { UpdateVesselTagSchema } from '../validation';
import { VesselTagCommon } from '../types';
import { useRevalidator } from 'react-router-dom';
import { VESSEL_TAGS_KEY } from 'query/queries';
import { filterOptionalParams } from 'utils/helpers';

type Props = {
  tag: VesselTagCommon;
  open: boolean;
  closeFn: () => void;
};

type UpdateVesselTagType = yup.InferType<typeof UpdateVesselTagSchema>;

const UpdateVesselTagMutation = async (values: UpdateVesselTagType) => {
  const { id, preferredTmc } = values;
  const endpoint = `/api/v2/omega/vessels/tags/${id}/tag-rules`;
  const response = await authedUpdate(
    endpoint,
    filterOptionalParams({ preferredTmc })
  );
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};
const EditVesselTag = ({ tag, open, closeFn }: Props) => {
  const { id, displayName } = tag;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<UpdateVesselTagType>({
    defaultValues: {
      id: id,
      preferredTmc: tag?.tagRules?.preferredTmc ?? '',
    },
    resolver: yupResolver(UpdateVesselTagSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UpdateVesselTagMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: VESSEL_TAGS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: UpdateVesselTagType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Edit Vessel Tag"
      icon="edit"
      subtitle={`Update parameters for ${displayName} tag`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Update
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">
            Preferred Travel Management Company (TMC)
          </Typography>
          <TextField
            control={control}
            name="preferredTmc"
            placeholder="All"
            autoFocus
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default EditVesselTag;
