import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { ThemeMode, THEME_OPTIONS } from 'utils/types';

function getInitialTheme() {
  const localStorageTheme = localStorage.getItem('theme');
  // const systemTheme = (
  //   window.matchMedia('(prefers-color-scheme: light)').matches
  //     ? 'light'
  //     : 'dark'
  // ) as ThemeMode;

  if (localStorageTheme) {
    if (THEME_OPTIONS.includes(localStorageTheme as ThemeMode)) {
      const theme = localStorageTheme as ThemeMode;
      return { theme };
    }
    return { theme: 'dark' as ThemeMode };
  } else {
    localStorage.setItem('theme', 'light');
    return { theme: 'light' as ThemeMode };

    // TODO: use systemTheme when app is updated to dark theme
    // localStorage.setItem('theme', systemTheme as string);
    // return { theme: systemTheme as ThemeMode };
  }
}

export const initialTheme: {
  theme: ThemeMode;
} = getInitialTheme();

const themeSlice = createSlice({
  name: 'theme',
  initialState: initialTheme,
  reducers: {
    toggleTheme: (state) => {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newTheme);
      return { ...state, theme: newTheme };
    },
    setTheme: (state, action: PayloadAction<ThemeMode>) => ({
      ...state,
      theme: action.payload,
    }),
  },
});

/* ----- selectors -----*/

export const selectTheme = ({ theme }: RootState) => theme;

/* ----- actions -----*/

export const { toggleTheme, setTheme } = themeSlice.actions;

/* ----- reducer -----*/

export default themeSlice.reducer;
