import React from 'react';
import { IconButton } from '@mui/material';
import { toggleTheme } from 'redux/actions';
import { useAppDispatch, useIsLightMode } from 'hooks';
import { orange } from 'lib/colors';
import MaterialIcon from 'components/MaterialIcon';

const ToggleColorMode = () => {
  const isLightMode = useIsLightMode();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(toggleTheme());
  };

  return (
    <IconButton onClick={handleClick} color="inherit" sx={{ width: 40 }}>
      {!isLightMode ? (
        <MaterialIcon name="dark_mode" color={orange} />
      ) : (
        <MaterialIcon name="light_mode" color={orange} />
      )}
    </IconButton>
  );
};

export default ToggleColorMode;
