import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'components/Modal';
import { TextField } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { authedDelete, handleAPIError } from 'utils/api';
import { useParams, useRevalidator } from 'react-router-dom';
import { COMPANY_VESSELS_KEY, USER_VESSELS_KEY } from 'query/queries';
import MaterialIcon from 'components/MaterialIcon';
import { orange } from 'lib/colors';

type Props = {
  type: 'company' | 'user';
  imo: number;
  open: boolean;
  closeFn: () => void;
};

const DeleteVesselMutation = async (imo: number) => {
  const endpoint = `/api/v2/omega/flotilla-vessels`;
  const response = await authedDelete(endpoint, { flotillaVesselIds: [imo] });
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

const DeleteVessel = ({ type, imo, open, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const id = parseInt(params.id!);
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<{ imo: number | null }>({
    defaultValues: {
      imo: null,
    },
    resolver: yupResolver(
      yup.object().shape({
        imo: yup
          .number()
          .positive()
          .required()
          .test(
            'confirm-delete',
            "Value doesn't match vessel imo.",
            (value) => {
              return value === imo;
            }
          ),
      })
    ),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: DeleteVesselMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey:
          type === 'company' ? COMPANY_VESSELS_KEY(id) : USER_VESSELS_KEY(id),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    mutate(imo);
  };

  const headerComp = () => (
    <ModalHeader
      title="Delete Vessel"
      icon="delete"
      subtitle={`Remove vessel with IMO (${imo})`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        color="error"
        variant="contained"
        disabled={isLoading}
      >
        Delete
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Button
          size="large"
          color="warning"
          variant="outlined"
          sx={{
            textTransform: 'none',
            textAlign: 'initial',
            width: '450px',
          }}
        >
          <MaterialIcon
            name="warning"
            color={orange}
            size={50}
            style={{
              marginRight: '1rem',
            }}
          />
          Once you delete this vessel, there is no going back. Please be
          certain.
        </Button>
        <Stack spacing={2} mt={2.5}>
          <Typography variant="subtitle2">
            Enter vessel IMO to delete
          </Typography>
          <TextField
            type="number"
            control={control}
            name="imo"
            placeholder={imo.toString()}
            autoFocus
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default DeleteVessel;
