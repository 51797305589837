import countriesAlpha3 from './countries-alpha3.json';

export type CountryOption = {
  name: string;
  countryCode: string;
  countryCodeAlpha3: string;
  label?: string;
};

export const COUNTRIES_LIST: CountryOption[] = Object.values(
  JSON.parse(JSON.stringify(countriesAlpha3))
);

export const getCountryFromAlpha2 = (
  alpha2Code: string
): CountryOption | undefined => {
  return COUNTRIES_LIST.find(({ countryCode }) => countryCode === alpha2Code);
};

export const getCountryFromAlpha3 = (alpha3Code: string): CountryOption => {
  const countriesList = JSON.parse(JSON.stringify(countriesAlpha3));
  return countriesList[alpha3Code];
};

export const getAlpha2CodeFromCountry = (
  country: string | null | undefined
): string =>
  (country &&
    COUNTRIES_LIST.find(
      ({ name }) => name.toLowerCase() === country.toLowerCase()
    )?.countryCode) ||
  '';
