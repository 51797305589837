import { RouteObject } from 'react-router';
import SeaGPTPage from './SeaGPT';

const routes: RouteObject[] = [
  {
    path: '/proteus',
    element: <SeaGPTPage />,
  },
];

export default routes;
