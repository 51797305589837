import React, { memo } from 'react';
import { useController, FieldValues, FieldPathByValue } from 'react-hook-form';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';

function DatePicker<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  control,
  name,
  ...props
}: DatePickerProps<Date> & { name: TPath; control: any }) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name,
    control,
  });
  return (
    <MuiDatePicker
      {...props}
      {...field}
      inputRef={field.ref}
      value={field.value}
      slotProps={{
        textField: {
          size: 'small',
          error: invalid,
          helperText: error?.message,
        },
      }}
    />
  );
}

export default memo(DatePicker);
