import ModalHeader from 'components/ModalHeader';
import React from 'react';
import { AgentType } from '../../types';
import Modal from 'components/Modal';
import { Button, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { portAgentQuery } from 'query/queries';
import { GetPortAgentResp } from '@greywing-maritime/frontend-library/dist/types/omegaPortAgents';
import RenderObject from 'components/RenderObject';

type Props = {
  portAgent: AgentType;
  open: boolean;
  closeFn: () => void;
};

const PortAgentDetails = ({ portAgent, open, closeFn }: Props) => {
  const { agentId, displayName } = portAgent;
  const { isLoading, isError, error, data } = useQuery(portAgentQuery(agentId));
  const headerComp = () => (
    <ModalHeader
      title="Port Agent Details"
      icon="support_agent"
      subtitle={`Details for port agent - ${displayName}`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
    </Stack>
  );

  let portAgentDetails = null;
  if (data) portAgentDetails = (data as GetPortAgentResp).portAgent;

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      error={isError && error instanceof Error ? error.message : null}
    >
      {portAgentDetails ? (
        <Stack spacing={0.5}>{RenderObject(portAgentDetails)}</Stack>
      ) : null}
    </Modal>
  );
};

export default PortAgentDetails;
