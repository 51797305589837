import {
  CompanyCommon,
  ListCompaniesResp,
} from '@greywing-maritime/frontend-library/dist/types/companies';
import { ListUserAccountsResp } from '@greywing-maritime/frontend-library/dist/types/omegaUsers';
import { CompaniesLoader, CompanyLoader } from 'pages/company/loader';
import queryClient from 'query';
import { userQuery, usersQuery } from 'query/queries';
import { LoaderFunctionArgs } from 'react-router-dom';
import { UserType } from './types';

export const UsersLoader = async () => {
  const { companies } = (await CompaniesLoader()) as ListCompaniesResp;
  const query = usersQuery;
  const { users } = (queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))) as ListUserAccountsResp;
  return users.map((user) => ({
    ...user,
    companyName: companies.find((company) => company.id === user.companyId)
      ?.displayName,
  }));
};

export const CompanyUsersLoader = async (props: LoaderFunctionArgs) => {
  const company = (await CompanyLoader(props)) as CompanyCommon;
  const users = (await UsersLoader()) as UserType[];
  const usersList = company.users.map(
    (user) =>
      users.find((u) => u.id === user.id) ?? {
        id: user.id,
        email: user.email,
        displayName: '',
        createdAt: '',
        updatedAt: '',
        vesselIdsPic: [],
        companyId: company.id,
        companyName: company.displayName,
      }
  );
  return usersList;
};

export const UserLoader = async (props: LoaderFunctionArgs) => {
  const {
    params: { id },
  } = props;
  if (typeof id === 'undefined') {
    throw new Response('User not found', { status: 404 });
  }
  const query = await userQuery(parseInt(id));
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
