import React from 'react';
import { Divider, Stack } from '@mui/material';
import PageHeader from 'components/PageHeader';
import Appearance from './components/Appearance';
import LocalBackend from './components/LocalBackend';
import { isDevelopment, isStagingInstance } from 'lib/environments';

function Settings() {
  return (
    <Stack>
      <PageHeader
        title="Settings"
        subtitle="View and manage app settings"
        icon="settings"
      />
      <Stack spacing={3}>
        <Appearance />
        <Divider />
        {(isStagingInstance || isDevelopment) && <LocalBackend />}
      </Stack>
    </Stack>
  );
}

export default Settings;
