import React, { useState, useEffect, useRef } from 'react';
import {
  styled,
  Typography,
  Stack,
  Divider,
  Grid,
  Tooltip,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { sideBarItems, sideBarItemsAdmin } from '../SidebarItems';
import { blue10, blue40, blue50, blue70, white } from 'lib/colors';
import GWLogo from './GWLogo';
import {
  FooterWrapper,
  LongText,
  SidebarWrapper,
  StyledNavLink,
  animateText,
  shrinkSidebarWidth,
  sideBarTransitionSpeed,
  sidebarWidth,
  staggerItems,
} from './common';
import { StyledAvatar } from 'styles/common';
import { stringAvatar } from 'utils/avatar';
import LogoutButton from './LogoutButton';
import ToggleSidebar from './ToggleSidebar';
import MaterialIcon from 'components/MaterialIcon';

const SidebarItemsContainer = styled(Stack)`
  max-height: 55.6vh;
  display: flex;
  align-items: center;
  background-color: ${blue10};
  color: ${white};
  border-radius: 0.6rem;
  padding: 0 0.25rem;
  span:not(#material-icons) {
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: 500;
    letter-spacing: 2px;
  }
`;

const SidebarItemsWrapper = styled('ul')`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${blue70};
  border-radius: 0.6rem;
  padding: 0.25rem;
  row-gap: 0.25rem;
  min-height: 0px;
  a {
    border-radius: 0.6rem;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  overflow-x: hidden;
`;

const Footer = ({
  fullWidth,
  showLogout,
}: {
  fullWidth: boolean;
  showLogout: boolean;
}) => {
  return (
    <FooterWrapper spacing={2}>
      <Divider />
      <Grid container justifyContent="center" alignItems={'center'}>
        {fullWidth ? (
          <>
            <Grid item xs={2}>
              <StyledAvatar
                {...stringAvatar(
                  localStorage.getItem('icEmail') || 'User',
                  'initials'
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Stack ml={1}>
                <LongText variant="subtitle2">
                  {localStorage.getItem('icEmail')}
                </LongText>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <LogoutButton />
            </Grid>
          </>
        ) : null}
        {showLogout ? (
          <LogoutButton />
        ) : (
          <StyledAvatar
            {...stringAvatar(
              localStorage.getItem('icEmail') || 'User',
              'initials'
            )}
          />
        )}
      </Grid>
    </FooterWrapper>
  );
};

const MainSidebar = ({
  isSecondarySidebarVisible,
  isSecondarySidebarExpanded,
  isLightMode,
}: {
  isSecondarySidebarVisible: boolean;
  isSecondarySidebarExpanded: boolean;
  isLightMode: boolean;
}) => {
  const sidebarItemsRef = useRef<HTMLUListElement>(null);
  const [showScrollMore, setShowScrollMore] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState<number[]>([0, 0]);
  const expanded = !isSecondarySidebarVisible && isSecondarySidebarExpanded;

  useEffect(() => {
    const updateWindowSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateWindowSize);
    return () => window.removeEventListener('resize', updateWindowSize);
  }, []);

  useEffect(() => {
    if (sidebarItemsRef.current) {
      setShowScrollMore(
        sidebarItemsRef.current.scrollHeight >
          sidebarItemsRef.current.clientHeight
      );
    }
  }, [sidebarItemsRef, windowSize]);

  return (
    <SidebarWrapper
      bgcolor={isLightMode ? blue40 : '#2c2c2c'}
      animate={{
        width: expanded ? sidebarWidth : shrinkSidebarWidth,
      }}
      transition={{ duration: sideBarTransitionSpeed }}
    >
      <Stack
        direction="row"
        justifyContent={expanded ? 'space-between' : 'center'}
      >
        <AnimatePresence>
          {isSecondarySidebarExpanded || isSecondarySidebarVisible ? (
            <motion.div
              initial="initial"
              animate="animate"
              variants={animateText}
            >
              <GWLogo fullWidth={expanded} />
            </motion.div>
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          {!isSecondarySidebarVisible ? (
            <motion.div
              initial="initial"
              animate="animate"
              variants={animateText}
            >
              <ToggleSidebar />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Stack>

      <SidebarItemsContainer mt={4}>
        <SidebarItemsWrapper ref={sidebarItemsRef}>
          {sideBarItems
            .filter((o) => !o.hide)
            .map((item, index) => (
              <li key={index}>
                <Tooltip
                  title={!expanded ? item.name : ''}
                  arrow
                  placement="right"
                >
                  <StyledNavLink
                    to={item.link}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? blue50 : 'inherit',
                      padding: expanded ? '1rem' : '1rem 0.7rem',
                      height: '1.57rem',
                    })}
                  >
                    <MaterialIcon name={item.icon} color={white} size={20} />
                    <AnimatePresence>
                      {expanded ? (
                        <motion.div
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          custom={index}
                          variants={staggerItems}
                        >
                          <Typography variant="subtitle2">
                            {item.name}
                          </Typography>
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </StyledNavLink>
                </Tooltip>
              </li>
            ))}
        </SidebarItemsWrapper>

        {showScrollMore ? (
          <Stack mt={1} mb={expanded ? 1.5 : 1}>
            <AnimatePresence>
              {expanded ? (
                <motion.div
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={animateText}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={2}
                    height={13}
                  >
                    <MaterialIcon
                      name="keyboard_double_arrow_down"
                      color={white}
                      size={20}
                    />
                    <span>Scroll for more</span>
                  </Stack>
                </motion.div>
              ) : (
                <MaterialIcon
                  name="keyboard_double_arrow_down"
                  color={white}
                  size={20}
                />
              )}
            </AnimatePresence>
          </Stack>
        ) : null}
      </SidebarItemsContainer>

      <Stack mt={6}>
        {sideBarItemsAdmin
          .filter((o) => !o.hide)
          .map((item, index) => (
            <StyledNavLink
              key={index}
              to={item.link}
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? blue50 : 'inherit',
                };
              }}
            >
              <MaterialIcon name={item.icon} color={white} size={20} />
              <AnimatePresence>
                {expanded ? (
                  <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    custom={index}
                    variants={staggerItems}
                  >
                    <Typography variant="subtitle2">{item.name}</Typography>
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </StyledNavLink>
          ))}
      </Stack>

      <Footer fullWidth={expanded} showLogout={!isSecondarySidebarVisible} />
    </SidebarWrapper>
  );
};

export default MainSidebar;
