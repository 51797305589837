import React, { useState } from 'react';
import { Stack, Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CompanyCommon } from '@greywing-maritime/frontend-library/dist/types/companies';
import {
  ListUserAccountsResp,
  OmegaUserAccountCommon,
} from '@greywing-maritime/frontend-library/dist/types/omegaUsers';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { authedPost, handleAPIError } from 'utils/api';
import RenderUser from 'components/Table/RenderAvatar';
import Table from 'components/Table';
import { useRevalidator } from 'react-router-dom';
import { COMPANIES_KEY, COMPANY_KEY } from 'query/queries';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'displayName',
    headerName: 'Name',
    width: 300,
    renderCell: RenderUser,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
];

type AssignUsersMutationType = {
  userIds: number[];
  companyId: number;
};

export const AssignUsersMutation = async (values: AssignUsersMutationType) => {
  const endpoint = `/api/v2/omega/users/assign-to-company`;
  const response = await authedPost(endpoint, values);
  const data = { success: response.status === 201 };
  handleAPIError(response.status, data);
  return data;
};

type AssignUsersType = {
  users: OmegaUserAccountCommon[];
  company: CompanyCommon;
};

type Props = AssignUsersType & {
  open: boolean;
  closeFn: () => void;
};
const AssignUsers = ({ users, company, open, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const { users: allUsers } = queryClient.getQueryData([
    'users',
  ]) as ListUserAccountsResp;
  const [selectedUsers, setSelectedUsers] = useState<OmegaUserAccountCommon[]>(
    []
  );
  const revalidator = useRevalidator();

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: AssignUsersMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: COMPANY_KEY(company.id),
        exact: true,
      });
      await queryClient.refetchQueries({
        queryKey: COMPANIES_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = () => {
    mutate({
      userIds: selectedUsers.map((user) => user.id),
      companyId: company.id,
    });
  };

  const headerComp = () => (
    <ModalHeader
      title="Assign Existing Users"
      icon="person"
      subtitle={`Assign existing users on the platform to ${company.displayName}`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        disabled={isLoading || selectedUsers.length === 0}
      >
        Assign
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
      height={800}
      width={800}
    >
      <form>
        <Stack spacing={2}>
          <Table
            height={400}
            rows={allUsers.filter(
              (user) =>
                !company.users.some((companyUser) => companyUser.id === user.id)
            )}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(ids) =>
              setSelectedUsers(allUsers.filter((user) => ids.includes(user.id)))
            }
            noRowsText="No users found"
            disableScrollRestoration
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default AssignUsers;
