import React from 'react';
import { Overlay } from 'styles/common';
import LottieAnimation from 'components/LottieAnimation';

type Props = {
  color?: string;
  size?: string | number;
};

const Loading = ({ size = '12rem' }: Props) => {
  return (
    <Overlay>
      <LottieAnimation
        name="loading-Y3Pt36IpkJ"
        height={size}
        width={size}
        loop={true}
      />
    </Overlay>
  );
};

export default Loading;
