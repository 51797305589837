import React, { useEffect } from 'react';
import Modal from 'components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { authedPost, handleAPIError } from 'utils/api';
import ModalHeader from 'components/ModalHeader';
import { Select, TextField } from 'components/Forms';
import { AddVesselSchema } from '../validations';
import { useParams, useRevalidator } from 'react-router-dom';
import {
  COMPANY_VESSELS_KEY,
  USER_VESSELS_KEY,
  companyQuery,
} from 'query/queries';

type AddVesselType = {
  userId: number;
  imo?: number;
};

type Props = {
  type: 'company' | 'user';
  open: boolean;
  closeFn: () => void;
};

const AddVesselMutation = async (values: AddVesselType) => {
  const { userId, imo } = values;
  const endpoint = `/api/v2/omega/flotilla-vessels`;
  const response = await authedPost(endpoint, {
    userId,
    imos: [imo!.toString()],
  });
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

const AddVessel = ({ type, open, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const id = parseInt(params.id!);
  const isTypeCompany = type === 'company';
  const {
    isLoading: isCompanyLoading,
    isError: isCompanyError,
    error: companyError,
    data: company,
  } = useQuery({
    ...companyQuery(id),
    enabled: isTypeCompany,
  });
  const revalidator = useRevalidator();

  const {
    handleSubmit,
    control,
    reset: formReset,
  } = useForm<AddVesselType>({
    defaultValues: {
      userId: isTypeCompany ? 0 : id,
    },
    resolver: yupResolver(AddVesselSchema),
  });

  useEffect(() => {
    if (company) formReset({ userId: company.users?.[0].id ?? 0 });
  }, [company]); //eslint-disable-line

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: AddVesselMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: isTypeCompany
          ? COMPANY_VESSELS_KEY(id)
          : USER_VESSELS_KEY(id),
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: AddVesselType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Add Vessel"
      icon="add_circle"
      subtitle="Add new vessel by providing vessel IMO"
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Add
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading || isTypeCompany ? isCompanyLoading : false}
      success={isSuccess}
      error={
        (isError && error instanceof Error ? error.message : null) ||
        (isTypeCompany && isCompanyError && companyError instanceof Error
          ? companyError.message
          : null)
      }
    >
      <form>
        <Stack spacing={2}>
          {isTypeCompany && company ? (
            <>
              <Typography variant="subtitle2">User</Typography>
              <Select
                control={control}
                name="userId"
                size="small"
                options={company.users.map(({ id, email }) => ({
                  value: id,
                  label: email,
                }))}
              />
            </>
          ) : null}
          <Typography variant="subtitle2">IMO</Typography>
          <TextField
            type="number"
            control={control}
            name="imo"
            size="small"
            placeholder="1234567"
            autoFocus={isTypeCompany ? false : true}
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default AddVessel;
