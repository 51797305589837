import React from 'react';
import { GetStatsResp as StatsType } from '@greywing-maritime/frontend-library/dist/types/usageStats';
import { Card, Typography, Stack, styled } from '@mui/material';
import { blue } from 'lib/colors';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-width: 10rem;
  align-items: center;
  .MuiTypography-body2 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
  }
  .MuiTypography-h6 {
    font-size: 11px;
  }
  .MuiTypography-h4 {
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 700;
    color: ${blue};
  }
`;

const RenderMaxValue = (o: [string, number][]) => {
  let arr = o;
  if (o.length > 1) {
    arr = arr.filter(([key, _]) => key !== 'UNKNOWN' && key !== '');
  }
  const maxEntry = arr.reduce((a, b) => (a[1] > b[1] ? a : b));
  const [maxKey, maxValue] = maxEntry;
  return (
    <Typography
      variant="h6"
      fontWeight={700}
      letterSpacing={1}
      textTransform="uppercase"
    >
      {maxKey} ({maxValue})
    </Typography>
  );
};

const Stats = ({ data }: { data: StatsType }) => {
  const {
    crewChangePlansCreatedCount,
    flotillaVesselsCount,
    crewChangeEventCount,
  } = data;
  const getCrewCountByNationality = Object.entries(
    data?.getCrewCountByNationality || {}
  );
  const byLocode = Object.entries(crewChangeEventCount?.byLocode || {});
  const byPortName = Object.entries(crewChangeEventCount?.byPortName || {});
  const byAirportCountryIso3 = Object.entries(
    crewChangeEventCount?.byAirportCountryIso3 || {}
  );

  return (
    <Stack direction="row" spacing={2}>
      <StyledCard variant="outlined">
        <Typography variant="body2" gutterBottom>
          Total Vessels
        </Typography>
        <Typography variant="h4">{flotillaVesselsCount}</Typography>
      </StyledCard>
      <StyledCard variant="outlined">
        <Typography variant="body2" gutterBottom>
          Total Crew Changes
        </Typography>
        <Typography variant="h4">{crewChangePlansCreatedCount}</Typography>
      </StyledCard>
      {getCrewCountByNationality.length ||
      byLocode.length ||
      byPortName.length ||
      byAirportCountryIso3.length ? (
        <StyledCard
          variant="outlined"
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="body2" mb={1}>
            Most Crew Changes
          </Typography>
          {getCrewCountByNationality.length ? (
            <Stack direction="row" spacing={1}>
              <Typography variant="h6">By Nationality: </Typography>
              {RenderMaxValue(getCrewCountByNationality)}
            </Stack>
          ) : null}
          {byLocode.length ? (
            <Stack direction="row" spacing={1}>
              <Typography variant="h6">By Locode: </Typography>
              {RenderMaxValue(byLocode)}
            </Stack>
          ) : null}
          {byPortName.length ? (
            <Stack direction="row" spacing={1}>
              <Typography variant="h6">By Port Name: </Typography>
              {RenderMaxValue(byPortName)}
            </Stack>
          ) : null}
          {byAirportCountryIso3.length ? (
            <Stack direction="row" spacing={1}>
              <Typography variant="h6">By Airport Country: </Typography>
              {RenderMaxValue(byAirportCountryIso3)}
            </Stack>
          ) : null}
        </StyledCard>
      ) : null}
    </Stack>
  );
};

export default Stats;
