import React, { MutableRefObject, useEffect, useRef } from 'react';
import {
  useController,
  FieldValues,
  ControllerRenderProps,
} from 'react-hook-form';
import { Stack, SxProps, Theme, styled } from '@mui/material';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import ToolbarPlugin from '../LexicalEditor/ToolbarPlugin';
import { ComposerStyles, EditorNodes, EditorTheme } from 'lib/editorConfig';
import { gray10 } from 'lib/colors';
import { $getRoot, $insertNodes, LexicalEditor, $setSelection } from 'lexical';
import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html';
import remarkHtml from 'remark-html';
import remarkParse from 'remark-parse';
import { unified } from 'unified';

const ToolbarWrapper = styled('div')`
  position: sticky;
  top: 0;
`;

const CustomEditor = ({
  bodyField,
  editorRef,
  isUserEditedEmail,
}: {
  bodyField: ControllerRenderProps<FieldValues, string>;
  editorRef: MutableRefObject<LexicalEditor | null>;
  isUserEditedEmail: boolean;
}) => {
  const hasSet = useRef<boolean>(false);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          bodyField.onChange($generateHtmlFromNodes(editor, null));
        });
      }
    );

    // Do not forget to unregister the listener when no longer needed!

    return () => removeUpdateListener();
  }, []); // eslint-disable-line

  // set default content
  useEffect(() => {
    editorRef.current = editor;

    async function getHtml(value: string) {
      hasSet.current = true;
      if (isUserEditedEmail) return value;
      return String(
        await unified()
          .use(remarkParse)
          .use(remarkHtml)
          .process(value.replace(/\n(?!\n)/g, '\n\n'))
      );
    }

    !hasSet.current &&
      getHtml(bodyField.value).then((html) => {
        editor.update(() => {
          const parser = new DOMParser();
          const dom = parser.parseFromString(html, 'text/html');
          // Once you have the DOM instance it's easy to generate LexicalNodes.
          const nodes = $generateNodesFromDOM(editor, dom);

          // Select the root
          $getRoot().select();

          // Insert them at a selection.
          $insertNodes(nodes);

          bodyField.onChange($generateHtmlFromNodes(editor, null));

          $setSelection(null);
        });
      });
  }, []); // eslint-disable-line
  return null;
};

function LexicalEditorField({
  control,
  name,
  isUserEditedEmail,
  sx,
}: {
  name: string;
  control: any;
  isUserEditedEmail?: boolean;
  sx?: SxProps<Theme>;
}) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });
  const editorRef = useRef<LexicalEditor | null>(null);
  return (
    <Stack sx={sx}>
      <ComposerStyles>
        <LexicalComposer
          initialConfig={{
            namespace: 'proteus',
            theme: EditorTheme,
            onError: (error) => {
              console.error(error);
            },
            nodes: EditorNodes,
          }}
        >
          <ToolbarWrapper id="lexical-toolbar">
            <ToolbarPlugin />
          </ToolbarWrapper>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                style={{
                  padding: '1rem 0.75rem',
                  background: gray10,
                  borderRadius: '0 0 4px 4px',
                  minHeight: '200px',
                  outline: '1px solid rgba(0, 0, 0, 0.05)',
                  borderBottom: invalid ? '2px solid #d32f2f' : undefined,
                }}
              />
            }
            placeholder={
              <div
                style={{
                  fontSize: '0.75rem',
                  lineHeight: 1.66,
                  fontWeight: 400,
                  color: `#d32f2f`,
                }}
              >
                {error?.message}
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <TablePlugin />
          <HistoryPlugin />
          <CustomEditor
            bodyField={field}
            editorRef={editorRef}
            isUserEditedEmail={isUserEditedEmail || false}
          />
        </LexicalComposer>
      </ComposerStyles>
    </Stack>
  );
}

export default LexicalEditorField;
