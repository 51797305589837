import { IconButton, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import MaterialIcon from 'components/MaterialIcon';
import { blue, orange } from 'lib/colors';

const RenderAccess = (props: GridRenderCellParams<any, boolean>) => {
  const { value } = props;
  return (
    <IconButton>
      {value ? (
        <Tooltip title="Public" placement="top" arrow>
          <MaterialIcon name="person" color={blue} />
        </Tooltip>
      ) : (
        <Tooltip title="Private" placement="top" arrow>
          <MaterialIcon name="lock" color={orange} />
        </Tooltip>
      )}
    </IconButton>
  );
};

export default RenderAccess;
