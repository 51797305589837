import * as yup from 'yup';

export const AddUserSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .required()
    .test(
      'password-length',
      'Password should be at least 5 characters',
      function (value) {
        return value.length >= 5;
      }
    ),
  confirmPassword: yup
    .string()
    .test('confirm-password', "Password doesn't match", function (value) {
      const { password } = this.parent;
      return password === value;
    })
    .required(),
  isVerified: yup.boolean().optional(),
  isApproved: yup.boolean().optional(),
  shouldSendWelcomeEmailIfVerifiedAndApproved: yup
    .boolean()
    .test(
      'send-welcome-email',
      'Welcome email can only be sent when both isVerified and isApproved are true',
      function (value) {
        const { isVerified, isApproved } = this.parent;
        if (value && (!isVerified || !isApproved)) return false;
        return true;
      }
    )
    .optional(),
});
