import React, { useState } from 'react';
import { styled, Typography, Stack } from '@mui/material';
import { useIsLightMode } from 'hooks';
import { NavLink } from 'react-router-dom';
import { blue40, blue50, white } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import Logo from 'components/Logo';
import { sideBarItems, sideBarItemsAdmin } from './SidebarItems';
import MaterialIcon from 'components/MaterialIcon';

const Wrapper = styled(Stack)`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${BREAK_POINT_XS}) {
    display: none;
  }
`;

const LogoIcon = styled(Logo)`
  height: 30px;
`;

const Container = styled(Stack)`
  z-index: 100;
  position: absolute;
  top: 4rem;
  left: 0;
  height: calc(100vh - 8rem);
  width: calc(100% - 4rem);
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const StyledNavLink = styled(NavLink)`
  color: ${white};
  text-decoration: none;
  height: 1.57rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2rem;
  padding: 1rem;
  border-radius: 0.2rem;
  font-size: 1rem;
  :hover {
    background-color: #385dea60 !important;
  }
`;

const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isLightMode = useIsLightMode();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper>
      <LogoIcon white />
      <MaterialIcon
        name="menu"
        onClick={handleOpen}
        color={white}
        style={{ cursor: 'pointer' }}
      />
      {isOpen ? (
        <Container
          sx={{
            backgroundColor: isLightMode ? blue40 : '#2c2c2c',
          }}
        >
          {sideBarItems
            .filter((o) => !o.hide)
            .map((item, index) => (
              <StyledNavLink
                key={index}
                to={item.link}
                style={({ isActive }) => {
                  return {
                    backgroundColor: isActive ? blue50 : 'inherit',
                  };
                }}
                onClick={handleOpen}
              >
                <MaterialIcon name={item.icon} color={white} />
                <Typography variant="subtitle2">{item.name}</Typography>
              </StyledNavLink>
            ))}
          <Stack mt={6}>
            {sideBarItemsAdmin
              .filter((o) => !o.hide)
              .map((item, index) => (
                <StyledNavLink
                  key={index}
                  to={item.link}
                  style={({ isActive }) => {
                    return {
                      backgroundColor: isActive ? blue50 : 'inherit',
                    };
                  }}
                  onClick={handleOpen}
                >
                  <MaterialIcon name={item.icon} color={white} />
                  <Typography variant="subtitle2">{item.name}</Typography>
                </StyledNavLink>
              ))}
          </Stack>
        </Container>
      ) : null}
    </Wrapper>
  );
};

export default MobileSidebar;
