import { ListPortRestrictionsResp } from '@greywing-maritime/frontend-library/dist/types/omegaRestrictionsData';
import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataGridProProps, useGridApiRef } from '@mui/x-data-grid-pro';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import { SimpleCard } from 'components/Table/Cards';
import { getCountryFromAlpha2 } from 'lib/countries';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useLoaderData } from 'react-router';

const columns: GridColDef[] = [
  {
    field: 'selector',
    headerName: 'Port',
    width: 100,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 200,
    valueGetter: ({ row }) =>
      getCountryFromAlpha2(row.selector.slice(0, 2))?.name,
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 150,
    valueFormatter: ({ value }) => capitalize(value),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 150,
    valueGetter: (params) => moment(params.row.updatedAt).format('MMM D, YYYY'),
  },
  {
    field: 'checkedAt',
    headerName: 'Checked At',
    width: 150,
    valueGetter: (params) => moment(params.row.updatedAt).format('MMM D, YYYY'),
  },
];

const PortRestrictionsPage = () => {
  const { portRestrictions } = useLoaderData() as ListPortRestrictionsResp;
  const apiRef = useGridApiRef();

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(
    ({ row: { htmlContent } }) => (
      <SimpleCard>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </SimpleCard>
    ),
    []
  );

  return (
    <Stack height="100%">
      <PageHeader
        title="Port Restrictions"
        subtitle="See port restrictions around the world"
        chipText={portRestrictions.length + ' restrictions'}
        icon="anchor"
      />

      <Table
        apiRef={apiRef}
        height="100%"
        rows={portRestrictions}
        columns={columns}
        noRowsText="No port restrictions found"
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        onRowClick={(params) => {
          apiRef.current.toggleDetailPanel(params.id);
        }}
      />
    </Stack>
  );
};

export default PortRestrictionsPage;
