import queryClient from 'query';
import { riskAreaQuery } from 'query/queries';

export const RiskAreaLoader = async () => {
  const query = riskAreaQuery;
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
