import { CompanyPermissionCommon } from '@greywing-maritime/frontend-library/dist/types/companies';
import {
  AllPermissionsCommon,
  UserPermissionCommon,
} from '@greywing-maritime/frontend-library/dist/types/omegaUsers';

export type AllPermissionType = AllPermissionsCommon & {
  featureId: number;
  displayName: string;
};
export type UserPermissionType = UserPermissionCommon & {
  displayName: string;
};
export type CompanyPermissionType = CompanyPermissionCommon & {
  displayName: string;
};

export type PermissionType = UserPermissionType | CompanyPermissionType;

export const AccessControl = ['allow', 'deny'];
