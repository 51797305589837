import { createLogger } from 'redux-logger';
import { isDevelopment, showLogger } from 'lib/environments';

const logger = createLogger({
  predicate: () => isDevelopment && showLogger,
});

// add custom middlewares here
const middlewares = [logger];

export default middlewares;
