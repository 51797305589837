import React from 'react';
import {
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { Loading, Error, Success } from 'components/State';
import { BREAK_POINT_XS } from 'lib/breakpoints';

const getModalStyle = (width?: number | string, height?: number | string) => ({
  sx: {
    border: 'none',
    borderRadius: '8px',
    minWidth: `${
      width ? (typeof width === 'string' && width) || `${width}px` : '500px'
    }`,
    maxHeight: `${
      height ? (typeof height === 'string' && height) || `${height}px` : '75vh'
    }`,
    [`@media screen and (max-width: ${
      width && Number(width) > 500 ? width?.toString() + 'px' : BREAK_POINT_XS
    })`]: {
      minWidth: 'calc(100vw - 2rem)',
      maxWidth: 'calc(100vw - 2rem)',
      maxHeight: 'calc(100vh - 2rem)',
      margin: '0 1rem',
    },
  },
});

type Props = {
  styles?: { [key: string]: string | number };
  width?: number | string;
  height?: number | string;
  title?: string;
  header?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  loading?: boolean;
  success?: boolean;
  error?: string | Error | Error[] | null;
  closeFn: () => void;
};

const Modal = ({
  styles,
  width,
  height,
  title,
  header,
  actions,
  children,
  open,
  loading = false,
  success = false,
  error = null,
  closeFn,
}: Props) => {
  const renderHeader = () => {
    return (
      <Stack p={2} spacing={1}>
        {Boolean(title) && (
          <Typography variant="h6" fontWeight={600}>
            {title}
          </Typography>
        )}
        {header || null}
      </Stack>
    );
  };

  const renderFooter = () =>
    actions ? <DialogActions sx={{ p: 2 }}>{actions}</DialogActions> : null;

  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={closeFn}
      PaperProps={getModalStyle(width, height)}
      disableRestoreFocus
      sx={{
        backdropFilter: 'blur(3px)',
        backgroundColor: 'rgba(52, 64, 84, 0.7)',
        ...styles,
      }}
    >
      {renderHeader()}

      <DialogContent
        dividers
        sx={{ py: 3, position: 'relative', minHeight: '200px' }}
      >
        {children}
        {loading ? <Loading /> : null}
      </DialogContent>

      {success ? <Success /> : null}
      {error ? <Error text={error} /> : null}

      {renderFooter()}
    </Dialog>
  );
};

export default Modal;
