import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { authedUpdate, handleAPIError } from 'utils/api';
import { EditCmsJobSchema } from '../validation';
import Modal from 'components/Modal';
import { TextField, AutocompleteWithAdd, Switch } from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import {
  CMS_ADAPTER_IDS_COMMON,
  CmsJobCommon,
} from '@greywing-maritime/frontend-library/dist/types/cms';
import { useRevalidator } from 'react-router-dom';
import { CMS_JOBS_KEY } from 'query/queries';
import { filterOptionalParams } from 'utils/helpers';

type Props = {
  cmsJob: CmsJobCommon;
  open: boolean;
  closeFn: () => void;
};

type UpdateCmsJobType = yup.InferType<typeof EditCmsJobSchema>;

const UpdateCmsJobMutation = async (values: UpdateCmsJobType) => {
  const { companyId, ...rest } = values;
  const endpoint = `/api/v2/omega/cms/jobs/company/` + companyId;
  const response = await authedUpdate(endpoint, filterOptionalParams(rest));
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};
const EditCmsJob = ({ cmsJob, open, closeFn }: Props) => {
  const { companyId, adapterId, enabled, frequencyInMins, companyName } =
    cmsJob;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<UpdateCmsJobType>({
    defaultValues: {
      companyId,
      adapterId,
      enabled,
      frequencyInMins,
    },
    resolver: yupResolver(EditCmsJobSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UpdateCmsJobMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: CMS_JOBS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: UpdateCmsJobType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Edit Cms Job"
      icon="edit"
      subtitle={`Update parameters for ${companyName}`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Update
      </Button>
    </Stack>
  );

  return (
    <Modal
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Switch
            control={control}
            name="enabled"
            label={'Enable ' + companyName + ' Cms Job'}
          />
          <Typography variant="subtitle2">Adapter ID</Typography>
          <AutocompleteWithAdd
            control={control}
            name="adapterId"
            size="small"
            options={Object.values(CMS_ADAPTER_IDS_COMMON).map((value) => ({
              label: value,
            }))}
          />
          <Typography variant="subtitle2">Frequency in minutes</Typography>
          <TextField
            type="number"
            control={control}
            name="frequencyInMins"
            placeholder="1440"
            size="small"
          />
        </Stack>
      </form>
    </Modal>
  );
};

export default EditCmsJob;
