export const parseNullableJson = (value?: string | null) => {
  if (value) {
    const parsedObject = JSON.parse(value);
    if (Object.keys(parsedObject).length) return parsedObject;
  }
  return null;
};
export const filterOptionalParams = <T>(
  obj: Record<string, T>
): Record<string, T> => {
  const result: Record<string, T> = {};

  for (const key in obj) {
    const value = obj[key];

    // Check if the value is defined based on its data type
    if (value !== null && value !== undefined)
      if (
        (typeof value === 'string' && value.trim() !== '') ||
        (Array.isArray(value) && value.length > 0) ||
        (typeof value === 'object' && Object.keys(value).length > 0) ||
        (typeof value === 'number' && !isNaN(value as number)) ||
        typeof value === 'boolean'
      ) {
        result[key] = value;
      }
  }

  return result;
};

export const base64ToFile = (
  base64: string,
  mimeType: string,
  fileName: string
): File => {
  const binaryString = atob(base64);
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: mimeType });

  return new File([blob], fileName, { type: mimeType });
};

export const formatCurrencyDisplay = (value: number, currency: string) => {
  return `${new Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
  }).format(value || 0)} ${currency ?? ''}`;
};

export const formatNumberDisplay = (value: number) => {
  return `${new Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
  }).format(value || 0)}`;
};
