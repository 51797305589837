export const isProduction =
  process.env.REACT_APP_NETLIFY_CONTEXT === 'production';
export const isStagingInstance = process.env.REACT_APP_STAGING_ENV === 'YES';
export const isDeployPreview =
  process.env.REACT_APP_NETLIFY_CONTEXT === 'deploy-preview';
export const isDevelopment = process.env.NODE_ENV === 'development';

export const showLogger = process.env.REACT_APP_REDUX_LOGGER === 'yes';

export const getEnvironment = () => {
  switch (true) {
    case isProduction:
      return 'production';

    case isDeployPreview:
      return 'deploy-preview';

    case isDevelopment:
      return 'development';

    default:
      return 'no-env-set';
  }
};
