import React, { useState } from 'react';
import { Button, Typography, styled } from '@mui/material';
import { blue, gray20, green, textGray, white } from 'lib/colors';
import MaterialIcon from './MaterialIcon';

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: 3rem 1fr 5rem;
  gap: 10px;
  padding: 0.25rem;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  border: 2px solid ${gray20};
`;

const Text = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Icon = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid ${gray20};
`;

const CopyLink = ({ text }: { text: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyLink = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Wrapper>
      <Icon>
        <MaterialIcon name="link" size={22} color={textGray} />
      </Icon>
      <Text variant="body2" color={textGray}>
        {text}
      </Text>
      <Button
        variant="contained"
        onClick={handleCopyLink}
        sx={{
          minHeight: '34px',
          '&.MuiButton-root': { backgroundColor: !isCopied ? blue : green },
        }}
        disableElevation
      >
        {!isCopied ? (
          'Copy'
        ) : (
          <MaterialIcon name="check" color={white} size={22} />
        )}
      </Button>
    </Wrapper>
  );
};

export default CopyLink;
