import React from 'react';
import Modal from 'components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { authedPost, handleAPIError } from 'utils/api';
import { AddDataPushJobSchema, DATA_PUSH_ADAPTER_IDS } from '../validation';
import ModalHeader from 'components/ModalHeader';
import {
  TextField,
  AutocompleteWithAdd,
  JsonEditorField,
} from 'components/Forms';
import { useRevalidator } from 'react-router-dom';
import { DATA_PUSH_JOBS_KEY } from 'query/queries';
import { parseNullableJson } from 'utils/helpers';

type AddDataPushJobType = {
  companyId: number;
  adapterId: string;
  frequencyInMins: number;
  config: string;
};

type Props = {
  open: boolean;
  closeFn: () => void;
};

const AddDataPushJobMutation = async (values: AddDataPushJobType) => {
  const { config, ...rest } = values;
  const endpoint = `/api/v2/omega/data-push/jobs`;
  const response = await authedPost(endpoint, {
    ...rest,
    config: parseNullableJson(config),
  });
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

const AddDataPushJob = ({ open, closeFn }: Props) => {
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      companyId: 0,
      frequencyInMins: 1,
      adapterId: Object.values(DATA_PUSH_ADAPTER_IDS)[0],
      config: '{}',
    },
    resolver: yupResolver(AddDataPushJobSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: AddDataPushJobMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: DATA_PUSH_JOBS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
        closeFn();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: AddDataPushJobType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Add Data Push Job"
      icon="add_circle"
      subtitle="Add data push job by providing data push details"
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Add
      </Button>
    </Stack>
  );

  return (
    <Modal
      width={800}
      height={700}
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Company ID</Typography>
          <TextField
            type="number"
            control={control}
            name="companyId"
            placeholder="01"
            size="small"
            autoFocus
          />
          <Typography variant="subtitle2">Adapter ID</Typography>
          <AutocompleteWithAdd
            control={control}
            name="adapterId"
            size="small"
            options={Object.values(DATA_PUSH_ADAPTER_IDS).map((value) => ({
              label: value,
            }))}
          />
          <Typography variant="subtitle2">Frequency in minutes</Typography>
          <TextField
            type="number"
            control={control}
            name="frequencyInMins"
            placeholder="1440"
            size="small"
          />
          <Typography variant="subtitle2">Config</Typography>
          <JsonEditorField control={control} name="config" />
        </Stack>
      </form>
    </Modal>
  );
};

export default AddDataPushJob;
