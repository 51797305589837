import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { styled, Typography, Button, Stack } from '@mui/material';
import { USER_TYPES } from '@greywing-maritime/frontend-library/dist/types/userConfig';
import { handleLogin, setSessionKeys } from './handle-login';
import { LoginSchema } from './validation';
import { useAppDispatch } from 'hooks';
import { setUserAuth } from 'redux/actions';
import { TextField, PasswordField } from 'components/Forms';
import Logo from 'components/Logo';
import { useNavigate, useNavigation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
// import GoogleIcon from '../../assets/icons/google-color-icon.svg';

const Title = styled(Typography)`
  font-weight: 600;
  margin-top: 2rem;
`;

const Subtitle = styled(Typography)`
  margin-top: 1rem;
`;

const StyledForm = styled('form')`
  margin-top: 2rem;
`;

const StyledLogo = styled(Logo)`
  height: 60px;
`;

type LoginType = yup.InferType<typeof LoginSchema>;

function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useNavigation();
  const [isLocalBackend, setIsLocalBackend] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<LoginType>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(LoginSchema),
  });

  const [error, setError] = useState<string>('');
  const onSubmit = async (values: LoginType) => {
    try {
      const response = await handleLogin(values);
      if (!response) {
        setError('No valid response');
        return;
      }
      const { error, success, message, authPacket } = response;

      if (error) {
        setError(message);
        return;
      }

      if (success && authPacket) {
        if (
          !(['omega', 'customer_success'] as USER_TYPES[]).includes(
            authPacket.userType
          )
        ) {
          setError('Unauthorised');
          return;
        }
        setSessionKeys(authPacket);
        // push to root route after store update
        dispatch(setUserAuth(true));
        if (searchParams.get('redirectTo'))
          navigate(searchParams.get('redirectTo')!);
        else navigate('/dashboard');
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  const handleReset = () => {
    window.localStorage.removeItem('API_URL');
    window.location.reload();
  };

  useEffect(() => {
    if (localStorage.getItem('API_URL')?.includes('http://localhost:')) {
      setIsLocalBackend(true);
    }
  }, []);

  return (
    <>
      <StyledLogo />
      <Title variant="h4">Log in to your account</Title>
      <Subtitle variant="subtitle1">
        Welcome back! Please enter your details
      </Subtitle>
      {error && (
        <Subtitle variant="subtitle1" color="error">
          {error}
        </Subtitle>
      )}
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ width: '25rem' }}>
          <Stack spacing={1}>
            <Typography variant="subtitle1">Email</Typography>
            <TextField
              control={control}
              size="small"
              variant="outlined"
              name="email"
              placeholder="Enter your email"
              autoFocus
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle1">Password</Typography>
            <PasswordField
              control={control}
              size="small"
              name="password"
              placeholder="Enter your password"
            />
          </Stack>
          <LoadingButton
            type="submit"
            size="large"
            variant="contained"
            sx={{ textTransform: 'none', height: '2.5rem' }}
            loading={isSubmitting || state === 'loading'}
          >
            <Typography variant="button" fontSize="0.8rem">
              Sign In
            </Typography>
          </LoadingButton>
          {isLocalBackend && (
            <Button
              size="large"
              variant="outlined"
              sx={{ textTransform: 'none' }}
              onClick={handleReset}
            >
              Reset API endpoint
            </Button>
          )}
        </Stack>
      </StyledForm>
    </>
  );
}

export default LoginPage;
