import * as yup from 'yup';

export enum VMS_ADAPTER_IDS_COMMON {
  IINO = 'iino_spreadsheet',
  ZEASHIP = 'zeaship',
  CLOUD_FLEET_MANAGER = 'cloud_fleet_manager',
  BW_LNG = 'BW_LNG',
  CMA_CGM = 'cma_cgm',
}

export const AddVmsJobSchema = yup.object().shape({
  companyId: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
  adapterId: yup.string().required(),
  frequencyInMins: yup.number().positive().required(),
  config: yup
    .string()
    .test('is-valid-object', 'Invalid JSON string', (value) => {
      try {
        if (value) {
          if (value === '') return true;
          const parsedObject = JSON.parse(value);
          return typeof parsedObject === 'object' && parsedObject !== null;
        }
      } catch (error) {
        return false; // JSON parsing failed
      }
    })
    .nullable()
    .required(),
});

export const EditVmsJobSchema = yup.object().shape({
  id: yup.number().positive().required(),
  companyId: yup.number().positive().nullable().optional(),
  adapterId: yup.string().optional(),
  enabled: yup.boolean().optional(),
  frequencyInMins: yup.number().positive().optional(),
  config: yup
    .string()
    .test('is-valid-object', 'Invalid JSON string', (value) => {
      try {
        if (value) {
          if (value === '') return true;
          const parsedObject = JSON.parse(value);
          return typeof parsedObject === 'object' && parsedObject !== null;
        }
      } catch (error) {
        return false; // JSON parsing failed
      }
    })
    .nullable()
    .optional(),
});
