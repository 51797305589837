import { Stack } from '@mui/material';
import PageHeader from 'components/PageHeader';
import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { mailType } from '../types';
import { OmegaListAssistedCrewChangeConvosResp } from '@greywing-maritime/frontend-library/dist/types/omegaAcc';
import Table from 'components/Table';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import RenderUser from 'components/Table/RenderAvatarV2';
import { StyledChip } from 'styles/common';
import RenderCrew from '../components/Table/RenderCrew';
import Conversations from '../components/Modals/Conversations';
import { AssistedCrewChangeBaseType } from '@greywing-maritime/frontend-library/dist/types/acc';

const RenderAgent = (props: GridRenderCellParams<any, number>) => {
  const { value } = props;
  return (
    <StyledChip
      label={value ? `${value} Agent${value > 1 ? 's' : ''}` : 'No Agent'}
      size="small"
    />
  );
};
const RenderPort = (props: GridRenderCellParams<any, string>) => {
  const { value } = props;
  return <StyledChip label={value} size="small" />;
};

const columns: GridColDef[] = [
  {
    field: 'creator',
    headerName: 'Executed by',
    width: 250,
    renderCell: RenderUser,
  },
  {
    field: 'portOfCall',
    headerName: 'Port of Call',
    width: 160,
    valueGetter: (params) =>
      params.row.portName && params.row.portLocode
        ? `${params.row.portName} - ${params.row.portLocode}`
        : `${params.row.portName || params.row.portLocode || '-'}`,
    renderCell: RenderPort,
  },
  {
    field: 'vesselName',
    headerName: 'Vessel',
    width: 205,
    valueGetter: (params) =>
      params.row.flotillaVessel?.name || params.row.vesselName || '-',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 150,
    valueGetter: (params) => moment(params.row.updatedAt).format('MMM D, YYYY'),
  },
  {
    field: 'crewDetails',
    headerName: 'Onsigners/Offsigners',
    width: 150,
    renderCell: RenderCrew,
  },
];

const CrewChanges = ({ type }: { type: (typeof mailType)[number] }) => {
  const { data } = useLoaderData() as OmegaListAssistedCrewChangeConvosResp;
  const [showConvoModal, setShowConvoModal] = useState<boolean>(false);
  const [acc, setACC] = useState<AssistedCrewChangeBaseType | null>(null);

  const uniqueAcc = Array.from(
    new Map(data.map((c) => [c.acc.id, c.acc])).values()
  );

  const handleConvoModal = () => {
    setShowConvoModal(!showConvoModal);
  };
  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    setACC(row);
    handleConvoModal();
  };

  const agentsCol: GridColDef = {
    field: 'agents',
    headerName: 'Agents Contacted',
    width: 150,
    renderCell: RenderAgent,
    valueGetter: (params) =>
      data.filter((c) => c.acc.id === params.row.id).length,
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Assisted Crew Changes"
        subtitle="A list of assisted crew changes"
        chipText={uniqueAcc.length + ' crew changes'}
        icon="diversity_1"
      />

      <Table
        height="100%"
        rows={uniqueAcc}
        columns={[columns[0], agentsCol, ...columns.slice(1)]}
        onRowClick={handleRowClick}
        noRowsText="No assisted crew changes found"
      />

      {showConvoModal && acc ? (
        <Conversations
          open={showConvoModal}
          conversations={data.filter((c) => c.acc.id === acc.id)}
          closeFn={handleConvoModal}
          type={type}
        />
      ) : null}
    </Stack>
  );
};

export default CrewChanges;
