import React, { useState } from 'react';
import {
  GridRowParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Button, Typography, Stack, Tooltip } from '@mui/material';
import {
  CompanyCommon,
  CompanyUserCommon,
} from '@greywing-maritime/frontend-library/dist/types/companies';
import { GetStatsResp } from '@greywing-maritime/frontend-library/dist/types/usageStats';

import { stringAvatar } from 'utils/avatar';
import AddCompany from '../company/components/AddCompany';
import Stats from 'components/Stats';
import { StyledAvatar } from 'styles/common';
import Table from 'components/Table';
import MaterialIcon from 'components/MaterialIcon';
import { white } from 'lib/colors';

const RenderUsers = (props: GridRenderCellParams<any, CompanyUserCommon[]>) => {
  const { value } = props;

  return (
    <Stack direction={'row'} spacing={-1}>
      {value && value.length > 0
        ? value.slice(0, 5).map(({ email, id }) => (
            <Tooltip key={id} title={email} placement="top" arrow>
              <StyledAvatar {...stringAvatar(email, 'initials')} />
            </Tooltip>
          ))
        : '-'}
      {value && value.length > 5 ? (
        <Tooltip
          title={value
            .slice(5)
            .map(({ email }) => email)
            .join(', ')}
          placement="top"
          arrow
        >
          <StyledAvatar {...stringAvatar('+' + (value.length - 5))} />
        </Tooltip>
      ) : null}
    </Stack>
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'displayName',
    headerName: 'Company',
    width: 150,
  },
  {
    field: 'domain',
    headerName: 'Domain',
    width: 200,
    valueGetter: (params) => params.row.domain,
  },
  {
    field: 'users',
    headerName: 'Users',
    width: 200,
    renderCell: RenderUsers,
  },
];

type LoaderProps = {
  stats: GetStatsResp;
  companies: CompanyCommon[];
};

function DashboardPage() {
  const { stats, companies } = useLoaderData() as LoaderProps;
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const getNavUrl = (id: number) => `/company/${id}`;

  const handleRowClick = (params: GridRowParams) => {
    const {
      row: { id },
    } = params;
    navigate(getNavUrl(id));
  };

  const handleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  return (
    <Stack height={'100%'} rowGap={4}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography variant="h4" fontWeight={600} gutterBottom>
            Welcome back,
          </Typography>
          <Typography variant="subtitle2" fontWeight={300}>
            Track, manage and integrate customers
          </Typography>
        </Stack>
        <Stack>
          <Button
            variant="contained"
            startIcon={<MaterialIcon name="add" color={white} />}
            onClick={handleAddModal}
          >
            Add
          </Button>
        </Stack>
      </Stack>

      <Stack>
        <Stats data={stats} />
      </Stack>

      <Table
        height="100%"
        rows={companies}
        columns={columns}
        onRowClick={handleRowClick}
        openInNewTab={getNavUrl}
        noRowsText="No companies found"
      />

      {showAddModal ? (
        <AddCompany open={showAddModal} closeFn={handleAddModal} />
      ) : null}
    </Stack>
  );
}

export default DashboardPage;
