import React from 'react';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Stack, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { authedUpdate, handleAPIError } from 'utils/api';
import { DATA_PUSH_ADAPTER_IDS, EditDataPushJobSchema } from '../validation';
import Modal from 'components/Modal';
import {
  TextField,
  AutocompleteWithAdd,
  Switch,
  JsonEditorField,
} from 'components/Forms';
import ModalHeader from 'components/ModalHeader';
import { useRevalidator } from 'react-router-dom';
import { DATA_PUSH_JOBS_KEY } from 'query/queries';
import { parseNullableJson, filterOptionalParams } from 'utils/helpers';
import { DataPushJobCommon } from '@greywing-maritime/frontend-library/dist/types/dataPushJobs';

type Props = {
  dataPushJob: DataPushJobCommon;
  open: boolean;
  closeFn: () => void;
};

type UpdateDataPushJobType = yup.InferType<typeof EditDataPushJobSchema>;

const UpdateDataPushJobMutation = async (values: UpdateDataPushJobType) => {
  const { id, config, ...rest } = values;
  const endpoint = `/api/v2/omega/data-push/jobs/` + id;
  const response = await authedUpdate(endpoint, {
    id,
    ...filterOptionalParams({ ...rest, config: parseNullableJson(config) }),
  });
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};
const EditDataPushJob = ({ dataPushJob, open, closeFn }: Props) => {
  const { id, adapterId, enabled, frequencyInMins, config } = dataPushJob;
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  const { handleSubmit, control } = useForm<UpdateDataPushJobType>({
    defaultValues: {
      id,
      companyId: dataPushJob.company.id,
      adapterId,
      enabled,
      frequencyInMins,
      config: config ? JSON.stringify(config) : '{}',
    },
    resolver: yupResolver(EditDataPushJobSchema),
  });

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: UpdateDataPushJobMutation,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: DATA_PUSH_JOBS_KEY,
        exact: true,
      });
      revalidator.revalidate();
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async (values: UpdateDataPushJobType) => {
    mutate(values);
  };

  const headerComp = () => (
    <ModalHeader
      title="Edit Data Push Job"
      icon="edit"
      subtitle={`Update parameters for data push job`}
    />
  );

  const footerComp = () => (
    <Stack direction="row" spacing={2} width={'100%'}>
      <Button fullWidth variant="outlined" onClick={closeFn}>
        Close
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={isLoading}
      >
        Update
      </Button>
    </Stack>
  );

  return (
    <Modal
      width={800}
      height={700}
      open={open}
      closeFn={closeFn}
      header={headerComp()}
      actions={footerComp()}
      loading={isLoading}
      success={isSuccess}
      error={isError && error instanceof Error ? error.message : null}
    >
      <form>
        <Stack spacing={2}>
          <Switch
            control={control}
            name="enabled"
            label="Enable Data Push Job"
          />
          <Typography variant="subtitle2">Adapter ID</Typography>
          <AutocompleteWithAdd
            control={control}
            name="adapterId"
            size="small"
            options={Object.values(DATA_PUSH_ADAPTER_IDS).map((value) => ({
              label: value,
            }))}
          />
          <Typography variant="subtitle2">Frequency in minutes</Typography>
          <TextField
            type="number"
            control={control}
            name="frequencyInMins"
            placeholder="1440"
            size="small"
          />
          <Typography variant="subtitle2">Config</Typography>
          <JsonEditorField control={control} name="config" />
        </Stack>
      </form>
    </Modal>
  );
};

export default EditDataPushJob;
