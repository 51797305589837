import { Attachment as AttachmentType } from '@greywing-maritime/frontend-library/dist/types/acc';
import { ExtractedAttachment } from '@greywing-maritime/frontend-library/dist/types/acc';
import { Button, IconButton, Stack, Typography, styled } from '@mui/material';
import MaterialIcon from 'components/MaterialIcon';
import PageHeader from 'components/PageHeader';
import { black, blue, codeBg, codeText, gray20, white } from 'lib/colors';
import React, { useState } from 'react';
import { VisuallyHiddenInput } from 'styles/common';
import Attachment from 'components/Attachment';
import { Error, Loading, Success } from 'components/State';
import { useMutation } from '@tanstack/react-query';
import { authedPost, handleAPIError } from 'utils/api';
import RenderMarkdown from 'components/RenderMarkdown';
import { BREAK_POINT_M, BREAK_POINT_XS } from 'lib/breakpoints';
import LottieAnimation from 'components/LottieAnimation';

const Wrapper = styled(Stack)`
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: ${gray20};
  width: calc(50% - 50px);
  overflow-y: auto;
  h4 {
    width: fit-content;
    margin: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: ${codeBg};
    color: ${codeText};
    font-size: 14px;
    font-weight: 600;
    span {
      font-weight: 400;
    }
  }
  @media only screen and (max-width: ${BREAK_POINT_M}) {
    width: 100%;
  }
`;

const DropZone = styled('div')`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${white};
  z-index: 100;
  color: ${blue};
  padding: 4rem;
  font-size: 4rem;
  font-weight: 200;
  border: 0.3rem dashed ${blue};
  border-radius: 20px 0px 0px 20px !important;
  @media only screen and (max-width: ${BREAK_POINT_XS}) {
    border-radius: 0px !important;
  }
`;

const ParseAttachmentsMutation = async (attachments: AttachmentType[]) => {
  const endpoint = `/api/v2/omega/assisted-crew-changes/extract-text-from-attachments`;
  const response = await authedPost(endpoint, {
    attachments,
  });
  const data = await response.json();
  handleAPIError(response.status, data);
  return data;
};

const SeaGPTParsingPage = () => {
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);
  const [output, setOutput] = useState<ExtractedAttachment[]>([]);
  const [draggingOver, setDraggingOver] = useState<boolean>(false);
  const AllowedFileTypes = {
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.png': 'image/png',
    '.pdf': 'application/pdf',
    '.xlsx':
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const { isError, isLoading, isSuccess, mutate, error, reset } = useMutation({
    mutationFn: ParseAttachmentsMutation,
    onSuccess: async (res) => {
      setOutput(res.attachments);
      setTimeout(() => {
        reset();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        reset();
      }, 5000);
    },
  });

  const onSubmit = async () => {
    if (attachments.length) {
      mutate(attachments);
    }
  };
  const handleFileInput = (files: FileList | null) => {
    const totalFiles = files?.length;
    if (totalFiles) {
      for (var i = 0; i < totalFiles; i++) {
        const name = files[i].name;
        const contentType = files[i].type;
        if (!Object.values(AllowedFileTypes).includes(contentType)) continue;
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = () => {
          const f = reader.result;
          if (typeof f === 'string') {
            const content = f.split(',')[1];
            const attachment = {
              name,
              contentType,
              content,
            };
            setAttachments((prev) => {
              if (!prev.some((a) => a.content === content))
                return [...prev, attachment];
              return prev;
            });
          }
        };
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleClear = () => {
    setOutput([]);
  };

  const handleDragOver = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDraggingOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDraggingOver(false);
    handleFileInput(e.dataTransfer.files);
  };

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleFileInput(e.target.files);
  };

  return (
    <Stack height="100%">
      <PageHeader
        title="Proteus Parsing"
        subtitle="Parse attachments using Proteus"
        chipText={attachments.length + ' attachments'}
        icon="document_scanner"
        actions={
          <>
            {output.length ? (
              <Button variant="outlined" onClick={handleClear}>
                Clear Output
              </Button>
            ) : null}
            <Button
              onClick={onSubmit}
              variant="contained"
              disabled={isLoading || attachments.length < 1}
            >
              Parse
            </Button>
          </>
        }
      />
      {output.length ? (
        <Stack gap={2}>
          <Button
            component="label"
            variant="contained"
            startIcon={<MaterialIcon name="cloud_upload" color={white} />}
            sx={{ width: 'fit-content' }}
          >
            Upload file
            <VisuallyHiddenInput
              // to allow re-uploading the same file
              key={attachments.length}
              type="file"
              accept={Object.keys(AllowedFileTypes).join(', ')}
              onChange={handleClick}
              multiple
            />
          </Button>

          <Stack direction="row" gap={1} flexWrap="wrap">
            {attachments.map((o, key) => (
              <Stack key={key} position="relative">
                <Attachment attachment={o} disableDownload />
                <IconButton
                  onClick={() => handleRemoveFile(key)}
                  sx={{ position: 'absolute', top: '-12px', right: '-15px' }}
                >
                  <MaterialIcon name="cancel" color={black} />
                </IconButton>
              </Stack>
            ))}
          </Stack>
          <Stack direction="row" gap={4} flexWrap="wrap" mb={4}>
            {output.map((o, key) => (
              <Wrapper
                key={key}
                style={{
                  maxHeight: attachments.length
                    ? 'calc(100vh - 292px)'
                    : 'calc(100vh - 250px)',
                }}
              >
                <h4>
                  Filename: <span>{o.fileName}</span>
                  <br />
                  Type: <span>{o.contentType}</span>
                </h4>
                <RenderMarkdown text={o.contentStr} />
              </Wrapper>
            ))}
          </Stack>
        </Stack>
      ) : (
        <>
          {draggingOver ? (
            <DropZone
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              Drop file anywhere
            </DropZone>
          ) : null}
          <Stack
            gap={2}
            height="100%"
            alignItems="center"
            justifyContent="center"
            onDragOver={handleDragOver}
          >
            <LottieAnimation
              name="doc-scan-h4crCHniv7"
              height={300}
              width={300}
              loop={true}
            />
            <Typography variant="body1" fontWeight={500}>
              Drag a file here or
            </Typography>
            <Button
              component="label"
              variant="contained"
              startIcon={<MaterialIcon name="cloud_upload" color={white} />}
              sx={{ width: 'fit-content', marginBottom: 2 }}
            >
              Upload file
              <VisuallyHiddenInput
                // to allow re-uploading the same file
                key={attachments.length}
                type="file"
                accept={Object.keys(AllowedFileTypes).join(', ')}
                onChange={handleClick}
                multiple
              />
            </Button>
            {!attachments.length ? (
              <Typography variant="body2">
                Supported types: {Object.keys(AllowedFileTypes).join(', ')}
              </Typography>
            ) : null}
            <Stack direction="row" gap={1} flexWrap="wrap">
              {attachments.map((o, key) => (
                <Stack key={key} position="relative">
                  <Attachment attachment={o} disableDownload />
                  <IconButton
                    onClick={() => handleRemoveFile(key)}
                    sx={{ position: 'absolute', top: '-12px', right: '-15px' }}
                  >
                    <MaterialIcon name="cancel" color={black} />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </>
      )}

      {isLoading ? <Loading /> : null}
      {isSuccess ? <Success /> : null}
      {isError ? <Error text={error as Error} /> : null}
    </Stack>
  );
};

export default SeaGPTParsingPage;
