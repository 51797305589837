import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { setUserAuth } from 'redux/actions';
import { authenticateUser, logoutUser } from 'utils/auth';
import AuthLayout from './AuthLayout';
import { useSelector } from 'react-redux';
import { selectUserAuth } from 'redux/selectors';
import LoginPage from 'pages/login';

function AuthWrapper() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthed = useSelector(selectUserAuth);
  const { pathname } = useLocation();

  /* check for authentication */
  useEffect(() => {
    const authenticate = async () => {
      const success = await authenticateUser();
      if (!success) {
        // logout user, reset values & clear local-storage
        logoutUser(dispatch);
      } else {
        dispatch(setUserAuth(success)); // set auth state to `true`
        if (pathname === '/') navigate('/dashboard');
      }
    };
    authenticate();
  }, []); // eslint-disable-line

  return isAuthed ? (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  ) : (
    <LoginPage />
  );
}

export default AuthWrapper;
